import React, { useState } from "react";
import "./Trade.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { formatNumberWithComma } from "../../../Common/Helpers";  

export const TradeDropdown = ({ setActiveMarket, coinsData, setCoinsData, coin, newCoin, setNewCoin, SETMARKET, increasingData, handleSearch, searchWord, tableShowData, pairs, selectedPair, handleItemClick, primaryCoin, dropdownVisible, setDropdownVisible }) => {

  const [isVisible, setIsVisible] = useState(true);


  const handleSelect = (item) => {
    setActiveMarket(item.quote)
    setNewCoin(item.base)
    setDropdownVisible(false)
  }

  return (
    <>
      <div className="dropdown trade-header-dropdown position-relative d-flex border-end custom-border-color border-secondary">
            <button
              className="btn btn-transparent coin-name d-flex align-items-center dropdown-toggle"
              type="button"
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              {coin ? (
                <>
                  {coin.base} / {coin.quote}
                </>
              ) : (
                <>
                  {primaryCoin?.base} / {primaryCoin?.quote} 
                </>
              )}
              
              <img src="/images/icons/caret-down.svg" alt="" className="ps-2" />
            </button>
            <ul
              className={
                dropdownVisible
                  ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
                  : "drop-menu position-absolute ps-3 d-none"
              }
            >


              <div className="coin-slider px-3">
              <Swiper
              slidesPerView={4}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        className="mySwiper mt-0"
      >
      {increasingData.map((inc, index) => (
        <SwiperSlide key={index}>
        <div className="coin-item d-flex flex-column">
          <div className="d-flex align-items-center gap-2">
            <img src={`/images/coins/${inc.base?.toLowerCase()}.svg`} alt="" width={24} height={24} />
            <span className="increase-coin-base">{inc.base}</span>
          </div>
          <div className="d-flex flex-column align-items-start pt-2">
            <span className="increase-coin-price">{inc.price}</span>
            <span className="text-xs text-success">{inc.change_in_price}</span>
          </div>
        </div>
      </SwiperSlide>
      ))}
      </Swiper>
              </div>
              <ul className="d-flex">
              {pairs?.map((item) => ( 
       
       <li key={item} onClick={() => handleItemClick(item)}  className={selectedPair === item ? 'pair-card selected' : 'pair-card'}>{item}</li>
))}
              </ul>
              <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
                <img src="/images/icons/search.svg" alt="" className="search-icon" />
                <input 
                  type="text" 
                  placeholder="Search"
                  value={searchWord}
                  onChange={handleSearch}
                />
              </div>

              {tableShowData?.map((item, index) => (
                <li key={index} className="coin-card">
                  <button
                    className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                 onClick={() => handleSelect(item)}
                  >
                    <div className="d-flex align-items-center gap-2">
                    <img src={`/images/coins/${item?.base?.toLowerCase()}.svg`} alt="" width={24} height={24} />
                    <div className="d-flex flex-column">
                      <span className="base-quote">{item?.base}/{item.quote}</span>
                      <span className="pair text-xs text-muted">
                      { formatNumberWithComma(item.base_volume) } {selectedPair}
                      </span>
                    </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span className="volume">{ formatNumberWithComma(item.base_volume) }</span>
                      <span className={item?.change_in_price > 0 ? "text-xs text-success" : "text-xs text-danger"}>
                        { parseFloat(item.change_in_price).toFixed(2) }%
                      </span>
                    </div>
                  </button>
                </li>
              ))}

            </ul>
        </div>
    </>
  )
};


