import { Link } from "react-router-dom";
import './AssetStatus.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';

function AssetStatus() {
   
  return (
    <>
    <Header />
    <PriceBar /> 

<div className="row">
  <div className="col-md-12 centered-items">
    <h1 style={{color:'#aaa', paddingTop:55}}>Asset Status</h1>
    </div>
    <div className="col-md-12 centered-items">
    <h4 style={{color:'#aaa', paddingTop:55}}>Under Construction!</h4> 
    </div>
    <p><br /><br /></p>
</div>
<Footer />
    </>
  );

}

export default AssetStatus;