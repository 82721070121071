
import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Table } from '@react-pdf/renderer';

import { formatNumberWithComma } from "../Common/Helpers";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    marginBottom: 10,
    borderBottom: '1 solid black',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    padding: 5,
    paddingLeft: 10,
    fontSize: 8, // Adjust font size
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 6, // Adjust font size
  },
  listItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    padding: 5,
    paddingLeft: 10,
    size:5,
    fontSize: 6, // Adjust font size
  },
  listItemText: {
    marginLeft: 10,
  },
});

const PdfDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text>PDF Export</Text>
        </View>
        <View style={styles.tableHeader}>
          <Text style={[styles.headerText, { flex: 1 }]}>currency</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>balance</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>balanceInTrade</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>ShortName</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>LongName</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>balanceInUsdt</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>balanceInBtc</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>balanceInTradeInUsdt</Text> 
          <Text style={[styles.headerText, { flex: 1 }]}>balanceInTradeInBtc</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>total</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>totalInUsdt</Text>
          <Text style={[styles.headerText, { flex: 1 }]}>totalInBt</Text> 
          {/* Add more headers as needed */}
        </View>
 
        {data.map((item, index) => (
          <View key={index} style={styles.listItem}>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.currency}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balance ? formatNumberWithComma(item.balance) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balanceInTrade ? formatNumberWithComma(item.balanceInTrade) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.ShortName}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.LongName}</Text>

            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balanceInUsdt ? formatNumberWithComma(item.balanceInUsdt) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balanceInBtc ? formatNumberWithComma(item.balanceInBtc) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balanceInTradeInUsdt ? formatNumberWithComma(item.balanceInTradeInUsdt) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.balanceInTradeInBtc ? formatNumberWithComma(item.balanceInTradeInBtc) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.total ? formatNumberWithComma(item.total) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.totalInUsdt ? formatNumberWithComma(item.totalInUsdt) : ""}</Text>
            <Text style={[styles.listItemText, { flex: 1 }]}>{item.totalInBt ? formatNumberWithComma(item.totalInBt) : ""}</Text>
            {/* Add more fields as needed */}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const PdfGenerator = ({ data }) => {
 
  return (
    <div>
   
      <PDFDownloadLink className='btn btn-primary' document={<PdfDocument data={data} />} fileName="exported_data.pdf">
        {({ blob, url, loading, error }) =>
          'Export PDF'
        }
      </PDFDownloadLink> 
    </div>
  );
};

export default PdfGenerator;
