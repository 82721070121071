import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./InstaTrades.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";

import axios from "axios";

function InstaTrades() {

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);

  const [searchWord, setSearchWord] = useState();
  const [tableShowData, setTableShowData] = useState([]);

/*
     * Pagination and Orderby
    */

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10);
// const [sortBy, setSortBy] = useState({ key: null, ascending: true });
const [sortBy, setSortBy] = useState({ key: "requestedOn", ascending: false });

// Function to sort data based on column key and sort order
const sortedData = tableShowData.sort((a, b) => {
  if (sortBy.key) {
    const valueA = a[sortBy.key];
    const valueB = b[sortBy.key];
    if (valueA < valueB) return sortBy.ascending ? -1 : 1;
    if (valueA > valueB) return sortBy.ascending ? 1 : -1;
  }
  return 0;
});

// Function to handle column header click for sorting
const handleSort = (key) => {
  if (sortBy.key === key) {
    setSortBy({ key, ascending: !sortBy.ascending });
  } else {
    setSortBy({ key, ascending: true });
  }
};

// Function to handle pagination
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

/*
 * Pagination and Orderby Ended
*/

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword;
 
        let filteredShowArray = tableShowData.filter(item => 
          item.base.toLocaleLowerCase('en-US').includes(tmpSearchWord.toLocaleLowerCase('en-US'))
        );

        setTableShowData(filteredShowArray);      
      } 

  }

  const getInstaTradeData = () => {
    
    const dataToSend = {
      key: "dummyValue",
    };

    // Axios POST
    axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/get_insta_trades`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi,
            "Content-Type": "application/json", // Set the content type as needed
          },
        }
      )
      .then((response) => {
       
        setData(response?.data?.data); 
 
        let latestData = response?.data?.data;
        console.log(latestData);

        if (searchWord) { 
          let filteredShowArray = latestData.filter(item => 
            item.currencyPair.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
          );  
          setTableShowData(filteredShowArray);
          setIsLoading(false);
        } else {
          setTableShowData(latestData);
          setIsLoading(false);
        }

      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
        setIsLoading(false);
      });

  }

  useEffect(() => {
    setIsLoading(true);
    
    getInstaTradeData();
     // Set up an interval to call getInstaTradeData every second
     const intervalId = setInterval(() => {
      getInstaTradeData();
    }, 1500);

  // Clean up function to clear the interval when component unmounts
  return () => {
      clearInterval(intervalId);
  };

  }, []);

  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Orders" submenu="OrdersInstaTrades" />
        <div className="main-content">
          <h4 className="title">Insta Trades</h4>
          <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('requestedOn')}>
                    {sortBy.key==="requestedOn" ? <u>DATE</u> : "DATE" }
                    {sortBy.key==="requestedOn" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="requestedOn" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('quoteAmount')}>
                    {sortBy.key==="quoteAmount" ? <u>QUOTE AMOUNT</u> : "QUOTE AMOUNT" }
                    {sortBy.key==="quoteAmount" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="quoteAmount" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('baseAmount')}>
                    {sortBy.key==="baseAmount" ? <u>BASE AMOUNT</u> : "BASE AMOUNT" }
                    {sortBy.key==="baseAmount" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="baseAmount" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                   
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('baseCurrency')}>
                    {sortBy.key==="baseCurrency" ? <u>BASE CURRENCY</u> : "BASE CURRENCY" }
                    {sortBy.key==="baseCurrency" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="baseCurrency" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('quoteCurrency')}>
                    {sortBy.key==="quoteCurrency" ? <u>QUOTE CURRENCY</u> : "QUOTE CURRENCY" }
                    {sortBy.key==="quoteCurrency" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="quoteCurrency" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('rate')}>
                    {sortBy.key==="rate" ? <u>RATE</u> : "RATE" }
                    {sortBy.key==="rate" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="rate" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
         
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('commission')}>
                    {sortBy.key==="commission" ? <u>COMMISSION</u> : "COMMISSION" }
                    {sortBy.key==="commission" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="commission" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
        
                  </tr>
                </thead>
                <tbody>
                {currentItems.length>0 ?  
  currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <div className="date">
                          {splitDateTime(item.requestedOn).date}

                          <span> {splitDateTime(item.requestedOn).time}</span>
                        </div>
                      </td>
                      <td>{formatNumberWithComma(item.quoteAmount)}</td>
                      <td>{item.baseAmount}</td>
                      <td>{item.baseCurrency}</td>
                      <td>{item.quoteCurrency}</td>
                      <td>{item.rate}</td>
                      <td>{item.commission}</td>
                    </tr>
                  ))
                  :
                  <>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>Loading...</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>No Data...</span>   
                      </td>
                  </tr>
                  }
                  </>
                  
                }
               
                </tbody>
              </table>
            </div>
            <ul className="pagination"> 
            {/* <li><Link onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</Link></li>
            <li><Link onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= tableShowData.length}>Next</Link></li> */}

              <li>
                {currentPage > 1 ? (
                  <Link onClick={() => setCurrentPage(currentPage - 1)}>Previous</Link>
                  ) : (
                  <Link style={{color:'#555'}}>Previous</Link>
                )}
              </li>
              <li>
                {indexOfLastItem < tableShowData.length ? (
                  <Link onClick={() => setCurrentPage(currentPage + 1)}>Next</Link>
                  ) : (
                  <Link style={{color:'#555'}}>Next</Link>
                )}
              </li>

          </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InstaTrades;
