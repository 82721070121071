import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './VolumeDiscount.css';

import axios from 'axios';

import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';

function VolumeDiscount() {
   
  const [data, setData] = useState([]);

  /*
   * Pagination and Orderby
   */

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // const [sortBy, setSortBy] = useState({ key: null, ascending: true });
  const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });

  // Function to sort data based on column key and sort order
  const sortedData = data.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  // Function to handle column header click for sorting
  const handleSort = (key) => {
    if (sortBy.key === key) {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      setSortBy({ key, ascending: true });
    }
  };

  // Function to handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  /*
  * Pagination and Orderby Ended
  */
 
  const checkExchangeToken = async () => {

    const token = localStorage.getItem("token");
 
    // Axios GET Request - 1
    const result = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/Get_User_Volume_Discount_Limits`, {
      headers: {
        'Authorization': `Bearer ${token}` // Bearer token gönderimi
      }
    })
      setData(result?.data?.data);
      console.log("result");
      console.log(result?.data?.data);
 
    }
      
  useEffect(() => {
    checkExchangeToken();
  }, []);

  return ( 
    <>
 
 <Header />
      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Account" submenu="AccountVolumeDiscount" />

        <div className="main-content">
          <h4 className="title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.1387 14.5H12.78H15C15.3978 14.5 15.7794 14.342 16.0607 14.0607C16.342 13.7794 16.5 13.3978 16.5 13V5C16.5 4.60218 16.342 4.22064 16.0607 3.93934C15.7794 3.65804 15.3978 3.5 15 3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V13C3.5 13.3978 3.65804 13.7794 3.93934 14.0607C4.22064 14.342 4.60218 14.5 5 14.5H7.22H7.86134L7.7049 15.122L7.5819 15.611L7.54821 15.7449L7.45055 15.8426L6.64661 16.6465C6.64659 16.6465 6.64657 16.6465 6.64655 16.6466C6.57668 16.7165 6.52909 16.8055 6.50981 16.9025C6.49053 16.9995 6.50043 17.1 6.53827 17.1913C6.5761 17.2827 6.64017 17.3608 6.72238 17.4157C6.80455 17.4706 6.90116 17.5 7 17.5L12.1387 14.5ZM12.1387 14.5L12.2951 15.122M12.1387 14.5L12.2951 15.122M12.2951 15.122L12.4181 15.611L12.4518 15.7449M12.2951 15.122L12.4518 15.7449M12.4518 15.7449L12.5494 15.8426M12.4518 15.7449L12.5494 15.8426M12.5494 15.8426L13.3534 16.6465M12.5494 15.8426L13.3534 16.6465M13.3534 16.6465C13.3534 16.6465 13.3534 16.6465 13.3534 16.6466M13.3534 16.6465L13.3534 16.6466M13.3534 16.6466C13.4233 16.7165 13.4709 16.8055 13.4902 16.9025M13.3534 16.6466L13.4902 16.9025M13.4902 16.9025C13.5095 16.9995 13.4996 17.1 13.4617 17.1913M13.4902 16.9025L13.4617 17.1913M13.4617 17.1913C13.4239 17.2827 13.3598 17.3608 13.2776 17.4157M13.4617 17.1913L13.2776 17.4157M13.2776 17.4157C13.1954 17.4706 13.0988 17.5 13 17.5M13.2776 17.4157L13 17.5M13 17.5C13 17.5 12.9999 17.5 12.9999 17.5M13 17.5H12.9999M12.9999 17.5H7.00011H12.9999ZM4.5 12V12.5H5H8.771H15H15.5V12V5V4.5H15H5H4.5V5V12Z"
                stroke="white"
              />
            </svg>
            Trading Volume Discount
          </h4>

          <div className="content-box mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Tier</th>
                    <th>Trade Volume BTC</th>
                    <th>Discount</th>
                  </tr>
                </thead>
                <tbody>
                {
              data?.length>0 ?  
                  data.map((item, index) => (
                    <tr key={item.id}>
                      <td>Tier {index}</td>
                      <td>{item?.tradedVolumeLimit} BTC</td>
                      <td>{item?.discount} %</td>
                    </tr>
                  ))
                  :
                  <>
                  <tr key="1" style={{height:200}}>
                      <td colSpan={6} align="center">
                      <img src="/images/loading.gif" />
                      <br /><br />
                      <span style={{color:'#ccc'}}>Loading...</span>   
                      </td>
                  </tr>
                  </>
                  
                }
          
                </tbody>
              </table>
            </div>
       
          </div>
        </div>
      </section>
      <Footer />


 
    </>
  );
}

export default VolumeDiscount;