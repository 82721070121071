// import React from 'react';
// import * as XLSX from 'xlsx';

// const ExportExcelButton = ({ data, filename }) => {
//   const exportToExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(data);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     XLSX.writeFile(wb, `${filename}.xlsx`);
//   };

//   return (
//     <a className='btn btn-primary' onClick={exportToExcel}>Export to Excel</a>
//   );
// };

// export default ExportExcelButton;

import React from 'react';
import * as XLSX from 'xlsx';

const ExportExcelButton = ({ data, filename }) => {
  const exportToExcel = () => {
    // Check if data is not empty
    if (data.length === 0) {
      return;
    }

    // Extract column names from the first object in the data array
    const columns = Object.keys(data[0]);

    // Prepare data for Excel export with column names as the first row
    const excelData = [columns].concat(data.map(row => columns.map(column => row[column])));

    // Create worksheet from the data
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save workbook to file
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <a className='btn btn-primary' onClick={exportToExcel}>Export to Excel</a>
  );
};

export default ExportExcelButton;

