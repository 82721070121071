import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Deposits.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";
import QRCode from "qrcode.react";
import CoinInformation from "../../../Common/CoinInformation.json";

import { useSelector, useDispatch } from "react-redux";
import {
  setSelectDepositMethodValue,
  setSelectDepositCurrency,
} from "../../../Features/Wallet/WalletSlice";

import {
  fetchMarketData,
  getCurrencySettings,
} from "../../../Components/MarketData";

import SelectDepositType from "./SelectDepositType";
import DepositTransferDetails from "./DepositTransferDetails";

import {
  splitDateTime,
  formatNumberWithComma,
  splitCoinCurrency,
} from "../../../Common/Helpers";

import axios from "axios";
import Select from "react-select";

import ExportExcelButton from "../../../Common/ExportExcelButton";
import DownloadPDFButton from "../../../Common/DownloadDynPDFButton";
import CsvExporter from "../../../Common/CsvExporter";

const tableHeaders = [
  "depositReqDate",
  "depositConfirmDate",
  "depositConfirmDate",
  "depositType",
  "depositAmount",
  "txnHash",
];
const tableHeaders2 = [
  "requestDate",
  "requestAmount",
  "transactionID",
  "status",
  "currency",
  "status",
  "type",
];

function Deposits() {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [currency, setCurrency] = useState("ALL");
  const [depositEnabledList, setDepositEnabledList] = useState([]);
  const [cryptoDiv, setCryptoDiv] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [depositMethod, setDepositMethod] = useState("");

  const [searchWord, setSearchWord] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen2, setIsOpen2] = useState(false);
  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [expandedRow, setExpandedRow] = useState(null);
  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const [expandedRow2, setExpandedRow2] = useState(null);
  const handleRowClick2 = (index) => {
    setExpandedRow2(expandedRow2 === index ? null : index);
  };

  const functionChangeParam1 = (value) => {
    getDepositRequests();
  };

  const handleSearch = (e) => {
    let newKeyword = e.target.value;

    setSearchWord(newKeyword);

    if (newKeyword) {
      let tmpSearchWord = newKeyword;

      if (displayData) {
        let filteredShowArray = displayData.filter((item) =>
          item.depositType
            .toLocaleLowerCase("en-US")
            .includes(tmpSearchWord.toLocaleLowerCase("en-US"))
        );
        setDisplayData(filteredShowArray);
      }
    }
  };

  const deleteRecordFunction = async (id = 0) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Cancel_Fiat_Manual_Deposit_Request`,
          { ID: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.status == "Success") {
            toast.success("Record Deleted!", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            window.location.reload();
          } else {
            toast.error("An error occured. Please try again later.", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          toast.error("An error occured. Please try again later.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (error) {
      toast.error("An error occured. Please try again later.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    getDepositRequests();
    return false;
  };

  const handleDepositMethodChange = (selectedOption) => {
    setDepositMethod(selectedOption);
    dispatch(setSelectDepositMethodValue(selectedOption));
  };

  const handleChange = (selectedOption) => {
    setSearchWord("");
    setSelectedOption(selectedOption);
    handleCurrencyChange(selectedOption.currency);
    dispatch(setSelectDepositCurrency(selectedOption.currency));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#121928" : state.data.bgColor, // Set background color based on bgColor property
      color: state.isSelected ? "#fff" : "#ccc", // Text color
      padding: 8,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#ccc", // Change text color of the selected value
    }),
    input: (provided) => ({
      ...provided,
      color: "#ccc", // Change text color while typing
    }),
  };

  function isItemInArray(array, item) {
    if (Array.isArray(array) && item) {
      const lowercaseArray = array.map((item) => item.toLowerCase());
      const lowercaseItem = item.toLowerCase();
      return lowercaseArray.includes(lowercaseItem);
    }
    return false;
  }

  const listAllAddresses = async (myCurrency = "ALL") => {
    setIsLoading(true);
    try {
      const mRes = await axios
        .get(`${process.env.REACT_APP_API_REST_URL}/api/ListAllAddresses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAllAddresses(response?.data?.data);
          console.log(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }

    try {
      const mRes = await axios
        .get(`${process.env.REACT_APP_API_REST_URL}/api/CurrencySettings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setDepositEnabledList(
            response.data.data
              .filter((x) => x.depositEnabled)
              .map((x) => x.shortName)
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
    return true;
  };

  const handleCopy = () => {
    const address = document.getElementById("address");
    address.select();
    document.execCommand("copy");
    alert("Address copied to clipboard: " + address.value);
  };

  const handleHashCopy = (tmpAddress) => {
    // Attempt to write the value to the clipboard
    navigator.clipboard
      .writeText(tmpAddress)
      .then(() => {
        // console.log('Value copied to clipboard:', tmpAddress);
      })
      .catch((error) => {
        // console.error('Failed to copy value to clipboard:', error);
      });
    alert("Address copied to clipboard: " + tmpAddress);
  };

  const generateDepositAddress = async (tmpCurrency) => {
    // const newAddress = { btc:'123132213' }
    // const updatedAddresses = { ...allAddresses, ...newAddress };
    // setAllAddresses(updatedAddresses);

    try {
      const mRes = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/GenerateAddress`,
          { currency: tmpCurrency.toLowerCase() },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status == "Success") {
            listAllAddresses();
            toast.success("Deposit Address Generated!", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // window.location.reload();
          } else {
            toast.error("Deposit Address Generation Error!", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          toast.error("An error occured. Please try again later.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });

      if (mRes?.data?.status == "Success") {
        setSearchWord("");
        setSelectedOption(tmpCurrency);
        handleCurrencyChange(tmpCurrency);
        dispatch(setSelectDepositCurrency(tmpCurrency));
      }
    } catch (error) {
      toast.error("An error occured. Please try again later.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const cryptoDivHtml = () => {
    let selectedCurrency = currency;

    return getWalletType(selectedCurrency) === "Token" &&
      allAddresses[selectedCurrency?.toLowerCase()] &&
      selectedCurrency != "ALL" &&
      isItemInArray(depositEnabledList, selectedCurrency) ? (
      <>
        <div className="container">
          <div className="row deposits-page">
            <div className="col text-center">
              <h3>{selectedCurrency}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <QRCode
                value={
                  allAddresses[selectedCurrency.toLowerCase()]
                    ? allAddresses[selectedCurrency.toLowerCase()]
                    : ""
                }
                size="128"
                style={{ border: "10px solid white" }}
              />
            </div>
          </div>
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col">
              <ul style={{ listStyleType: "disc" }} className="deposits-info">
                <li>
                  Do not use this address to deposit any other asset than{" "}
                  {selectedCurrency}. Deposits of other assets to this address
                  may result in loss of funds.
                </li>
                <li>
                  This is your only address for depositing {selectedCurrency}.
                  Multiple deposits can be processed simultaneously.
                </li>
                <li>
                  {selectedCurrency} will be deposited into your account once
                  the transaction reaches confirmations.
                </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col-md-2 deposit-address">
              <span>Deposit Address</span>
            </div>
            <div className="col-md-8">
              <input
                className="textbox form-control"
                id="address"
                value={allAddresses[selectedCurrency.toLowerCase()]}
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-md-2">
              <button
                className="btn button-danger px-0"
                onClick={handleCopy}
                style={{ width: "100%" }}
              >
                Copy Deposit Address
              </button>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        {getWalletType(selectedCurrency) === "Token" &&
        isItemInArray(depositEnabledList, selectedCurrency) ? (
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="col-md-2">
                <button
                  className="btn button-danger"
                  onClick={() => generateDepositAddress(selectedCurrency)}
                  style={{ width: "300px" }}
                >
                  Generate&nbsp;Deposit&nbsp;Address&nbsp;for&nbsp;
                  {selectedCurrency}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {getWalletType(selectedCurrency) === "Token" &&
            !isItemInArray((depositEnabledList, selectedCurrency)) && 
            currency!="ALL"
            ? (
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <h3 className="text-center">
                    <span>
                      This coin is not supported
                    </span>
                  </h3>
                </div>
              </div>
            ) : (
              <>
                {getWalletType(selectedCurrency) != "Token" ? (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <span style={{ color: "#ddd", paddingBottom: "5px" }}>
                          Select a Deposit Method
                        </span>
                        <SelectDepositType
                          value={depositMethod}
                          currency={selectedCurrency}
                          onChange={handleDepositMethodChange}
                        /> 
                        <DepositTransferDetails
                          changeParam1={functionChangeParam1}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const handleCurrencyChange = async (selectedCurrency) => {
    setCurrency(selectedCurrency);

    let filteredArray = [];

    if (selectedCurrency == "ALL") {
      filteredArray = data;
      setDisplayData(data);
      console.log(filteredArray);
    } else {
      filteredArray = data.filter(
        (item) => item.depositType === selectedCurrency
      );
      setDisplayData(filteredArray);
      console.log(filteredArray);
    }
  };

  const fetchData = async () => {
    try {
      // let marketDataResponse = await fetchMarketData();
      let marketDataResponse = await getCurrencySettings();

      const coinInformation = CoinInformation;
      for (let i = 0; i < marketDataResponse.length; i++) {
        marketDataResponse[i].ShortName = marketDataResponse[i].shortName;
        marketDataResponse[i].LongName = marketDataResponse[i].fullName;
        marketDataResponse[i].currency = marketDataResponse[i].ShortName;
      }

      marketDataResponse.sort((a, b) => {
        const nameA = a.shortName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.shortName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      setMarketData(marketDataResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getWalletType = (shortName) => {
    const upperCaseShortName = shortName.toUpperCase();
    let walletType = "Token";
    if (marketData) {
      const foundObject = marketData.find(
        (item) => item.shortName.toUpperCase() === upperCaseShortName
      );

      let orgWalletType = foundObject?.walletType;
      if (orgWalletType) {
        if (orgWalletType.startsWith("Fiat")) {
          walletType = "Fiat";
        }
      }
    }
    return walletType;
  };

  const getDepositAddress = async (myCurrency = "ALL") => {
    setIsLoading(true);

    try {
      const mRes = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/GetDeposits`,
          { currency: myCurrency },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Bearer token gönderimi
            },
          }
        )
        .then((response) => {
          let latestData = response?.data?.data?.deposits;
          setData(response?.data?.data?.deposits);

          if (searchWord) {
            let filteredShowArray = latestData.filter((item) =>
              item.depositType
                .toLocaleLowerCase("en-US")
                .includes(searchWord.toLocaleLowerCase("en-US"))
            );
            setDisplayData(filteredShowArray);
          } else {
            setDisplayData(latestData);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }

    return false;
  };

  const getDepositRequests = async () => {
    setIsLoading(true);

    try {
      const mRes = await axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/List_Fiat_Manual_Deposit_Requests?currency=${currency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Bearer token
            },
          }
        )
        .then((response) => {
          // console.log(response);
          setDepositData(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }

    return false;
  };

  useEffect(() => {
    getDepositAddress();
    getDepositRequests();
    fetchData();
    listAllAddresses();
  }, [searchWord]);

  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page withdrawals">
        <SideBar mainmenu="Wallet" submenu="WalletDeposits" />
        <div className="main-content">
          <h4 className="title">Deposits</h4>

          <div className="row">
            <div className="col-lg-12 mb20">
              <form className="form-flex">
                <div className="withdrawals-dropdown w-100">
                  <Select
                    className="custom-select"
                    value={selectedOption}
                    onChange={handleChange}
                    options={marketData}
                    getOptionValue={(option) => option.currency}
                    getOptionLabel={(option) =>
                      `${option.LongName} (${option.currency})`
                    }
                    isSearchable={true}
                    placeholder="ALL Coins - Please Select"
                    styles={customStyles}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-lg-12 mb20">{cryptoDiv}</div> */}

            {currency ? cryptoDivHtml(currency) : <></>}
          </div>
          <div className="mt5">
            {currency &&
            currency != "ALL" &&
            getWalletType(currency) != "Token" ? (
              <>
                <div className="row">
                  <div className="col-lg-10 mb20">&nbsp;</div>
                  <div className="col-lg-2 dropdown">
                    <button
                      onClick={toggleDropdown2}
                      className="btn btn-primary"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      Export Data
                    </button>

                    {isOpen2 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "20",
                          zIndex: "999",
                        }}
                      >
                        <DownloadPDFButton
                          data={depositData}
                          headers={tableHeaders2}
                        />
                        <div style={{ paddingBottom: "5px" }} />
                        <CsvExporter data={depositData} />
                        <div style={{ paddingBottom: "5px" }} />
                        <ExportExcelButton
                          data={depositData}
                          filename="exported_data"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>REQUESTED</th>
                        <th>DEPOSIT AMOUNT</th>
                        <th>TRANSACTION ID</th>
                        <th>STATUS</th>
                        <th>DEPOSIT TYPE</th>
                        <th>DELETE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositData?.length > 0 ? (
                        depositData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr
                              key={item.id}
                              onClick={() => handleRowClick(index)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <div className="date">
                                  {splitDateTime(item.requestDate).date}
                                  <span>
                                    {" "}
                                    {splitDateTime(item.requestDate).time}
                                  </span>
                                </div>
                              </td>

                              <td>
                                {item?.requestAmount} {currency ? currency : ""}
                              </td>
                              <td>{item?.transactionID}</td>
                              <td>{item?.status}</td>
                              <td>{item?.type}</td>
                              <td>
                                {item?.type == "Bank" &&
                                item?.status == "Pending" ? (
                                  <button
                                    className="button-danger"
                                    onClick={() =>
                                      deleteRecordFunction(item?.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                            {expandedRow === index && (
                              <tr>
                                <td colSpan="5">
                                  <span
                                    style={{ color: "#aaa", fontSize: "10pt" }}
                                  >
                                    Requested:{" "}
                                    {splitDateTime(item.requestDate).date}{" "}
                                    {splitDateTime(item.requestDate).time}
                                    <br />
                                    Deposit Amount: {item?.requestAmount}{" "}
                                    {currency ? currency : ""}
                                    <br />
                                    Comments: {item?.comments}
                                  </span>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <>
                          {isLoading ? (
                            <tr key="1" style={{ height: 200 }}>
                              <td colSpan={6} align="center">
                                <img src="/images/loading.gif" width={50} />
                                <br />
                                <br />
                                <span style={{ color: "#ccc" }}>
                                  Loading...
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <tr key="1" style={{ height: 200 }}>
                              <td colSpan={6} align="center">
                                <br />
                                <br />
                                <span style={{ color: "#ccc" }}>
                                  No Data...
                                </span>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-10 mb20">
                    <form className="form-flex">
                      <div className="search">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                            stroke="#9CA3AF"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchWord}
                          onChange={handleSearch}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={13}
                          height={14}
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                            fill="#111928"
                            stroke="#9CA3AF"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-2 dropdown">
                    <button
                      onClick={toggleDropdown}
                      className="btn btn-primary"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        width: "100%",
                        height: 47
                      }}
                    >
                      Export Data
                    </button>

                    {isOpen && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "20",
                          zIndex: "999",
                        }}
                      >
                        <DownloadPDFButton
                          data={displayData}
                          headers={tableHeaders}
                        />
                        <div style={{ paddingBottom: "5px" }} />
                        <CsvExporter data={displayData} />
                        <div style={{ paddingBottom: "5px" }} />
                        <ExportExcelButton
                          data={displayData}
                          filename="exported_data"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>REQUESTED</th>
                        <th>DEPOSITED</th>
                        <th>COIN</th>
                        <th>AMOUNT</th>
                        <th>TRANSACTION HASH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData?.length > 0 ? (
                        displayData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr
                              key={item.id}
                              onClick={() => handleRowClick2(index)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <div className="date">
                                  {splitDateTime(item.depositReqDate).date}
                                  <span>
                                    {" "}
                                    {splitDateTime(item.depositReqDate).time}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="date">
                                  {splitDateTime(item.depositConfirmDate).date}
                                  <span>
                                    {" "}
                                    {
                                      splitDateTime(item.depositConfirmDate)
                                        .time
                                    }
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="pair">
                                  <img
                                    src={`/images/coins/${item.depositType.toLowerCase()}.svg`}
                                    alt=""
                                  />
                                  {item.depositType}
                                </div>
                              </td>
                              <td>{item.depositAmount}</td>
                              <td>{item?.txnHash}</td>
                            </tr>
                            {expandedRow2 === index && (
                              <tr>
                                <td colSpan="5">
                                  <span
                                    style={{ color: "#aaa", fontSize: "10pt" }}
                                  >
                                    <b>Deposit Type:</b> {item?.depositType}
                                    <br />
                                    <b>
                                      tables.depositHistory.networkName:
                                    </b>{" "}
                                    {item?.networkName}
                                    <br />
                                    <b>Deposit Address:</b>{" "}
                                    {item?.depositAddress}
                                    <br />
                                    <b>Deposit Amount:</b> {item?.depositAmount}
                                    <br />
                                    <b>Transaction Hash:</b> {item?.txnHash}
                                    <br />
                                    <b>Requested:</b> {item?.depositReqDate}
                                    <br />
                                    <b>Deposited:</b> {item?.depositConfirmDate}
                                    <br />
                                    <b>Transaction Count:</b>{" "}
                                    {item?.currentTxnCount}
                                    <br />
                                    <b>Required Transaction Count:</b>{" "}
                                    {item?.requiredTxnCount}
                                    <br />
                                    <b>Block Explorer URL:</b>{" "}
                                    {item?.explorerURL}
                                    &nbsp;&nbsp;
                                    <a
                                      onClick={() =>
                                        handleHashCopy(item?.explorerURL)
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      <svg
                                        aria-label="Copy"
                                        viewBox="0 0 24 24"
                                        className="StyledIcon-ofa7kd-0 kyfZlJ"
                                        style={{
                                          cursor: "pointer",
                                          width: "17px",
                                          height: "17px",
                                        }}
                                      >
                                        <path
                                          fill="none"
                                          stroke="#ccc"
                                          strokeWidth="2"
                                          d="M9,15 L17,15 L9,15 Z M9,11 L19,11 L9,11 Z M9,7 L13,7 L9,7 Z M16,1 L16,7 L22,7 M6,5 L2,5 L2,23 L18,23 L18,19 M22,19 L6,19 L6,1 L17,1 L22,6 L22,19 L22,19 Z"
                                        ></path>
                                      </svg>
                                    </a>
                                    <br />
                                  </span>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <>
                          {isLoading ? (
                            <tr key="1" style={{ height: 200 }}>
                              <td colSpan={6} align="center">
                                <img src="/images/loading.gif" width={50} />
                                <br />
                                <br />
                                <span style={{ color: "#ccc" }}>
                                  Loading...
                                </span>
                              </td>
                            </tr>
                          ) : (
                            <tr key="1" style={{ height: 200 }}>
                              <td colSpan={6} align="center">
                                <br />
                                <br />
                                <span style={{ color: "#ccc" }}>
                                  No Data...
                                </span>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Deposits;
