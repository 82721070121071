import React from 'react';

function SelectDepositType({ value, onChange }) {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <select
      className="form-control"
      onChange={handleOnChange}
      style={{ backgroundColor:'#374151', color: '#ddd' }}
    >
      <option value="SELECT">Select a Deposit Method</option>
      <option value="bankTransfer" selected={value === "bankTransfer"} >Bank Transfer</option>
      <option value="card" selected={value === "card"}>Credit or Debit Card Deposit</option>
    </select>
  )
}

export default SelectDepositType;
