import React, { useState, useEffect, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import './InstaTrade.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';
import {fetchMarketData, fetchMarketMergedData, fetchMarketMergedInstaData, convertCurrency} from '../../../Components/MarketData';
import {getBalance} from '../../../Components/Wallet';
  
import { useSelector } from 'react-redux';
import { findFullNameOfCoin } from "../../../Common/Helpers";
 
import CustomModal from '../../../Components/CustomModal/';

import axios from "axios";

import { useModalContext } from "../../../Context/ModalContext";

function InstaTrade() {

  const { selectedTheme } = useModalContext();

    
  const currencySettings = useSelector(state => state.currencySettings.data);

  const [pairs, setPairs] = useState();
  const [pairsAll, setPairsAll] = useState();
  const [pairsOfTarget, setPairsOfTarget] = useState();
  
  const [isOpen, setIsOpen] = useState(false);
  const [isTargetOpen, setTargetIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedTargetItem, setSelectedTargetItem] = useState('');

  const [amount, setAmount] = useState();
  const [predefineAmount, setPredefineAmount] = useState();
  const [changeRatio, setChangeRatio] = useState();
   
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [currentCryptoPrices, setCurrentCryptoPrices] = useState([]);
 
  const [displayDefault, setDisplayDefault] = useState();

  const [userLatestBalance, setUserLatestBalance] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const[message, setMessage] = useState(); 

  const dropdownRef = useRef(null);
  const targetDropdownRef = useRef(null);

  const getUserBalance = async () => {
    let userBalance = await getBalance();
    if (userBalance) {
     const userBalanceArr = userBalance.data?.data;
     const sortedUserBalanceArr = [...userBalanceArr].sort((a, b) => b.balance - a.balance);
     setUserLatestBalance(sortedUserBalanceArr);
    }
  }
  
  function getQuoteCurrencies(baseCurrency) {
    const matchingPairs = pairsAll.filter(pair => pair.baseCurrency === baseCurrency);
    const quoteCurrencies = matchingPairs.map(pair => pair.quoteCurrency);
    return quoteCurrencies;
  }

  const getRateByCurrency = (currency) => {

    // Convert input currency to lowercase for case-insensitive comparison
    const lowerCaseCurrency = currency.toLowerCase();
    
    // Find the object with the matching currency (ignoring case)
    const currencyObject = currentCryptoPrices.find(
      (crypto) => crypto.currency.toLowerCase() === lowerCaseCurrency
    );

    // If the currency is found, return its rate; otherwise, return null or any default value
    return currencyObject ? currencyObject.rate : 0;
  }
 
  const getRatioByCurrency = (currency) => {

    // Convert input currency to lowercase for case-insensitive comparison
    const lowerCaseCurrency = currency.toLowerCase();
    
    // Find the object with the matching currency (ignoring case)
    const currencyObject = currentCryptoPrices.find(
      (crypto) => crypto.currency.toLowerCase() === lowerCaseCurrency
    );

    // If the currency is found, return its rate; otherwise, return null or any default value
    return currencyObject ? currencyObject.priceChangePercent7d : 0;
  }


  const calculatePredefinedAmount = async () => {
    if (currentCryptoPrices.length>0) {
      let calculatedResult=0; 
      if (selectedItem && selectedTargetItem && currentCryptoPrices) {
        calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, 1, currentCryptoPrices);
        let remaining = getRateByCurrency(selectedItem)
        setPredefineAmount(
          <>
          <ul className="list">
          <li onClick={() => setAmount(String(Math.floor(remaining * (1/5))))}>{Math.floor(remaining * (1/5))} {selectedItem}</li>
          <li onClick={() => setAmount(String(Math.floor(remaining * (2/5))))}>{Math.floor(remaining * (2/5))} {selectedItem}</li>
          <li onClick={() => setAmount(String(Math.floor(remaining * (3/5))))}>{Math.floor(remaining * (3/5))} {selectedItem}</li>
          <li onClick={() => setAmount(String(Math.floor(remaining * (4/5))))}>{Math.floor(remaining * (4/5))} {selectedItem}</li>
          <li onClick={() => setAmount(String(remaining))}>Maximum {selectedItem}</li>
          </ul>
          </>
        )
      }  
    } else {
      setPredefineAmount(
          <p>Please Select Coin</p> 
      )
    }
  }
 
  const calculatePredefinedRatio = async () => {
    let ratio="";
    if (currentCryptoPrices.length>0) { 
      if (selectedItem && currentCryptoPrices) { 
        ratio = getRatioByCurrency(selectedItem) 
        setChangeRatio(
          <>  
          <div className="ratio-btn" style={{ backgroundColor: ratio > 0 ? '#DEF7EC' : '#bb4444' }}>
          {ratio} %
          </div> 
          </>
        )
      }  
    } else {
      setChangeRatio(
        <>
        {ratio}
        </>
      )
    }
  }

  const calculateDisplayPriceFunction = async () => {
    if (currentCryptoPrices.length>0) {
      let calculatedResult=0; 
      if (selectedItem && selectedTargetItem && currentCryptoPrices) {
        calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, 1, currentCryptoPrices);
        setDisplayDefault(
          <>
          <span className="v01">1 {selectedItem} </span>
          <span className="v02">≈ {calculatedResult} {selectedTargetItem}</span>
          </>
        )
      }  
    } else {
      setDisplayDefault(
        <>
          <span className="v01">Please Select Coin</span> 
        </>
      )
    }
  }

const calculateTargetPrice = async () => {
  
  if (currentCryptoPrices.length>0) {
    let calculatedResult=0;
    if (selectedItem && selectedTargetItem && amount && currentCryptoPrices) {
      // do not write characters check
      const result = amount.replace(/[^0-9.]/g, '');
      setAmount(result); 
      calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, amount, currentCryptoPrices);
      calculatedResult = calculatedResult*0.99;
      setCalculatedAmount(calculatedResult)
    } else {
      setCalculatedAmount(calculatedResult)
    }
  } else {
    setAmount(""); 
  }
}
 
  const instaBuySell = async () => {

    let reqBody = {
      // side: "BUY", // Fixed in this flow
      // market: selectedTargetItem, 
      // trade: selectedItem, 
      // amount: amount
      baseAmount:amount,
      baseCurrency:selectedItem,
      quoteCurrency:selectedTargetItem,
      okx_quote_id:"",
      order_id:""
    }

    const token = localStorage.getItem("token");
    const response = await axios.post(
      // `${process.env.REACT_APP_API_REST_URL}/api/PlaceOrder_Priced`,
      `${process.env.REACT_APP_API_REST_URL}/api/request_insta_trade`, 
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
    ).catch(error => {
      console.error('Post Error:', error);
    });
    console.log(reqBody);
    // console.log(response?.data?.data);
    // alert(response?.data?.data);
    setMessage(response?.data?.data)
    handleShow();
   }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleTargetDropdown = () => {
    setTargetIsOpen(!isTargetOpen);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    const result = getQuoteCurrencies(item);
    setPairsOfTarget(result)
  };

  const handleTargetSelect = (item) => {
    setSelectedTargetItem(item);
    setTargetIsOpen(false);
  };

  const handleClickOutside = (event) => { 
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (targetDropdownRef.current && !targetDropdownRef.current.contains(event.target)) {
      setTargetIsOpen(false);
    } 
  }

  const fetchMarketDataFunction = async () => {
    // let latestFiatData = await fetchMarketData();
    // let latestFiatData = await fetchMarketMergedData();

    let latestFiatData = await fetchMarketMergedInstaData();
     
    // console.log(latestFiatData);
    setCurrentCryptoPrices(latestFiatData);
  }

  useEffect(() => {
    
    // Restful Version
    const interval = setInterval(() => {
      fetchMarketDataFunction().catch(console.error); 
    }, 2500); 

    // Websocket Version
    

  },[]);

  useEffect(() => {
    calculateTargetPrice();
  },[amount, selectedItem, selectedTargetItem]);
 
  useEffect(() => {
    calculateDisplayPriceFunction();
    calculatePredefinedAmount();
    calculatePredefinedRatio();
  },[selectedItem, selectedTargetItem]);
 
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  useEffect(() => {
     


    const fetchData = async () => {
      const response = await axios.get (
        process.env.REACT_APP_API_REST_URL+'/api/get_insta_pairs'
      );
      
      let rawData = response?.data?.data;
         // console.log("insta-pairs:"+ JSON.stringify(rawData) );
      if (rawData) {
        const extractedBaseCurrencies = rawData.map(item => item.baseCurrency);
        setPairs(extractedBaseCurrencies);
        setPairsAll(rawData)
         // console.log("insta-pairs-map:"+ JSON.stringify(extractedBaseCurrencies) );
      }
    }
 
    fetchData();
    getUserBalance();
  }, []);



  if (localStorage.getItem("token")) {
          return (
            <>
            <Header />
      
            <CustomModal 
        show={show} 
        handleClose={handleClose} 
        title="Insta Trade" 
        message={message}
      />

            <PriceBar />
        {/* Trade Card */}
        <div className="container">
                <div className="trade-card">
                  <div className="head"><img src={selectedTheme.name === "dark" ? "images/icons/fire.svg" : "images/icons/fire-black.svg"} alt="" />Insta Trade</div>
                  <div className="you-have">
                    <div className="tt">You have :</div>
                    <ul>
                      <li>{userLatestBalance[0]?.balance} {userLatestBalance[0]?.currency}</li>
                      <li>{userLatestBalance[1]?.balance} {userLatestBalance[1]?.currency}</li>
                    </ul>
                  </div>
        
                  <div className="price">
                    {displayDefault}
                  </div>
        
                  <h4>Estimated value</h4>
                  
                    {changeRatio}
                  
                  
                  <div className="item">
                    <h2>Payment Amount</h2>
                    <span> 
                    <img src={`/images/coins/"BTC".toLowerCase()}.svg`} alt="" />
                    </span> 
                      {predefineAmount} 
                    <div className="coin-input"> 
            <div className={`dropdown ${isOpen ? 'show' : ''}`} ref={dropdownRef}>
              <button className={`dropdown-toggle ${isOpen ? 'show' : ''}`} type="button" onClick={toggleDropdown}>
              <img src={`images/coins/${selectedItem.toLowerCase()}.svg`} alt="" />
                {selectedItem}
              </button>
              <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                {pairs?.map((data, index) => (
                  <li key={index} onClick={() => handleSelect(data)}>
                    <Link to="" className="dropdown-item"> 
                      <img src={`images/coins/${data.toLowerCase()}.svg`} alt="" /> 
                      &nbsp;
                      {data}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            
            <input type="text" 
              placeholder={0.00000000000} 
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
              style={{color:'#ccc'}} 
            />
            
            </div>
            </div>
            <div className="item">
            <h2>Total Recieved</h2>
            <div className="coin-input">
        
            <div className={`dropdown ${isTargetOpen ? 'show' : ''}`} ref={targetDropdownRef}>
              <button className={`dropdown-toggle ${isTargetOpen ? 'show' : ''}`} type="button" onClick={toggleTargetDropdown}>
                <img src={`images/coins/${selectedTargetItem.toLowerCase()}.svg`} alt="" />
                {selectedTargetItem}
              </button>
              <ul className={`dropdown-menu ${isTargetOpen ? 'show' : ''}`}>
                {pairsOfTarget?.map((data, index) => (
                  <li key={index} onClick={() => handleTargetSelect(data)}>
                    <Link to="" className="dropdown-item">
                      <img src={`images/coins/${data.toLowerCase()}.svg`} alt="" /> 
                      &nbsp;
                      {data}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
        
            <input type="text" placeholder={0.00000000000} value={calculatedAmount} disabled style={{color:'#ccc'}}/>
            </div>
            <p>This amount is an estimate after fees are collected.</p>
            </div>
            <button className="button" onClick={() => instaBuySell()}>Trade</button>
        
            </div>
            </div>
              {/* Trade Card End */}
        
              <Footer />
              
            </>
          );
  } else {
    return (
      <>
          <Header />
          <PriceBar /> 

            <div className="row">
            <div className="col-md-12 centered-items">
            <h1 style={{color:'#aaa', paddingTop:55}}>You are Logged Out!</h1>
            </div>
            <div className="col-md-12 centered-items">
            <h4 style={{color:'#aaa', paddingTop:55}}>
            <Link to="/login">Please Login</Link> 
            </h4> 
            </div>
            <p><br /><br /></p>
            </div>
          <Footer />
      </>
    )
  }


}

export default InstaTrade;
