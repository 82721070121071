import React, { useEffect, createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const [themeNo, setThemeNo] = useState(2);


  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    return savedLanguage
      ? JSON.parse(savedLanguage)
      : {
          id: 1,
          name: "english",
          code: "en",
          icon: "/images/flag/us.svg",
        };
  });
  const [selectedNumberFormat, setSelectedNumberFormat] = useState(() => {
    const savedNumberFormat = localStorage.getItem("selectedNumberFormat");
    return savedNumberFormat
      ? JSON.parse(savedNumberFormat)
      : {
          id: 1,
          name: "Language Default",
        };
  });
  const [selectedFiatEstimation, setSelectedFiatEstimation] = useState(() => {
    const savedFiatEstimation = localStorage.getItem("selectedFiatEstimation");
    return savedFiatEstimation
      ? JSON.parse(savedFiatEstimation)
      : {
          id: 1,
          name: "USD",
        };
  });

  const [selectedTheme, setSelectedTheme] = useState(() => {
    const savedTheme = localStorage.getItem("selectedTheme");
    return savedTheme
      ? JSON.parse(savedTheme)
      : {
        id: 1,
        name: "dark",
        icon: "/images/icons/moon.svg",
        };
  });

  useEffect(() => {
    localStorage.setItem("selectedTheme", JSON.stringify(selectedTheme));
  }, [selectedTheme]);

  useEffect(() => {
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
  }, [selectedLanguage]);

  useEffect(() => {
    localStorage.setItem("selectedNumberFormat", JSON.stringify(selectedNumberFormat));
  }, [selectedNumberFormat]);

  useEffect(() => {
    localStorage.setItem("selectedFiatEstimation", JSON.stringify(selectedFiatEstimation));
  }, [selectedFiatEstimation]);

  const toggleSettingsModal = () => {
    setSettingsModalVisible((prev) => !prev);
  };

  return (
    <ModalContext.Provider
      value={{
        settingsModalVisible,
        toggleSettingsModal,
        selectedLanguage,
        setSelectedLanguage,
        selectedNumberFormat,
        setSelectedNumberFormat,
        selectedFiatEstimation,
        setSelectedFiatEstimation,
        selectedTheme,
        setSelectedTheme,
        themeNo,
        setThemeNo
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
