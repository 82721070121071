// import React from 'react';

// const CsvExporter = ({ data }) => {
//   const downloadCsv = () => {
//     const csvContent = "data:text/csv;charset=utf-8," 
//         + data.map(row => Object.values(row).join(",")).join("\n");
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "exported_data.csv");
//     document.body.appendChild(link);
//     link.click();
//   };

//   return (
//     <div>
//       <button className='btn btn-primary' onClick={downloadCsv}>Export CSV</button>
//     </div>
//   );
// };

// export default CsvExporter;

import React from 'react';

const CsvExporter = ({ data }) => {
  const downloadCsv = () => {
    // Check if data is not empty
    if (data.length === 0) {
      return;
    }

    // Generate header row
    const headers = Object.keys(data[0]);

    // Generate CSV content with headers
    const csvContent = "data:text/csv;charset=utf-8," 
        + [headers.join(",")].concat(data.map(row => headers.map(header => row[header]).join(","))).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exported_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <button className='btn btn-primary' onClick={downloadCsv}>Export CSV</button>
    </div>
  );
};

export default CsvExporter;

