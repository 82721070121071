import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Trade.css";
import { formatNumberWithComma } from "../../../Common/Helpers";  

export const RecentTrades = ({ coinsData, coin, primaryCoin, MARKET, activeMarket }) => {
  
  const getSocketVariables = (coin, primaryCoin, activeMarket) => {
    const base = coin ? coin.base : primaryCoin?.base;
    return {
      SocketVariableOb: `OB.${base}_${activeMarket}`,
      SocketVariableRT: `RT.${base}_${activeMarket}`,
      SocketVariablePO: `PO.${base}_${activeMarket}`,
    };
  };

  const [socketVars, setSocketVars] = useState(getSocketVariables(coin, primaryCoin, activeMarket));

  const [transactions, setTransactions] = useState();
  const [asks, setAsks] = useState([]);
  const [bids, setBids] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [trades, setTrades] = useState([]);
  const [latestTradePrice, setLatestTradePrice] = useState(0);
  const [latestTradeType, setLatestTradeType] = useState("BUY");

  const calculatedAsks = asks?.map((ask, index) => {
    const total = asks?.slice(0, index + 1).reduce((sum, ask) => sum + ask[1], 0);
    return { price: ask[0], size: ask[1], total: total.toFixed(6) };
  });

  const calculatedBids = bids?.map((bid, index) => {
    const total = bids?.slice(0, index + 1).reduce((sum, bid) => sum + bid[1], 0);
    return { price: bid[0], size: bid[1], total: total.toFixed(6) };
  });

  const ws = useRef(null);

  const subscribeToEvents = () => {
   // console.log("subscribe");
    ws.current.send(JSON.stringify({
        method: "subscribe",
        events: [socketVars.SocketVariableOb, socketVars.SocketVariableRT, socketVars.SocketVariablePO],
    }));
   
    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data?.event === socketVars.SocketVariableOb) {
        if (data?.data?.asks?.length > 10) {
          const sortedAsks = data.data.asks.sort((a, b) => b[0] - a[0]);
          setAsks(sortedAsks.slice(-10));
        } else {
          setAsks(data.data.asks.sort((a, b) => b[0] - a[0]));
        }
        if (data?.data?.bids?.length > 10) {
          const sortedBids = data.data.bids.sort((a, b) => b[0] - a[0]);
          setBids(sortedBids.slice(0, 10));
        } else {
          setBids(data.data.bids.sort((a, b) => b[0] - a[0]));
        }
      } else if (data?.event === socketVars.SocketVariableRT) {
        if (data?.data && data?.data[0]) {
          setTrades([...data.data]);
          setLatestTradePrice(data.data[0].rate);
          setLatestTradeType(data.data[0].execution_side);
        }
      } else if (data?.event === socketVars.SocketVariablePO) {
        if (data?.data) {
          let openOrderList = [...openOrders];
          data.data.forEach((item) => {
            if (item?.status === false) {
              const index = openOrderList.findIndex(
                (oldOrder) => oldOrder.order_id === item.order_id
              );
              if (index === -1) {
                openOrderList.push(item);
              } else {
                openOrderList[index] = item;
              }
            } else {
              const index = openOrderList.findIndex(
                (oldOrder) => oldOrder.order_id === item.order_id
              );
              if (index > -1) {
                openOrderList.splice(index, 1);
              }
            }
          });
          setOpenOrders(openOrderList.sort((a, b) => b.timestamp.localeCompare(a.timestamp)).filter(item => item?.base === primaryCoin?.base));
        } else {
          setOpenOrders([]);
        }
      }
    };
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    ws.current = new WebSocket('wss://node1.ledgerbytes.tech/ws');

    ws.current.onopen = () => {
      if (token) {
        ws.current.send(JSON.stringify({
          method: "login",
        }));

        ws.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.method === "login" && data.status === "success") {
            subscribeToEvents();
          }
        };
      } else {
        subscribeToEvents();
      }
    };

    ws.current.onerror = (error) => {
      console.log("WebSocket error: " + error);
    };

    ws.current.onclose = () => {
    //  console.log("WebSocket connection closed");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);



  useEffect(() => {
    const newSocketVars = getSocketVariables(coin, primaryCoin, activeMarket);
    setSocketVars(newSocketVars);
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      subscribeToEvents();
    }
  }, [coinsData, coin, primaryCoin]);

  return (
    <div className="border-top custom-border-color border-secondary px-2">
    <div className="table-responsive overflow-x-hidden mt-2">
      <h6 className="recent-trades-title">Recent Trades</h6>
      <div className="table-recent-trades-wrapper">
      <table className="table table-sm table-no-border mb-1">
        <thead>
          <tr className="small">
            <th className="text-muted px-0">Price</th>
            {coin ? (
              <th className="text-muted">Size[{coin.base}]</th>
            ) : (
              <th className="text-muted">Size[{primaryCoin?.base}]</th>
            )}
            <th className="text-muted">Time</th>
          </tr>
        </thead>
        
        <tbody className="mb-2">
          {trades?.map((trade, index) => {
            const timestamp = trade?.timestamp;
            const date = new Date(timestamp);

            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            const formattedTime = `${hours}:${minutes}:${seconds}`;

            return (
            <tr key={index} className="border-0 small">
                <td className={trade?.execution_side === 'SELL' ? "text-danger border-0 px-0" : "text-success border-0 px-0"}>{formatNumberWithComma(trade?.rate)}</td>
                <td className="border-0">{trade?.volume}</td>
                <td className="border-0">{formattedTime}</td>
            </tr>
             )})}
          </tbody> 
      </table>
      </div>
    </div>
    </div>
  )
}
