import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isAuthenticated: false,
    userToken: null,
    userProfile: null,
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.userToken = action.payload.userToken;
      state.userProfile = action.payload.userProfile;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userToken = null;
      state.userProfile = null;
    },
  },
});

export const { login, logout } = sessionSlice.actions;
export const selectIsAuthenticated = (state) => state.session.isAuthenticated;
export const selectUserProfile = (state) => state.session.userProfile;
export const selectUserToken = (state) => state.session.userToken;
export default sessionSlice.reducer;