
import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Table } from '@react-pdf/renderer';

import { formatNumberWithComma } from "../Common/Helpers";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    marginBottom: 10,
    borderBottom: '1 solid black',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    padding: 5,
    paddingLeft: 10,
    fontSize: 8, // Adjust font size
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 6, // Adjust font size
  },
  listItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    padding: 5,
    paddingLeft: 10,
    size:5,
    fontSize: 6, // Adjust font size
  },
  listItemText: {
    marginLeft: 10,
  },
});

const PdfDocument = ({ data, headers }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text>PDF Export</Text>
        </View>

        <View style={styles.tableHeader}>
          {headers.map((header, index) => (
            <Text key={index} style={[styles.headerText, { flex: 1 }]}>
              {header}
            </Text>
          ))}
        </View>
 
        {data.map((item, index) => (
          <View key={index} style={styles.listItem}>
            {headers.map((header, index) => (
              <Text key={index} style={[styles.listItemText, { flex: 1 }]}>
                {item[header] ? formatNumberWithComma(item[header]) : ""}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

const PdfGenerator = ({ data, headers }) => {
 
  return (
    <div>
   
      <PDFDownloadLink className='btn btn-primary' document={<PdfDocument data={data} headers={headers} />} fileName="exported_data.pdf">
        {({ blob, url, loading, error }) =>
          'Export PDF'
        }
      </PDFDownloadLink> 
    </div>
  );
};

export default PdfGenerator;
