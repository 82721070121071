import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './ApiKeys.css';

import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';

import CustomModal from '../../../Components/CustomModal';

import ApiModal from './ApiModal';
import KeyModal from './KeyModal';

import axios from "axios";

function ApiKeys() {
   
  const token = localStorage.getItem("token");

  const [is2FaEnabled, setIs2FaEnabled] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [deleteRecord, setDeleteRecord] = useState();

  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal1 = () => setShowModal1(false);
  const handleShowModal1 = () => setShowModal1(true);
 
  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);
  const [privateKey, setPrivateKey] = useState('');
  const [publicKey, setPublicKey] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [copiedKey, setCopiedKey] = useState(null);
 
  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key);
    setCopiedKey(key);
    alert("Copied to Clipboard")
  };

  const handleOkButtonClick = async () => {
 
    try { 
      let reqBody = {
        key: deleteRecord
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/DeleteApiKey`, 
        reqBody,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
        }
      ).catch(error => {
        console.error('Post Error:', error);
      });
 
    } catch (error) {
      console.log("Error" + error);
    } 
    handleClose(); 
  }

  const deleteRecordFunction = async (id) => {
    setDeleteRecord(id); 
    setShow(true);
  }
 
  const addApiFunction = async (returnObj) => {

      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/GenerateApiKey`, 
        returnObj,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
        }
      ).catch(error => {
        console.error('Post Error:', error);
        alert( JSON.stringify(response?.data) );
      });

      if (response?.data?.status && response?.data?.status=="Success") {
      
        if (response?.data?.data?.privateKey) {
          setPrivateKey(response?.data?.data?.privateKey)
        } else {
          setPrivateKey('')
        }

        if (response?.data?.data?.publicKey) {
          setPublicKey(response?.data?.data?.publicKey)
        } else {
          setPublicKey('')
        }
 
        setShowModal2(true);

      } else {
        alert( JSON.stringify(response?.data?.data) );

      }
      handleCloseModal1();
  }

  
  const check2Fa = async () => {
 
    await axios
    .get(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setIs2FaEnabled(response?.data?.data?.is2FAEnabled); 
    })
    .catch((error) => {
      console.error("GET isteği hatası:", error);
    });
  }
 
  const getApiKeys = async () => {
 
    let reqBody = {
      keyType: "ALL"
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/ListApiKey`, 
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
      ).then((response) => {
        console.log(response?.data?.data);
        setApiKeys(response?.data?.data)
      }
      ).catch(error => {
      console.error('Post Error:', error);
    });

  }

 

  useEffect(() => {
    check2Fa();
    getApiKeys();
  }, [showModal1, show]);

  return (
    <>
     <Header />

     <CustomModal 
        show={show} 
        handleClose={handleClose} 
        title="Delete API KEY" 
        message="Do you want to continue with the change?"
        handleOk={handleOkButtonClick}
      />
 
      <KeyModal 
        showModal2={showModal2} 
        handleCloseModal2={handleCloseModal2} 
        privateKey={privateKey}
        publicKey={publicKey}
      />

     <ApiModal 
        showModal1={showModal1} 
        handleCloseModal1={handleCloseModal1} 
        addApi={addApiFunction}
      />

     {/* Main */}
      <section className="main-page">
      <SideBar mainmenu="Account" submenu="AccountApiKeys" />

      <div className="main-content">
        <h4 className="title">
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 8.00008C18.0003 8.93725 17.781 9.86145 17.3598 10.6986C16.9386 11.5358 16.3271 12.2627 15.5744 12.821C14.8216 13.3792 13.9486 13.7535 13.0252 13.9136C12.1018 14.0737 11.1538 14.0153 10.257 13.7431L10 14.0001L9 15.0001L8 16.0001H6V18.0001H2V14.0001L6.257 9.74308C6.00745 8.91809 5.93857 8.04902 6.05504 7.19502C6.17152 6.34102 6.47062 5.52215 6.93199 4.79412C7.39336 4.0661 8.00616 3.44602 8.72869 2.97609C9.45122 2.50617 10.2665 2.19743 11.1191 2.07088C11.9716 1.94434 12.8415 2.00296 13.6693 2.24276C14.4972 2.48256 15.2637 2.89791 15.9166 3.46054C16.5696 4.02317 17.0936 4.71987 17.4531 5.50322C17.8127 6.28656 17.9992 7.13817 18 8.00008ZM12 4.00008C11.7348 4.00008 11.4804 4.10543 11.2929 4.29297C11.1054 4.48051 11 4.73486 11 5.00008C11 5.26529 11.1054 5.51965 11.2929 5.70718C11.4804 5.89472 11.7348 6.00008 12 6.00008C12.5304 6.00008 13.0391 6.21079 13.4142 6.58586C13.7893 6.96094 14 7.46964 14 8.00008C14 8.26529 14.1054 8.51965 14.2929 8.70718C14.4804 8.89472 14.7348 9.00008 15 9.00008C15.2652 9.00008 15.5196 8.89472 15.7071 8.70718C15.8946 8.51965 16 8.26529 16 8.00008C16 6.93921 15.5786 5.92179 14.8284 5.17165C14.0783 4.4215 13.0609 4.00008 12 4.00008Z" fill="white" />
          </svg>
          API Keys
        </h4>
        <div className="box-item">
          <div className="api-key">
            <p>Use this page to manage your API keys to interact with the exchange.</p>
            <p>When creating an API key be sure to store the private key somewhere safe, you will only see it once.</p>
            
            
            {is2FaEnabled 
            ?
            <>
            <button className="button mt30"
            onClick={()=>handleShowModal1()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM11 7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H9V13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H11V7Z" fill="white" />
              </svg>
              Add New Key
            </button>
            <div className="table-responsive mt15">
              <table className="table">
                <thead>
                  <tr>
                    <th>KEY</th>
                    <th>TYPE</th>
                    <th>COPY</th>
                    <th>DELETE</th>
                  </tr>
                </thead>
                <tbody>
                {
              apiKeys?.length>0 ?  
              apiKeys.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item?.key}</td>
                      <td>{item?.type} </td>
                      <td> 
                        <button className="button-danger"
                        onClick={() => handleCopyKey(item.key)}
                        > 
                        Copy
                      </button>
                      </td>
                      <td>
                      <button className="button-danger"
                        onClick={() => deleteRecordFunction(item?.key)}
                        > 
                        Delete
                      </button>
 
                        </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                }
                  
                </tbody>
              </table>
            </div>
            </>
            :
            <></>
            }



            {!is2FaEnabled 
            ?
            <div className="null-box">
              <img src="images/materials/google.png" alt="" />
              <div className="text">
                <h5>You must enable Google Authenticator to use this feature</h5>
              </div>
              <Link to="/account/security" className="button">
                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.49998 1.55518C6.77594 3.10223 4.54899 3.97096 2.23278 3.99998C2.14478 4.51918 2.09998 5.05518 2.09998 5.59998C2.09998 9.77998 4.77198 13.336 8.49998 14.6536C12.228 13.336 14.9 9.77998 14.9 5.59998C14.9 5.05438 14.8544 4.51998 14.7672 3.99918C12.4511 3.97038 10.2241 3.10194 8.49998 1.55518ZM9.29998 11.2C9.29998 11.4121 9.21569 11.6156 9.06566 11.7657C8.91563 11.9157 8.71215 12 8.49998 12C8.2878 12 8.08432 11.9157 7.93429 11.7657C7.78426 11.6156 7.69998 11.4121 7.69998 11.2C7.69998 10.9878 7.78426 10.7843 7.93429 10.6343C8.08432 10.4843 8.2878 10.4 8.49998 10.4C8.71215 10.4 8.91563 10.4843 9.06566 10.6343C9.21569 10.7843 9.29998 10.9878 9.29998 11.2ZM9.29998 5.59998C9.29998 5.3878 9.21569 5.18432 9.06566 5.03429C8.91563 4.88426 8.71215 4.79998 8.49998 4.79998C8.2878 4.79998 8.08432 4.88426 7.93429 5.03429C7.78426 5.18432 7.69998 5.3878 7.69998 5.59998V7.99998C7.69998 8.21215 7.78426 8.41563 7.93429 8.56566C8.08432 8.71569 8.2878 8.79998 8.49998 8.79998C8.71215 8.79998 8.91563 8.71569 9.06566 8.56566C9.21569 8.41563 9.29998 8.21215 9.29998 7.99998V5.59998Z" fill="white" />
                </svg>
                Enable 2FA
              </Link>
            </div>
            :
            <></>
            }
            

          </div>
        </div>
      </div>

      </section>
     <Footer />
    </>
  );
}

export default ApiKeys;