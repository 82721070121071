  import React, { useState, useEffect } from "react";
  import Modal from 'react-bootstrap/Modal';
  import Button from 'react-bootstrap/Button';
  
  const ApiModal = ({ showModal1, handleCloseModal1, addApi }) => {
    
      const [ipAddress, setIpAddress] = useState('');
      const [keyType, setKeyType] = useState('trade');
      const [twoFactorAuthKey, setTwoFactorAuthKey] = useState('');
  
    const addApiFunction = () => {

      if (ipAddress === null || ipAddress === '') {
        alert("Please Enter Whitelisted IP Address");
        return false;
      }

      if (twoFactorAuthKey === null || twoFactorAuthKey === '') {
        alert("Please Enter 2FA Code");
        return false;
      }
  
      let reqBody = {
          IpAddresses:ipAddress,
          keyType:keyType,
          twoFactorAuthKey:twoFactorAuthKey
        }
        addApi(reqBody);  

        const delayedFunction = () => {
          setIpAddress('');
          setTwoFactorAuthKey('');
        };
    
        // Set a timeout for 2 seconds and call the delayed function
        const timeoutId = setTimeout(delayedFunction, 2000);
  
    }
      
    useEffect(() => {  
      }, []);

    return (
      <Modal show={showModal1} onHide={handleCloseModal1} className="add-api-modal">
        <Modal.Header closeButton>
          <Modal.Title>ADD API</Modal.Title>
        </Modal.Header>

        <Modal.Body>
  
        <div className='row' style={{paddingBottom:'15px', paddingTop:'15px'}}>
          <div className='col-md-12'>
              <div className="form-group">
                  <label for="usr">Currency:</label>
                  <select className="form-control" onChange={(e) => setKeyType(e.target.value)}> 
                    <option value={"trade"}>Trade</option>
                    <option value={"readonly"}>Read Only</option> 
                  </select>
              </div>
          </div>
        </div>

        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group">
              <label for="usr">Enter Whitelisted IP Address:</label>
              <input 
              type="text" 
              className="form-control"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
              />
          </div>
        </div>
        </div>
  
        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group">
              <label for="usr">2FA :</label>
              <input 
              type="text" 
              className="form-control"
              value={twoFactorAuthKey}
              onChange={(e) => setTwoFactorAuthKey(e.target.value)}
              />
          </div>
        </div>
        </div>
  
        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group"> 
            <button class="btn button-danger" type="button" style={{width:'100%'}} onClick={addApiFunction}>
            Generate Key
            </button> 
          </div>
        </div>
        </div>
  
        </Modal.Body>
        <Modal.Footer> 
          <Button variant="secondary" onClick={handleCloseModal1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  export default ApiModal;
