export const VolumeData = [
  {
    id: 1,
    change_in_price: 0,
    price: 0,
    base: 'Loading',
    quote: '....'
  },
  {
    id: 2,
    change_in_price: 0,
    price: 0,
    base: 'Loading',
    quote: '....'
  },
  {
    id: 3,
    change_in_price: 0,
    price: 0,
    base: 'Loading',
    quote: '....'
  }
];

export const IncreasingData = [
    {
      id: 1,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    },
    {
      id: 2,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    },
    {
      id: 3,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    }
  ];

  export const DecreasingData = [
    {
      id: 1,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    },
    {
      id: 2,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    },
    {
      id: 3,
      change_in_price: 0,
      price: 0,
      base: 'Loading',
      quote: '....'
    }
  ];

    