import { Link, useFetcher } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef, act } from "react";

import { fetchTransactionData } from "../../../Components/MarketData";
import { segregateAndSortTrades, segregateAndSortTradesUniqueRate } from "../../../Common/Helpers.js"
 
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
import axios from 'axios';

import { TradeHeader } from "./TradeHeader.js";
import { AnnounceBar } from "./AnnounceBar";
import { BuySellOrder } from "./BuySellOrder";
import { OrderBook } from "./OrderBook";
import { RecentTrades } from "./RecentTrades";
import { Transactions } from "./Transactions";
import { TradingView } from "./TradingView";
import { Portfolio } from "./Portfolio";
import { VolumeData, IncreasingData, DecreasingData, TableData } from "../../../Common/DummyData";  
import { useNavigate } from 'react-router-dom';


function Trade() {
  const navigate = useNavigate();

  const { pair } = useParams();
  let currentPair = pair;




const [pairLoading, setPairLoading] = useState(true);

const [newCoin, setNewCoin] = useState("");
const [MARKET, SETMARKET] = useState("");
const [activeMarket, setActiveMarket] = useState("");


useEffect(() => {
  const fetchDefaultPair = async () => {
    try {
      const response = await fetch("https://node1.ledgerbytes.tech/api/GetSettings");
      const data = await response.json();
      console.log("data", data)
      const defaultPair = data.data.default_Pair.split("_");
      setNewCoin(defaultPair[0]);
      SETMARKET(defaultPair[1]);
      setActiveMarket(defaultPair[1])
      setPairLoading(false)
    } catch (error) {
      console.error("Error fetching default pair:", error);
    }
  };

  fetchDefaultPair();
}, []);

const [pairs, setPairs] = useState([]);
const [selectedPair, setSelectedPair] = useState();
const [searchWord, setSearchWord] = useState();
const [increasingData, setIncreasingData] = useState(IncreasingData);
const [decreasingData, setDecreasingData] = useState(DecreasingData);
const [volumeData, setVolumeData] = useState(VolumeData);
const [isLoading, setIsLoading] = useState(true);






const [dropdownVisible, setDropdownVisible] = useState(false);

const [tableData, setTableData] = useState([]);
const [tableShowData, setTableShowData] = useState([]);

const [coinsData, setCoinsData] = useState([]);


 
  var ws = useRef();


  useEffect(() => {

    const token = localStorage.getItem("token");

    setIsLoading(true);
    ws.current = new WebSocket('wss://node1.ledgerbytes.tech/ws');
    
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify({
          method: 'subscribe',
          events: ['MK']
        })
      );
    };


     // WebSocket üzerinden gelen mesajları dinleyin
     ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message?.data) {
        let dataArray = message?.data;
        setCoinsData(dataArray)
        const uniqueQuotes = [...new Set(dataArray.map(item => item.quote))];
        setPairs(uniqueQuotes)
 
        if (!selectedPair) {
          setSelectedPair(uniqueQuotes[0])
        }

        // Filter selectedPair
     //   let filteredArray = dataArray.filter(item => item.quote === selectedPair);


//        filteredArray.sort((a, b) => b.price - a.price);
   //     setTableData(filteredArray)
   setTableData(dataArray)

      
        if (searchWord) {
          let tmpSearchWord = searchWord.toUpperCase();
          let filteredShowArray = dataArray.filter(item => 
            item.base.includes(tmpSearchWord) 
            || item.quote.includes(tmpSearchWord)
            );
       //   setTableShowData(filteredShowArray);
        } else {
    //      setTableShowData(dataArray);
        }  
         
        dataArray.sort((a, b) => b.base_volume - a.base_volume);
    //    setVolumeData(dataArray.slice(0, 3));
  
        // Sort the array by change_in_price in descending order
        dataArray.sort((a, b) => b.change_in_price - a.change_in_price);

        // Extract the top 6 high positive values
        const topSixHighPositive = dataArray
          .filter(item => item.change_in_price > 0)
          .slice(0, 5);

      //    setIncreasingData(topSixHighPositive);
          
        // Extract the 3 lowest values (negative or zero)
        const threeLowest = dataArray.slice(-3);
        //setDecreasingData(threeLowest);
 
      }
    };


    // WebSocket bağlantısı kapandığında
    ws.current.onclose = () => {
      console.log('Trade WebSocket connection closed.');
    }; 


    setIsLoading(false);
        // Komponentin sonlandırılması durumunda WebSocket bağlantısını kapatın
        return () => {
          if (ws.current) {
            ws.current.close();
          }
        };

        // Go once initially
  
  }, [selectedPair, searchWord]);

useEffect(() => {
  console.log("table-show-data", tableShowData);
}, [tableShowData]);


const filterTableData = (pair) => {
  if (!tableData || !pair) return [];

  let filteredArray = tableData.filter(item => item.quote === pair);
  
  if (searchWord) {
      const tmpSearchWord = searchWord.toUpperCase();
      filteredArray = filteredArray.filter(item => 
          item.base.includes(tmpSearchWord) || item.quote.includes(tmpSearchWord)
      );
  }

  filteredArray.sort((a, b) => b.price - a.price);
  setTableShowData(filteredArray);

  filteredArray.sort((a, b) => b.base_volume - a.base_volume);
  setVolumeData(filteredArray.slice(0, 3));

  filteredArray.sort((a, b) => b.change_in_price - a.change_in_price);
  setIncreasingData(filteredArray.filter(item => item.change_in_price > 0).slice(0, 5));

  setDecreasingData(filteredArray.slice(-3));
};

useEffect(() => {
  filterTableData(selectedPair);
}, [selectedPair, searchWord]);

  const handleItemClick = (item) => {
    setSelectedPair(item);
    SETMARKET(item);
  }


  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword.toUpperCase();
        let filteredShowArray = tableShowData.filter(item => 
          item.base.includes(tmpSearchWord) || item.quote.includes(tmpSearchWord));
        setTableShowData(filteredShowArray);      
      } 
  }



 

 const coin = coinsData?.find(item => item.base === newCoin && item.quote === activeMarket)
 const primaryCoin = coinsData?.find(item => item.base === newCoin && item.quote === activeMarket);



  const getBalance = async () => {
 
    const token = localStorage.getItem("token");
    const reqBody = { currency: 'ALL' }
  
    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
    ).catch(error => {
      console.error('Post Error:', error);
    });
  
    return response;
  }
  
  
  const [userBalanceArray, setUserBalanceArray] = useState();
  
  const getUserBalance = async () => {
    let userBalance = await getBalance();
    if (userBalance) {
     const userBalanceArr = userBalance.data?.data;
     setUserBalanceArray(userBalanceArr)
    }
  }

  const [tradeCompleted, setTradeCompleted] = useState(false);
  
  useEffect(() => { 
    getUserBalance()
  }, [tradeCompleted])
  



  // example: USDT
  const pairBalance = userBalanceArray?.find(item => item.currency === activeMarket);
  // example: DOGE
  const coinBalance = userBalanceArray?.find(item => item.currency === coin?.base);



  return (
    <>
      <Header 
        coin={coin} 
        primaryCoin={primaryCoin} dropdownVisible={dropdownVisible} setDropdownVisible={setDropdownVisible}
        pairs={pairs}
        selectedPair={selectedPair}
        handleItemClick={handleItemClick}
        tableShowData={tableShowData}
        handleSearch={handleSearch}
        searchWord={searchWord}
        MARKET={MARKET}
        SETMARKET={SETMARKET}
        />
      <AnnounceBar />

      {primaryCoin && (
      <TradeHeader 
      setActiveMarket={setActiveMarket}
      coinsData={coinsData}
      setCoinsData={setCoinsData}

      coin={coin}

      newCoin={newCoin}
      setNewCoin={setNewCoin}
      SETMARKET={SETMARKET}
      primaryCoin={primaryCoin} 
      dropdownVisible={dropdownVisible} 
      setDropdownVisible={setDropdownVisible}

      pairs={pairs}
      selectedPair={selectedPair}
      handleItemClick={handleItemClick}
      tableShowData={tableShowData}
      handleSearch={handleSearch}
      searchWord={searchWord}
      increasingData={increasingData}
    />
      )}



      <div className={dropdownVisible ? "row blured" : "row"}>
        <div className="col-lg-8">
          <TradingView />
        </div>
        <div className="col-lg-2 border-start border-end custom-border-color border-secondary px-0">
          <div className="py-3 px-0 min-vh-100">
            <OrderBook coin={coin} primaryCoin={primaryCoin} MARKET={MARKET} activeMarket={activeMarket} />
            <RecentTrades coinsData={coinsData} coin={coin} primaryCoin={primaryCoin} MARKET={MARKET} activeMarket={activeMarket} /> 
          </div>
        </div>
        <div className="col-lg-2 ps-0">
        <BuySellOrder tradeCompleted={tradeCompleted} setTradeCompleted={setTradeCompleted} market={MARKET} activeMarket={activeMarket} coin={newCoin} coinPrice={coin?.price} pairBalance={pairBalance?.balance} coinBalance={coinBalance?.balance} />
          </div>
      </div> 


      <div className={dropdownVisible ? "row blured" : "row"}>
        <div className="col-lg-10 col-md-10 col-12 px-0">
          <Portfolio tradeCompleted={tradeCompleted} setTradeCompleted={setTradeCompleted} coin={coin} />
        </div>
      </div>

      <div className={dropdownVisible ? "row blured" : "row"}>
        <Footer />
      </div>
    </>
  );
}

export default Trade;
