import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './IpWhitelisting.css';
import { formatDate } from "../../../Common/Helpers";

import CustomModal from '../../../Components/CustomModal/';

import IpModal from './IpModal';

import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';

import axios from "axios";

function IpWhitelisting() {
 
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);


  const [ipList, setIpList] = useState([]);
  const [ipListOrg, setIpListOrg] = useState([]);

  const [deleteDeviceId, setDeleteDeviceId] = useState();
  const [searchWord, setSearchWord] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal1 = () => setShowModal1(false);
  const handleShowModal1 = () => setShowModal1(true);

  const handleOkButtonClick = async () => {
 
    try { 
      let reqBody = {
        cidr: deleteDeviceId,
        type:"Login"
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/Delete_IP_Whitelist`, 
        reqBody,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
        }
      )
      .then((response) => {
        navigate('/logout');
      })
      .catch(error => {
        console.error('Post Error:', error);
      });
 
    } catch (error) {
      console.log("Error" + error);
    } 
    handleClose(); 
  }

  const deleteWhitelistedDevice = async (id) => { 
    setDeleteDeviceId(id); 
    handleShow(); 
  }
   
  const addIpFunction = async (reqBody) => {
    
    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/Add_IP_Whitelist`, 
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
    ).then((response) => {
       
      if (response?.data.status=="BadRequest") {
        alert(response?.data?.data);

      } else if (response?.data.status=="Success") {
        navigate('/logout');

      } else {
        alert("General Error Occured")
      }
      
    })
    .catch(error => {
      console.error('Post Error:', error);
    });
    handleCloseModal1();
 }
 

 const getIpWhiteList = async () => { 
  // Axios ile GET isteği yapalım
  await axios
    .get(
      `${process.env.REACT_APP_API_REST_URL}/api/Get_IP_Whitelist`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token 
        },
      }
    )
    .then((response) => {
      console.log(response?.data?.data);
      setIpList(response?.data?.data);
      setIpListOrg(response?.data?.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("GET isteği hatası:", error);
      setIsLoading(false);
    });
}

const handleSearch = (e) => {
  let newKeyword = e.target.value;
  setSearchWord(newKeyword);

  // setIpList(ipListOrg);

  let tmpList = ipListOrg

    if (newKeyword) {
      let tmpSearchWord = newKeyword;
      let filteredShowArray = tmpList.filter(item => 
        item.cidr.includes(tmpSearchWord)
      );
      setIpList(filteredShowArray);
      setIsLoading(false); 
    } else {
      setIpList(ipListOrg);
    }

}

useEffect(() => {
  setIsLoading(true);
  getIpWhiteList();
}, [show, showModal1]);

  return (
    <>
  <Header />

  <CustomModal 
        show={show} 
        handleClose={handleClose} 
        title="Delete IP from Whitelist" 
        message="Do you want to continue with the change? You will be logged out after operation."
        handleOk={handleOkButtonClick}
      />

<IpModal 
        showModal1={showModal1} 
        handleCloseModal1={handleCloseModal1} 
        addIp={addIpFunction}
      />

{/* Main */}
<section className="main-page">
    <SideBar mainmenu="Account" submenu="AccountIpWhiteListing" />
        <div className="main-content">
          <h4 className="title">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
              <path d="M8.11055 16.4042C9.142 15.3728 10.5409 14.7934 11.9996 14.7934C13.4582 14.7934 14.8571 15.3728 15.8886 16.4042M11.9996 20.0002H12.0096M4.92955 12.9292C8.83355 9.02424 15.1656 9.02424 19.0706 12.9292M1.39355 9.39324C7.25055 3.53624 16.7486 3.53624 22.6066 9.39324" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            IP Whitelisting
          </h4>
          <div className="row">
            <div className="col-lg-4">
              <div className="box-item">
                <button 
                onClick={()=>handleShowModal1()}
                className="add-ip hover01" data-bs-toggle="modal" href="#exampleModalToggle" role="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM11 7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H9V13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9H11V7Z" fill="white" />
                  </svg>
                  Add IP Address
                </button>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="box-item">
                <form className="search">
                  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                    <path d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="#9CA3AF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <input type="text" placeholder="Search" 
                    value={searchWord}
                    onChange={handleSearch}
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} viewBox="0 0 13 14" fill="none">
                    <path d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583" fill="#111928" stroke="#9CA3AF" />
                  </svg>
                </form>
              </div>
            </div>
          </div>
          <div className="content-box mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>IP ADRESS</th>
                    <th>TYPE</th>
                    <th className="text-center">DELETE</th>
                  </tr>
                </thead>
                <tbody> 
                {
              ipList.length>0 ?  
              ipList.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <div className="date">
                          {formatDate(item?.addedOn)}
                          <span></span>
                        </div>
                      </td>
                      <td>
                          {item.cidr}
                      </td>
                      <td>{item.type}</td> 
                      <td>
                        <button className="button-danger" onClick={() => deleteWhitelistedDevice(item.cidr)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.20039 1.6001C7.05186 1.60018 6.90629 1.6416 6.77997 1.71974C6.65365 1.79787 6.55158 1.90963 6.48519 2.0425L5.90599 3.2001H3.20039C2.98822 3.2001 2.78473 3.28438 2.63471 3.43441C2.48468 3.58444 2.40039 3.78792 2.40039 4.0001C2.40039 4.21227 2.48468 4.41575 2.63471 4.56578C2.78473 4.71581 2.98822 4.8001 3.20039 4.8001V12.8001C3.20039 13.2244 3.36896 13.6314 3.66902 13.9315C3.96908 14.2315 4.37604 14.4001 4.80039 14.4001H11.2004C11.6247 14.4001 12.0317 14.2315 12.3318 13.9315C12.6318 13.6314 12.8004 13.2244 12.8004 12.8001V4.8001C13.0126 4.8001 13.216 4.71581 13.3661 4.56578C13.5161 4.41575 13.6004 4.21227 13.6004 4.0001C13.6004 3.78792 13.5161 3.58444 13.3661 3.43441C13.216 3.28438 13.0126 3.2001 12.8004 3.2001H10.0948L9.51559 2.0425C9.4492 1.90963 9.34713 1.79787 9.22081 1.71974C9.0945 1.6416 8.94892 1.60018 8.80039 1.6001H7.20039ZM5.60039 6.4001C5.60039 6.18792 5.68468 5.98444 5.83471 5.83441C5.98473 5.68438 6.18822 5.6001 6.40039 5.6001C6.61256 5.6001 6.81605 5.68438 6.96608 5.83441C7.11611 5.98444 7.20039 6.18792 7.20039 6.4001V11.2001C7.20039 11.4123 7.11611 11.6158 6.96608 11.7658C6.81605 11.9158 6.61256 12.0001 6.40039 12.0001C6.18822 12.0001 5.98473 11.9158 5.83471 11.7658C5.68468 11.6158 5.60039 11.4123 5.60039 11.2001V6.4001ZM9.60039 5.6001C9.38822 5.6001 9.18473 5.68438 9.03471 5.83441C8.88468 5.98444 8.80039 6.18792 8.80039 6.4001V11.2001C8.80039 11.4123 8.88468 11.6158 9.03471 11.7658C9.18473 11.9158 9.38822 12.0001 9.60039 12.0001C9.81256 12.0001 10.016 11.9158 10.1661 11.7658C10.3161 11.6158 10.4004 11.4123 10.4004 11.2001V6.4001C10.4004 6.18792 10.3161 5.98444 10.1661 5.83441C10.016 5.68438 9.81256 5.6001 9.60039 5.6001Z"
                              fill="white"
                            />
                          </svg>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                  :
                  <>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={4} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>Loading...</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={4} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>No Data...</span>   
                      </td>
                  </tr>
                  }
                  </>
                  
                }



                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
 <Footer />
    </>
  );
}

export default IpWhitelisting;