import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async action to fetch currency settings
export const fetchCurrencySettings = createAsyncThunk('currencySettings/fetchCurrencySettings', async () => {

  const response = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/CurrencySettings`);
  
  // console.log("currency setttings 101 ... " + JSON.stringify(response?.data?.data)  );

  if (response?.data?.data) {
    let returnData = response?.data?.data;
    return returnData;
  }
 
});
 

const currencySettingsSlice = createSlice({
  name: 'currencySettings',
  initialState: { data: {}, status: 'idle', error: null },
  reducers: {},
  extraReducers: {
    [fetchCurrencySettings.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCurrencySettings.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchCurrencySettings.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    }
  }
});

export default currencySettingsSlice.reducer;