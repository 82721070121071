import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

function DepositTransferDetails({changeParam1}) {
  
  const navigate = useNavigate();

  let token = localStorage.getItem("token");
  let depositMethod = useSelector((state) => state.wallet.selectDepositMethod);
  let depositCurrency = useSelector(
    (state) => state.wallet.selectDepositCurrency
  );

  const [bankDetails, setBankDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);

  const [bankId, setBankId] = useState('');
  const [comment, setComment] = useState('');
  const [requestAmount, setRequestAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');

  const [requestCardAmount, setRequestCardAmount] = useState('');
  const [totalChargeAmount, setTotalChargeAmount] = useState('');
   
  const requestAmountFunction = (tmpRequestAmount) => {
    // Accept only numeric characters
    const numericInput = tmpRequestAmount.replace(/\D/g, "");
    setRequestAmount(numericInput);
  }

  const requestCardAmountFunction = (tmpRequestCardAmount) => {
    setRequestCardAmount(tmpRequestCardAmount);
    let tmpTotalChargeAmount=0;
    tmpTotalChargeAmount=parseFloat(tmpRequestCardAmount)+1;
    if (isNaN(tmpTotalChargeAmount)) {
        tmpTotalChargeAmount = 0;
    }
    setTotalChargeAmount(tmpTotalChargeAmount);
  }

  const doCardTransferDetail = async () => {
  
    let reqBody = {
        amount:requestCardAmount,
        comment: "",
        currency:depositCurrency,
        fee:1
    }
  
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Add_Fiat_PG_Deposit_Request`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
           
          if (response?.data?.status=="Success") {

            // toast.success(response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2500,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            // });

            if (response?.data?.data?.redirectURL) {
                let newUrl=response?.data?.data?.redirectURL;
                window.open(newUrl, '_blank');
            }
          }
          else {
            toast.error(response?.data?.data, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

        })
        .catch((error) => {
            toast.error("Error Occured", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              console.error("Error:", error);
        });
    } catch (error) {
        toast.error("Error Occured", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
      console.error("Error:", error);
    }
    changeParam1(Math.random());
    return true;
  }

  const doBankTransferDetail = async () => {
  
    let reqBody = {
        BankID:bankId,
        Comments: comment,
        RequestAmount:requestAmount,
        TransactionID:transactionId
    }
 
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Add_Fiat_Manual_Deposit_Request`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.status=="Success") {
            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          else {
            toast.error(response?.data?.data, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

        })
        .catch((error) => {
            toast.error("Error Occured", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              console.error("Error:", error);
        });
    } catch (error) {
        toast.error("Error Occured", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
      console.error("Error:", error);
    }
    changeParam1(Math.random());
    return true;
  }

  const getBankTransferDetail = async () => {
    try {
      const mRes = await axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/List_Fiat_BanksList/${depositCurrency}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.data) {
            setBankDetails(response?.data?.data[0]);
            setBankId(response?.data?.data[0].id)
          }
          console.log(response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
    return true;
  }

  const getCardTransferDetail = async () => {
    try {
      const mRes = await axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/Get_Fiat_PGs?Currency=${depositCurrency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // ..
          if (response?.data?.data) {
            setCardDetails(response?.data?.data[0]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
    return true;
  };

  const getBankTransferDetailHtml = () => {
    return (
      <>
        <div className="row" style={{ color: "#ddd", paddingTop: "25px" }}>
          <div className="col-md-6">
            <h2>Deposit By Bank Transfer</h2>
            <hr />
            <p>Use the information below to initiate a bank transfer.</p>
            <h2>Bank Transfer Details</h2>
            <p>
              <b>Bank Name</b>
              <br />
              {bankDetails?.bankName}
              <br />
              <br />
              <b>Beneficiary Name</b>
              <br />
              {bankDetails?.beneficiaryName}
              <br />
              <br />
              <b>Routing Code</b>
              <br />
              {bankDetails?.bankRoutingCode}
              <br />
              <br />
              <b>Account Number</b>
              <br />
              {bankDetails?.accountNumber}
              <br />
              <br />
              <b>Swift Code</b>
              <br />
              {bankDetails?.swiftCode}
              <br />
              <br />
              <b>Location</b>
              <br />
              {bankDetails?.branchCity}, {bankDetails?.branchCountry}
              <br />
              <br />
            </p>
          </div>
          <div className="col-md-6">
            <h2>Upload Deposit Details</h2>
            <hr />
            <p>
              Once you have executed the bank transfer, kindly complete the form
              below by adding the requested details so we can track the payment
              to your exchange wallet. You will be notified once the funds have
              arrived in your account with us.
            </p>
            <h2>Bank Transfer Details</h2>
            Currency Amount Transfered
            <input className="textbox form-control" value={requestAmount} onChange={(e) => requestAmountFunction(e.target.value)} />
            <br />
            Bank Transaction ID
            <input className="textbox form-control" value={transactionId} onChange={(e) => setTransactionId(e.target.value)}/>
            <br />
            Additional Comments
            <input className="textbox form-control" value={comment} onChange={(e) => setComment(e.target.value)}/>
            <br />
            Upload Proof of Transfer (Optional)
            <input className="textbox form-control" type="file" />
            <br />
            <button 
                className="btn button-danger" 
                style={{ width: "100%" }}
                onClick={doBankTransferDetail}
                >
              Submit
            </button>
          </div>
        </div>
      </>
    );
  };

  const getCardTransferDetaillHtml = () => {
    return (
      <>
        <div className="row" style={{ color: "#ddd", paddingTop: "25px" }}>
          <div className="col-md-12">
            <h2>Upload Deposit Details</h2>
            <br />

            <div className="row">
              <div className="col-md-2">Min&nbsp;Deposit:&nbsp;{cardDetails?.minTxnAmount}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">Max&nbsp;Deposit:&nbsp;{cardDetails?.maxTxnAmount}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">Percent&nbsp;Fee:&nbsp;{cardDetails?.fee_In_Percent}%</div>
              <div className="col-md-2">Min&nbsp;Fee:&nbsp;{cardDetails?.minFee}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">Max&nbsp;Fee:&nbsp;{cardDetails?.maxFee}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
            </div>

            <div className="row" style={{paddingTop:'25px'}}>
              <div className="col-md-12">
              Enter Your Deposit Amount
              <br />
              <input className="textbox form-control" value={requestCardAmount} onChange={(e) => requestCardAmountFunction(e.target.value)} />
              <br />

<b>Processing Fee</b>
<br />
1 {depositCurrency}
<br /><br />

<b>Total Charge Amount (Fee Included)</b>
<br />
{totalChargeAmount} {depositCurrency}
<br /><br />

<b>Disclaimer</b>
<br />
You will now be redirected to our third party payment processing services relating to credit card payments, which is a separate platform owned by a third party.
<br /><br />
            <button 
                className="btn button-danger" 
                style={{ width: "100%" }}
                onClick={doCardTransferDetail}
                >
              Submit
            </button>

              </div>
            </div>

            
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (depositMethod == "bankTransfer") {
      getBankTransferDetail();
    }
    if (depositMethod == "card") {
      getCardTransferDetail();
    }
  }, [depositMethod, depositCurrency]);

  if (depositMethod && depositMethod == "bankTransfer") {
    return getBankTransferDetailHtml();
  }

  if (depositMethod && depositMethod == "card") {
    return getCardTransferDetaillHtml();
  }
}

export default DepositTransferDetails;
