import "./SettingsModal.css";
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from "../Dropdown";
import { useModalContext } from "../../Context/ModalContext";
import { useSelector } from 'react-redux';

export default function SettingsModal() {
  const { 
    settingsModalVisible, 
    toggleSettingsModal, 
    selectedLanguage, 
    setSelectedLanguage, 
    selectedNumberFormat, 
    setSelectedNumberFormat, 
    selectedFiatEstimation, 
    setSelectedFiatEstimation,
    selectedTheme,
    setSelectedTheme
  } = useModalContext();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const labels = useSelector(state => state.labels.data);

  const languages = [
    { id: 1, name: "english", code: "en", icon: "/images/flag/us.svg" },
    { id: 2, name: "serbian", code: "sr", icon: "/images/flag/gb.svg" },
  ];

  const numberFormats = [
    { id: 1, name: "Language Default" },
    { id: 2, name: "Period as Decimal (1,234.56)" },
    { id: 3, name: "Comma as Decimal (1.234,56)" },
  ];

  const fiatEstimations = [
    { id: 1, name: "USD" },
    { id: 2, name: "TRY" },
    { id: 3, name: "EUR" },
    { id: 4, name: "AED" },
    { id: 5, name: "PKR" },
  ];

  const themes = [
    { id: 1, name: "dark", icon: "/images/icons/moon.svg" },
    { id: 2, name: "light", icon: "/images/icons/moon.svg" },
  ];

  const handleSelectLanguage = (language) => setSelectedLanguage(language);
  const handleSelectNumberFormat = (numberFormat) => setSelectedNumberFormat(numberFormat);
  const handleSelectFiatEstimation = (fiatEstimation) => setSelectedFiatEstimation(fiatEstimation);
  const handleSelectTheme = (theme) => setSelectedTheme(theme);
  const toggleDropdown = (dropdown) => setActiveDropdown(activeDropdown === dropdown ? null : dropdown);

  return (
    <Modal show={settingsModalVisible} onHide={toggleSettingsModal} centered className="modal settings-modal">
      <Modal.Header closeButton>
        <Modal.Title>{labels?.settings?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown 
          title={labels?.settings?.theme}
          options={themes}
          selectedOption={selectedTheme}
          onSelectOption={handleSelectTheme}
          dropdownVisible={activeDropdown === "theme"}
          onToggleDropdown={() => toggleDropdown("theme")}
        />
        
        <Dropdown 
          title={labels?.settings?.language}
          options={languages}
          selectedOption={selectedLanguage}
          onSelectOption={handleSelectLanguage}
          dropdownVisible={activeDropdown === "language"}
          onToggleDropdown={() => toggleDropdown("language")}
        />

        <Dropdown 
          title={labels?.settings?.numberFormat}
          options={numberFormats}
          selectedOption={selectedNumberFormat}
          onSelectOption={handleSelectNumberFormat}
          dropdownVisible={activeDropdown === "numberFormat"}
          onToggleDropdown={() => toggleDropdown("numberFormat")}
        />

        <Dropdown 
          title={labels?.settings?.fiat}
          options={fiatEstimations}
          selectedOption={selectedFiatEstimation}
          onSelectOption={handleSelectFiatEstimation}
          dropdownVisible={activeDropdown === "fiatEstimation"}
          onToggleDropdown={() => toggleDropdown("fiatEstimation")}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0 px-4">
        <Button onClick={toggleSettingsModal} className="btn settings-modal-action-btn w-100">
        {labels?.socialTrade?.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
