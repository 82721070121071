import './Verification.css';
import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';

function Verification() {
 
  return (
    <>
  <Header />

{/* Main */}
<section className="main-page">
  <SideBar mainmenu="Account" submenu="AccountVerification" />
  <div className="main-content">
  <div className="main-content">
        <h4 className="title">
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.26686 3.4551C6.91012 3.40375 7.52077 3.15073 8.01186 2.7321C8.56652 2.2597 9.27129 2.00024 9.99986 2.00024C10.7284 2.00024 11.4332 2.2597 11.9879 2.7321C12.4789 3.15073 13.0896 3.40375 13.7329 3.4551C14.4593 3.51316 15.1413 3.82807 15.6566 4.34337C16.1719 4.85867 16.4868 5.54067 16.5449 6.2671C16.5959 6.9101 16.8489 7.5211 17.2679 8.0121C17.7403 8.56677 17.9997 9.27153 17.9997 10.0001C17.9997 10.7287 17.7403 11.4334 17.2679 11.9881C16.8492 12.4792 16.5962 13.0898 16.5449 13.7331C16.4868 14.4595 16.1719 15.1415 15.6566 15.6568C15.1413 16.1721 14.4593 16.487 13.7329 16.5451C13.0896 16.5965 12.4789 16.8495 11.9879 17.2681C11.4332 17.7405 10.7284 18 9.99986 18C9.27129 18 8.56652 17.7405 8.01186 17.2681C7.52077 16.8495 6.91012 16.5965 6.26686 16.5451C5.54043 16.487 4.85843 16.1721 4.34313 15.6568C3.82782 15.1415 3.51292 14.4595 3.45486 13.7331C3.4035 13.0898 3.15049 12.4792 2.73186 11.9881C2.25945 11.4334 2 10.7287 2 10.0001C2 9.27153 2.25945 8.56677 2.73186 8.0121C3.15049 7.52102 3.4035 6.91036 3.45486 6.2671C3.51292 5.54067 3.82782 4.85867 4.34313 4.34337C4.85843 3.82807 5.54043 3.51316 6.26686 3.4551ZM13.7069 8.7071C13.889 8.5185 13.9898 8.2659 13.9875 8.0037C13.9853 7.74151 13.8801 7.49069 13.6947 7.30529C13.5093 7.11988 13.2585 7.01471 12.9963 7.01243C12.7341 7.01015 12.4815 7.11095 12.2929 7.2931L8.99986 10.5861L7.70686 9.2931C7.51826 9.11095 7.26565 9.01015 7.00346 9.01243C6.74126 9.01471 6.49045 9.11988 6.30504 9.30529C6.11963 9.49069 6.01446 9.74151 6.01219 10.0037C6.00991 10.2659 6.1107 10.5185 6.29286 10.7071L8.29286 12.7071C8.48039 12.8946 8.7347 12.9999 8.99986 12.9999C9.26502 12.9999 9.51933 12.8946 9.70686 12.7071L13.7069 8.7071Z" fill="white" />
          </svg>
          Account Verification
        </h4>
 
<div className="row mx-auto">
<h4 className="mx-auto" style={{color:'#aaa', paddingTop:55}}>Under Construction!</h4>
</div>


       
      </div>
  </div> 
      </section>
 <Footer />
    </>
  );
}
 

export default Verification;


 
