import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
  
export const TradingView = ({ props }) => {
  
  const [myVar, setMyVar] = useState(null);
  
  useEffect(() => {
  }, []);
  
  return (
    <div className="trading-view text-white d-flex align-items-center justify-content-center w-100 vh-100">
        Trading View
    </div>
  )
}