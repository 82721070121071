import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import "./Header.css";
import { formatNumberWithComma } from "../../Common/Helpers"; 
import { useModalContext } from "../../Context/ModalContext";

 
export const Header = ({ coin, setCoin, primaryCoin, dropdownVisible, setDropdownVisible, pairs, selectedPair, handleItemClick, tableShowData, handleSearch, searchWord, MARKET, SETMARKET }) => {
  
  const { toggleSettingsModal, selectedTheme, setSelectedTheme, selectedLanguage, setSelectedLanguage } = useModalContext();



  const languages = [
    {
      id: 1,
      name: "english",
      code: "en",
      icon: "/images/flag/us.svg",
    },
    {
      id: 2,
      name: "serbian",
      code: "sr",
      icon: "/images/flag/gb.svg",
    },
  ];

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const themes = [
    {
      id: 1,
      name: "dark",
      icon: "/images/icons/moon.svg",
    },
    {
      id: 2,
      name: "light",
      icon: "/images/icons/moon.svg",
    },
  ];

  const handleSelectTheme = (theme) => {
    setSelectedTheme(theme);
  };

  const timerInitialValue=900;
  const [timer, setTimer] = useState(timerInitialValue);
  const navigate = useNavigate();

  let isInstitutional=false;
  if (process.env.REACT_APP_IS_INSTITUTIONAL==="true") {
    isInstitutional = true;
  }
   
  useEffect(() => {

    if (localStorage.getItem("token")) {
 
          const resetTimer = () => {
            setTimer(timerInitialValue);
          };
          
          const handleUserActivity = () => {
            resetTimer();
          };

          // Set up event listeners for user activity
          window.addEventListener('mousemove', handleUserActivity);
          window.addEventListener('keydown', handleUserActivity);

          const intervalId = setInterval(() => {
            let tmpTimer=timer;
            tmpTimer--;
            // console.log(tmpTimer);
            setTimer(tmpTimer);
            if (timer<1) {
              resetTimer();
              navigate('/logout');
            }
          }, 1000);
          return () => clearInterval(intervalId);
      }

  }, [timer]);
 
  const labels = useSelector(state => state.labels.data);
  const status = useSelector(state => state.status); 
 
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const [userInfo, setUserInfo] = useState("");

  const dropdownRef = useRef(null);
  const targetDropdownRef = useRef(null);

  const handleClickOutside = (event) => { 
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (targetDropdownRef.current && !targetDropdownRef.current.contains(event.target)) {
      setIsOpenProfile(false);
    } 
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownProfile = () => {
    setIsOpenProfile(!isOpenProfile);
  };

  const buttonClassName = `dropdown-toggle${isOpen ? " show" : ""}`;
  const ulClassName = `dropdown-menu${isOpen ? " show" : ""}`;
  const ulStyle = isOpen
    ? {
        position: "absolute",
        inset: "0px auto auto 0px",
        margin: "0px",
        transform: "translate3d(0px, 25.5px, 0px)",
      }
    : {};

  const buttonProfileClassName = `dropdown-toggle${
    isOpenProfile ? " show" : ""
  }`;
  const ulProfileClassName = `dropdown-menu${isOpenProfile ? " show" : ""}`;
  const ulProfileStyle = isOpenProfile
    ? {
        position: "absolute",
        inset: "0px auto auto 0px",
        margin: "0px",
        transform: "translate3d(-0.5px, 36px, 0px)",
      }
    : {};
 
  useEffect(() => {
    const jsonObject = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(jsonObject);
  }, []);

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);



  const handleSelect = (item) => {
    SETMARKET(item.quote)

    setCoin(item)

    setDropdownVisible(false)
  }

   
  if (localStorage.getItem("token")) {
    return (
      <>
        {/* Header */}
        <div className={isMobileMenuVisible ? "d-lg-none mobile-menu open" : "d-lg-none mobile-menu"}>
        <Link to="/" className="logo">
            <img className="destop-logo" 
             src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"} 
            alt="" />
          </Link>
          <button className="button-close-menu" onClick={() => setIsMobileMenuVisible(false)}>
              <img src="/images/icons/x.svg" alt="" />
            </button>
        <ul className="menu mt-4">
              <li>
                <Link to="/">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/home-black.svg" : "/images/icons/home.svg" }  alt="" />
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/trade">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/chart-bar-black.svg" : "/images/icons/chart-bar.svg" }  alt="" />
                  <span>{labels?.navbar?.links?.trade}</span>
                </Link>
              </li>
              {!isInstitutional
              ? 
              <li>
                <Link to="/insta-trade">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/fire-black.svg" : "/images/icons/fire.svg" } alt="" />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">New</span>
                </Link>
              </li>
              :
              <></> 
              }
              <li>
              <Link to="#" className="button-download">
                <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg" }  alt="" />
                Download theApp
              </Link>
              </li>
              <li>
              <button               
              onClick={toggleSettingsModal}
              className="btn btn-transparent button-setting">
              <img src="/images/icons/settings.svg" alt="" />
              <span>Settings</span>
            </button>
              </li>
            </ul>
            <div className="actions d-flex flex-column">
            <Link to="/logout" className="button-logOut mobil-show">
              {labels?.navbar?.links?.logout}
              </Link>
          </div>
        </div>
        <header>
        <Link to="#" className="menu-trigger" onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
            <span />
            <span />
            <span />
          </Link>
          <Link to="/" className="logo index-logo">
            <img className="destop-logo" 
             src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"} 
            alt="" />
            <img className="mobil-logo"  src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"}  alt="" />
          </Link>
          <nav>
            <ul className="menu">
              <li className={currentPath === "/" ? "selected" : ""}>
                <Link to="/">
                <img src={(selectedTheme?.name === "light" && currentPath !== "/") ? "/images/icons/home-black.svg" : "/images/icons/home.svg"} alt="" />
                  <span>{labels?.navbar?.links?.home}</span>
                </Link>
              </li>
              <li className={currentPath === "/market" ? "selected" : ""}>
                <Link to="/market">
                  <img src={(selectedTheme?.name === "light" && currentPath !== "/market") ? "/images/icons/chart-bar-black.svg" : "/images/icons/chart-bar.svg"} alt="" />
                  <span>Market Overview</span>
                </Link>
              </li>

              {!isInstitutional
              ?
              <li className={currentPath === "/trade" ? "selected" : ""}>
                <Link to="/trade">
                <img src={(selectedTheme?.name === "light" && currentPath !== "/trade") ? "/images/icons/trade-black.svg" : "/images/icons/trade.svg"} alt="" />
                  <span>{labels?.navbar?.links?.trade}</span>
                </Link>
              </li>
               :
               <></>
               }
               
              {!isInstitutional
              ? 
              <li className={currentPath === "/insta-trade" ? "selected" : ""}>
                <Link to="/insta-trade">
                  <img src={(selectedTheme?.name === "light" && currentPath !== "/insta-trade") ? "/images/icons/fire-black.svg" : "/images/icons/fire.svg"} alt="" />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">New</span>
                </Link>
              </li>
              :
              <></>
              }

              <Link to="#" className="button-download mobil-show">
              <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg"} alt="" />
                Download theApp
              </Link>
              <Link to="/logout" className="button-logOut mobil-show">
              {labels?.navbar?.links?.logout}
              </Link>
            </ul>
          </nav>

            <div className={currentPath === "/trade" ? "" : "d-none"}>
            <div className="dropdown position-relative d-lg-none d-md-none d-flex">
              <button
              className="btn btn-transparent text-white d-flex align-items-center dropdown-toggle"
              type="button"
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              {coin ? (
                <>
                  {coin.base} / {coin.quote}
                </>
              ) : (
                <>
                  {primaryCoin?.base} / {primaryCoin?.quote} 
                </>
              )}
              
              <img src="/images/icons/caret-down.svg" alt="" className="ps-2" />
            </button>
            <ul
              className={
                dropdownVisible
                  ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
                  : "drop-menu position-absolute ps-3 d-none"
              }
            >
              <ul className="d-flex">
              {pairs?.map((item) => ( 
       
       <li key={item} onClick={() => handleItemClick(item)}  className={selectedPair === item ? 'pair-card selected' : 'pair-card'}>{item}</li>
))}
              </ul>
              <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
                <img src="/images/icons/search.svg" alt="" className="search-icon" />
                <input 
                  type="text" 
                  placeholder="Search"
                  value={searchWord}
                  onChange={handleSearch}
                />
              </div>

              {tableShowData?.map((item, index) => (
                <li key={index} className="coin-card">
                  <button
                    className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                 onClick={() => handleSelect(item)}
                  >
                    <div className="d-flex align-items-center gap-2">
                    <img src={`/images/coins/${item?.base?.toLowerCase()}.svg`} alt="" width={24} height={24} />
                    <div className="d-flex flex-column">
                      <span>{item?.base}/{item.quote}</span>
                      <span className="text-xs text-muted">
                      { formatNumberWithComma(item.base_volume) } {selectedPair}
                      </span>
                    </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span>{ formatNumberWithComma(item.base_volume) }</span>
                      <span className={item?.change_in_price > 0 ? "text-xs text-success" : "text-xs text-danger"}>
                        { parseFloat(item.change_in_price).toFixed(2) }%
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
              </div>
            </div>

          <div className="actions">
            <Link to="/logout" className="button-logOut">
            {labels?.navbar?.links?.logout}
            </Link>
            <Link to="#" className="button-download">
            <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg"} alt="" />
              Download the App
            </Link>
            <Link to="#" className="button-bell d-none d-lg-flex">
              <img src="/images/icons/bell.svg" alt="" />
            </Link>


            {themes.map((theme, index) => (
              <button onClick={() => handleSelectTheme(theme)} className="btn btn-transparent button-moon" key={index} style={{ display: selectedTheme.name === theme.name ? "none" : "" }}>
                {theme.name === "dark" ? (
                  <img src="/images/icons/moon.svg" alt="" />
                ) : (
                  <img src="/images/icons/sun.png" alt="" />
                )}
              </button>
            ))}


            <button 
                onClick={toggleSettingsModal}
                className="btn btn-transparent button-setting d-none d-lg-flex"
              >
                <img src="/images/icons/settings.svg" alt="" />
            </button>



            <div className="dropdown d-none d-lg-flex" ref={dropdownRef}>
              <button
                className={buttonClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={toggleDropdown} 
              >
                <img src={selectedLanguage.icon} alt=""  width={20} height={15} />
                <span className="text-capitalize">{selectedLanguage.name}</span>
              </button>

              <ul
                className={ulClassName}
                style={ulStyle}
                data-popper-placement="bottom-start"
              >
                {languages.map((language, index) => (
                  <li key={index}>
                  <a className="dropdown-item" onClick={() => { handleSelectLanguage(language); toggleDropdown(); }}>
                    {" "}
                    <img src={language.icon} alt="" />
                    <span className="text-capitalize">{language.name}</span>
                  </a>
                </li>
                ))}
              </ul>
            </div>
            <div className="dropdown users" ref={targetDropdownRef}>
              <button
                className={buttonProfileClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpenProfile}
                onClick={toggleDropdownProfile} 
              >
                <img src="/images/materials/avatar.png" alt="" />
                <span>{userInfo?.firstName}</span>
              </button>
              <ul
                className={ulProfileClassName}
                style={ulProfileStyle}
                data-popper-placement="bottom-start"
              >
                <li>
                  <Link to="/account/overview" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/user-circle.svg" alt="" />
                    {labels?.navbar?.links?.account}
                  </Link>
                </li>
                <li>
                  <Link to="/order/open-orders" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/switch-horizontal.svg" alt="" />
                    {labels?.navbar?.links?.orders}
                  </Link>
                </li>
                <li>
                  <Link to="/wallet/overview" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/wallet.svg" alt="" />
                    {labels?.navbar?.links?.wallet}
                  </Link>
                </li>
                <li>
                <a               
              onClick={toggleSettingsModal}
              className="dropdown-item">
              <img src="/images/icons/settings.svg" alt="" />
               {labels?.settings?.title}
            </a>
                </li>
                <li>
                  <Link to="/logout" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/logout.svg" alt="" />
                    {labels?.navbar?.links?.logout}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        {/* Header End */}
      </>
    );
  } else {
    return (
      <>
        {/* Header */}
        <div className={isMobileMenuVisible ? "d-lg-none mobile-menu open" : "d-lg-none mobile-menu"}>
        <Link to="/" className="logo">
            <img className="destop-logo" 
            src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"} 
            alt=""
            />
          </Link>
          <button className="button-close-menu" onClick={() => setIsMobileMenuVisible(false)}>
              <img src="/images/icons/x.svg" alt="" />
            </button>
        <ul className="menu mt-4">
              <li>
                <Link to="/">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/home-black.svg" : "/images/icons/home.svg" } alt="" />
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/trade">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/chart-bar-black.svg" : "/images/icons/chart-bar.svg" }  alt="" />
                  <span>{labels?.navbar?.links?.trade}</span>
                </Link>
              </li>
              {!isInstitutional
              ? 
              <li>
                <Link to="/insta-trade">
                  <img src={selectedTheme?.name === "light" ? "/images/icons/fire-black.svg" : "/images/icons/fire.svg" }  alt="" />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">New</span>
                </Link>
              </li>
              :
              <></>
              }
              <li>
              <Link to="#" className="button-download">
                <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg" }  alt="" />
                Download theApp
              </Link>
              </li>
              <li>
              <button               
              onClick={toggleSettingsModal}
              className="btn btn-transparent button-setting">
              <img src="/images/icons/settings.svg" alt="" />
              <span>Settings</span>
            </button>
              </li>
            </ul>
            <div className="actions d-flex flex-column">
            <Link to="/login" className="button-login mt-3 mb-2">
            {labels?.navbar?.links?.login}
            </Link>
            <Link to="/register" className="button-signUp">
            {labels?.navbar?.links?.signup}
            </Link>
          </div>
        </div>
        <header>
          <Link to="#" className="menu-trigger" onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
            <span />
            <span />
            <span />
          </Link>
          <Link to="/" className="logo">
            <img className="destop-logo"             
              src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"} 
              alt="" 
            />
            <img className="mobil-logo"  src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"} alt="" />
          </Link>
          
          <div className={currentPath === "/trade" ? "" : "d-none"}>
          <div className="dropdown position-relative d-lg-none d-md-none d-flex">
              <button
              className="btn btn-transparent text-white d-flex align-items-center dropdown-toggle"
              type="button"
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              {coin ? (
                <>
                  {coin.base} / {coin.quote}
                </>
              ) : (
                <>
                  {primaryCoin?.base} / {primaryCoin?.quote} 
                </>
              )}
              
              <img src="/images/icons/caret-down.svg" alt="" className="ps-2" />
            </button>
            <ul
              className={
                dropdownVisible
                  ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
                  : "drop-menu position-absolute ps-3 d-none"
              }
            >
              <ul className="d-flex">
              {pairs?.map((item) => ( 
       
       <li key={item} onClick={() => handleItemClick(item)}  className={selectedPair === item ? 'pair-card selected' : 'pair-card'}>{item}</li>
))}
              </ul>
              <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
                <img src="/images/icons/search.svg" alt="" className="search-icon" />
                <input 
                  type="text" 
                  placeholder="Search"
                  value={searchWord}
                  onChange={handleSearch}
                />
              </div>

              {tableShowData?.map((item, index) => (
                <li key={index} className="coin-card">
                  <button
                    className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                 onClick={() => handleSelect(item)}
                  >
                    <div className="d-flex align-items-center gap-2">
                    <img src={`/images/coins/${item?.base?.toLowerCase()}.svg`} alt="" width={24} height={24} />
                    <div className="d-flex flex-column">
                      <span>{item?.base}/{item.quote}</span>
                      <span className="text-xs text-muted">
                      { formatNumberWithComma(item.base_volume) } {selectedPair}
                      </span>
                    </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span>{ formatNumberWithComma(item.base_volume) }</span>
                      <span className={item?.change_in_price > 0 ? "text-xs text-success" : "text-xs text-danger"}>
                        { parseFloat(item.change_in_price).toFixed(2) }%
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
              </div>
              </div>

          <nav>
            <ul className="menu">
              <li className={currentPath === "/" ? "selected" : ""}>
                <Link to="/">
                <img src={(selectedTheme?.name === "light" && currentPath !== "/") ? "/images/icons/home-black.svg" : "/images/icons/home.svg"} alt="" />
                   <span>Home</span>
                </Link>
              </li>
              <li className={currentPath === "/trade" ? "selected" : ""}>
                <Link to="/trade">
                  <img src={(selectedTheme?.name === "light" && currentPath !== "/trade") ? "/images/icons/chart-bar-black.svg" : "/images/icons/chart-bar.svg"} alt="" />
                  <span>{labels?.navbar?.links?.trade}</span>
                </Link>
              </li>
              {!isInstitutional
              ? 
              <li className={currentPath === "/insta-trade" ? "selected" : ""}>
                <Link to="/insta-trade">
                <img src={(selectedTheme?.name === "light" && currentPath !== "/insta-trade") ? "/images/icons/fire-black.svg" : "/images/icons/fire.svg"} alt="" />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">New</span>
                </Link>
              </li>
              :
              <></>
              }
              <Link to="#" className="button-download mobil-show">
              <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg"} alt="" />
                Download theApp
              </Link>
            </ul>
          </nav>
          <div className="actions">
            <Link to="/login" className="button-login d-none d-lg-flex">
            {labels?.navbar?.links?.login}
            </Link>
            <Link to="/register" className="button-signUp d-none d-lg-flex">
            {labels?.navbar?.links?.signup}
            </Link>
            <Link to="#" className="button-download">
            <img src={selectedTheme?.name === "light" ? "/images/icons/download-black.svg" : "/images/icons/download.svg"} alt="" />
              Download the App
            </Link>
            <button 
              onClick={toggleSettingsModal}
              className="btn btn-transparent button-setting">
              <img src="/images/icons/settings.svg" alt="" />
            </button>
            <div className="dropdown">
            <button
                className={buttonClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={toggleDropdown} 
              >
                <img src={selectedLanguage.icon} alt=""  width={20} height={15} />
                <span className="text-capitalize">{selectedLanguage.name}</span>
              </button>


              <ul
                className={ulClassName}
                style={ulStyle}
                data-popper-placement="bottom-start"
              >
                {languages.map((language, index) => (
                  <li key={index}>
                  <a className="dropdown-item" onClick={() => { handleSelectLanguage(language); toggleDropdown(); }}>
                    {" "}
                    <img src={language.icon} alt="" />
                    <span className="text-capitalize">{language.name}</span>
                  </a>
                </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        {/* HeaderWithOutAuth End */}
      </>
    );
  }
}

export default Header;
