import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const accountCurrenciesConst = {
  AED: "AED",
  EUR: "EURO",
  GBP: "GBP",
  ISO: "ISO",
  IDR: "Indonesian Rupiah",
  PKR: "PKR",
  SAMPL: "SAMPLE BASKET",
  TRY: "TURK LIRASI",
  USD: "USD",
};

const accountTypeConst = {
  Savings: "Savings",
  Checking: "Checking",
};

const AddBankModal = ({
  showAddBank,
  handleCloseAddBank,
  title,
  handleOkBank,
  sendOtp,
  is2FaEnabled,
}) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [currency, setCurrency] = useState("0");
  const [accountType, setAccountType] = useState("Savings");
  const [bankName, setBankName] = useState("");
  const [routingCode, setRoutingCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [gAuth, setGAuth] = useState("");

  let sendOtpFunctionStatus = false;
  let handleOkBankFunctionStatus = false;

  if (
    currency != "0" &&
    accountNumber &&
    bankName &&
    routingCode &&
    swiftCode &&
    emailOtp
  ) {
    handleOkBankFunctionStatus = true;
    sendOtpFunctionStatus = true;
  } else if (
    currency != "0" &&
    accountNumber &&
    bankName &&
    swiftCode &&
    routingCode
  ) {
    sendOtpFunctionStatus = true;
  }

  const sendOtpFunction = () => {
    let returnObj = {
      AccountNumber: accountNumber,
      AccountCurrency: currency,
      accountType: accountType,
      BankName: bankName,
      type: "email",
    };
    sendOtp(returnObj);
  };

  const handleOkBankFunction = () => {
    let returnObj = {
      AccountNumber: accountNumber,
      AccountCurrency: currency,
      AccountType: accountType,
      BankName: bankName,
      emailotp: emailOtp,
      BankRoutingCode: routingCode,
      SwiftCode: swiftCode,
      gauth_code: gAuth,
      smsotp: "",
    };
    handleOkBank(returnObj);

    const delayedFunction = () => {
      setAccountNumber('');
      setCurrency('0');
      setAccountType('Savings');
      setBankName('');
      setEmailOtp('');
      setRoutingCode('');
      setSwiftCode('');
      setGAuth('');
    };

    // Set a timeout for 2 seconds and call the delayed function
    const timeoutId = setTimeout(delayedFunction, 2000);

  };

  return (
    <Modal show={showAddBank} onHide={handleCloseAddBank} className="add-bank-modal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* {message} */}

        <div
          className="row"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Account Currency:</label>
              <select
                className="form-control"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="0">Select</option>
                {Object.entries(accountCurrenciesConst).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value} ({key})
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Account Type:</label>
              <select
                className="form-control"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
              >
                {Object.entries(accountTypeConst).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value} ({key})
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Account Number:</label>
              <input
                type="text"
                className="form-control"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Bank Name:</label>
              <input
                type="text"
                className="form-control"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Bank Routing Code:</label>
              <input
                type="text"
                className="form-control"
                value={routingCode}
                onChange={(e) => setRoutingCode(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">Swift Code:</label>
              <input
                type="text"
                className="form-control"
                value={swiftCode}
                onChange={(e) => setSwiftCode(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="form-group">
            <label for="usr">Email Verification Code:</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                onChange={(e) => setEmailOtp(e.target.value)}
              />

              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={sendOtpFunction}
                  disabled={!sendOtpFunctionStatus}
                  style={{
                    borderRadius: "0 0.25rem 0.25rem 0",
                    backgroundColor: sendOtpFunctionStatus ? "" : "#dadada",
                    color: sendOtpFunctionStatus ? "" : "#222",
                  }}
                >
                  REQUEST OTP
                </button>
              </div>
            </div>
          </div>
        </div>

        {is2FaEnabled ? (
          <div className="row" style={{ paddingBottom: "15px" }}>
            <div className="col-md-12">
              <div className="form-group">
                <label for="usr">Google Auth. 2FA Code:</label>
                <input
                  type="text"
                  className="form-control"
                  value={gAuth}
                  onChange={(e) => setGAuth(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <button
                class="btn btn-primary"
                type="button"
                style={{ width: "100%" }}
                onClick={handleOkBankFunction}
              >
                Add Bank
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddBank}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBankModal;
