import { configureStore } from '@reduxjs/toolkit';

import sessionReducer from './Features/Session/SessionSlice';
import labelsReducer from './Features/Labels/LabelsSlice';
import currencySettingsReducer from './Features/CurrencySettings/CurrencySettingsSlice';
import walletReducer from './Features/Wallet/WalletSlice';
 
const store = configureStore({
  reducer: {
    session: sessionReducer,
    labels: labelsReducer,
    currencySettings: currencySettingsReducer,
    wallet:walletReducer
  },
});

export default store;
 