import React, { useState, useEffect, useRef } from "react";
 
import { Link } from "react-router-dom";
import { formatNumberWithComma } from "../../../Common/Helpers";  
import { VolumeData, IncreasingData, DecreasingData, TableData } from "../../../Common/DummyData";  
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import './Home.css';



import {TableComponent} from './Components';

import { useModalContext } from "../../../Context/ModalContext";
 
function Home() {

  const { selectedLanguage, themeNo } = useModalContext();

 
  const [isLoading, setIsLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [tableShowData, setTableShowData] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [selectedPair, setSelectedPair] = useState();
  const [primaryCoin, setPrimaryCoin] = useState([]);
  const [increasingData, setIncreasingData] = useState(IncreasingData);
  const [decreasingData, setDecreasingData] = useState(DecreasingData);
  const [volumeData, setVolumeData] = useState(VolumeData);
  const [searchWord, setSearchWord] = useState();

  const [bigSliderCounter, setBigSliderCounter] = useState(0);

  const handleItemClick = (item) => {
    setSelectedPair(item);
  }

  const ws = useRef(null);
   
  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword.toUpperCase();
        let filteredShowArray = tableShowData.filter(item => 
          item.base.includes(tmpSearchWord) || item.quote.includes(tmpSearchWord));
        setTableShowData(filteredShowArray);      
      } 
  }


  const bigSlider = () => {
    let myCounter = bigSliderCounter;
    myCounter++;
    if (myCounter>=2) myCounter=0;
    setBigSliderCounter(myCounter);
  }


const coinBox = () => {
  return (

    <div className="coin-box" style={{background: '#31C48D'}}>
    <div className="coin-name">
      
      <img src={`images/coins/${tableData[bigSliderCounter]?.base.toLowerCase()}.svg`} alt="" />
      {tableData[bigSliderCounter]?.base}/{tableData[bigSliderCounter]?.quote}
    </div>
    <div className="price">{formatNumberWithComma(tableData[bigSliderCounter]?.price.toFixed(2))}</div>
    <ul>
      <li><span>${formatNumberWithComma(tableData[bigSliderCounter]?.price.toFixed(2))}</span></li>
      <li>
        <div className="bb">
          <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} viewBox="0 0 14 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.30505 7.29514C2.17382 7.16387 2.1001 6.98586 2.1001 6.80024C2.1001 6.61463 2.17382 6.43661 2.30505 6.30534L6.50505 2.10534C6.63632 1.97411 6.81433 1.90039 6.99995 1.90039C7.18556 1.90039 7.36358 1.97411 7.49485 2.10534L11.6948 6.30534C11.8224 6.43736 11.8929 6.61418 11.8913 6.79772C11.8897 6.98126 11.8161 7.15683 11.6863 7.28661C11.5565 7.4164 11.381 7.49002 11.1974 7.49161C11.0139 7.49321 10.8371 7.42265 10.705 7.29514L7.69995 4.29004V12.4002C7.69995 12.5859 7.6262 12.7639 7.49492 12.8952C7.36365 13.0265 7.1856 13.1002 6.99995 13.1002C6.8143 13.1002 6.63625 13.0265 6.50497 12.8952C6.3737 12.7639 6.29995 12.5859 6.29995 12.4002V4.29004L3.29485 7.29514C3.16358 7.42637 2.98556 7.50009 2.79995 7.50009C2.61433 7.50009 2.43632 7.42637 2.30505 7.29514Z" fill="#03543F" />
          </svg>
          {tableData[bigSliderCounter]?.chage_in_price}
        </div>
      </li>
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" width={57} height={25} viewBox="0 0 57 25" fill="none">
          <path d="M1 24C3.41912 23.2089 5.4084 18.3846 8.29485 17.5738C9.83329 17.1416 11.0658 17.9827 12.4408 19.2898C13.8159 20.5968 13.5467 21.1612 16.6393 22.1688C19.1914 23.0003 19.859 14.0353 22.3597 14.3797C24.8605 14.7242 26.0172 3.94543 28.6574 4.84519C31.2977 5.74496 31.8253 3.41865 34.3071 7.8275C36.789 12.2364 39.082 1.95961 42.1975 1.05984C45.3131 0.160075 46.1245 9.71004 47.603 9.35013C49.0816 8.99023 50.4129 15.7952 51.6802 16.7849C52.9475 17.7747 53.3069 14.1793 56 14.1793" stroke="#046C4E" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </li>
    </ul>
    <div className="arrow" onClick={bigSlider}>
      <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 30 30" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 27C18.1826 27 21.2348 25.7357 23.4853 23.4853C25.7357 21.2348 27 18.1826 27 15C27 11.8174 25.7357 8.76516 23.4853 6.51472C21.2348 4.26428 18.1826 3 15 3C11.8174 3 8.76516 4.26428 6.51472 6.51472C4.26428 8.76516 3 11.8174 3 15C3 18.1826 4.26428 21.2348 6.51472 23.4853C8.76516 25.7357 11.8174 27 15 27ZM20.5605 13.9395L16.0605 9.4395C15.7776 9.16626 15.3987 9.01507 15.0054 9.01849C14.6121 9.02191 14.2359 9.17966 13.9578 9.45777C13.6797 9.73588 13.5219 10.1121 13.5185 10.5054C13.5151 10.8987 13.6663 11.2776 13.9395 11.5605L15.879 13.5H10.5C10.1022 13.5 9.72064 13.658 9.43934 13.9393C9.15804 14.2206 9 14.6022 9 15C9 15.3978 9.15804 15.7794 9.43934 16.0607C9.72064 16.342 10.1022 16.5 10.5 16.5H15.879L13.9395 18.4395C13.7962 18.5779 13.682 18.7434 13.6033 18.9264C13.5247 19.1094 13.4834 19.3062 13.4816 19.5054C13.4799 19.7046 13.5178 19.9021 13.5933 20.0864C13.6687 20.2708 13.7801 20.4383 13.9209 20.5791C14.0617 20.7199 14.2292 20.8313 14.4136 20.9067C14.5979 20.9822 14.7954 21.0201 14.9946 21.0184C15.1938 21.0166 15.3906 20.9753 15.5736 20.8967C15.7566 20.818 15.9221 20.7038 16.0605 20.5605L20.5605 16.0605C20.8417 15.7792 20.9997 15.3977 20.9997 15C20.9997 14.6023 20.8417 14.2208 20.5605 13.9395Z" fill="#BCF0DA" />
      </svg>
    </div>
  </div>

  );
}




  useEffect(() => {

    setIsLoading(true);
    ws.current = new WebSocket('wss://node1.ledgerbytes.tech/ws');
    ws.current.onopen = () => {
      // console.log('WebSocket connection opened.');
      ws.current.send(
        JSON.stringify({
          method: 'subscribe',
          events: ['MK']
        })
      );
    };
 
     // WebSocket üzerinden gelen mesajları dinleyin
     ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message?.data) {
        let dataArray = message?.data;
        const uniqueQuotes = [...new Set(dataArray.map(item => item.quote))];
        setPairs(uniqueQuotes)
 
        if (!selectedPair) {
          setSelectedPair(uniqueQuotes[0])
        }

        // Filter selectedPair
        let filteredArray = dataArray.filter(item => item.quote === selectedPair);
        const primaryCoin = filteredArray.filter(item => item.base === "BTC");

        filteredArray.sort((a, b) => b.price - a.price);
        setPrimaryCoin(primaryCoin)
        setTableData(filteredArray)
      
        if (searchWord) {
          let tmpSearchWord = searchWord.toUpperCase();
          let filteredShowArray = filteredArray.filter(item => 
            item.base.includes(tmpSearchWord) 
            || item.quote.includes(tmpSearchWord)
            );
          setTableShowData(filteredShowArray);
        } else {
          setTableShowData(filteredArray);
        }  
         
        filteredArray.sort((a, b) => b.base_volume - a.base_volume);
        setVolumeData(dataArray.slice(0, 3));
  
        // Sort the array by change_in_price in descending order
        filteredArray.sort((a, b) => b.change_in_price - a.change_in_price);

        // Extract the top 3 high positive values
        const topThreeHighPositive = dataArray
          .filter(item => item.change_in_price > 0)
          .slice(0, 3);

          setIncreasingData(topThreeHighPositive);
          
        // Extract the 3 lowest values (negative or zero)
        const threeLowest = dataArray.slice(-3);
        setDecreasingData(threeLowest);
 
      }
    };

    // WebSocket bağlantısı kapandığında
    ws.current.onclose = () => {
      // console.log('WebSocket connection closed.');
    };
    setIsLoading(false);
        // Komponentin sonlandırılması durumunda WebSocket bağlantısını kapatın
        return () => {
          if (ws.current) {
            ws.current.close();
          }
        };

        // Go once initially
  
  }, [selectedPair, searchWord]);
   
  return (
    <>
     <Header />
     <div>
        {/* Marquee */}
        <section className="marquee">
          <div className="container">
            <div className="swiper-marquee">
              <ul>  
              <li>&nbsp;</li>
              {tableData?.filter((item) => item.change_in_price !== 0).map((item) => (
                <li key={item.id}>
                  <a href="#">
                    {item.base}/{item.quote}
                    <span className={item.change_in_price > 0 ? 'up' : 'down'}>
                      {item.change_in_price.toFixed(2)}%
                    </span>
                  </a>
                </li>
              ))}

              {/* {tableData?.map((item) => (  
                <li><a href="#">{item.base}/{item.quote}<span className={item?.change_in_price > 0 ? 'up' : 'down'}>{item?.change_in_price}%</span></a></li>
              ))}  */}
              </ul>
 
            </div>
          </div>
        </section>
        {/* Marquee End */}
        {/* Slider main container */}
        <section className="swiper">
          
 {/* ... */}

 <Swiper
      spaceBetween={50}
      slidesPerView={1.2}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
            <div className="swiper-slide container">
              <div className="row">
                <div className="col-lg-7">
                  <img className="bg" src="images/materials/slider01.jpg" alt="" />
                </div>
                <div className="col-lg-5">
                  <div className="text">
                    <span>Announcement</span>
                    <h2>Shib Listed!</h2>
                    <p>Shib Listed! Now you can buy or sell <br />
                      from out system!</p>
                      {themeNo === 1 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-blue.svg" alt="" /></a>
                      )}
                      {themeNo === 2 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-yellow.svg" alt="" /></a>
                      )}
                      {themeNo === 3 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-green.svg" alt="" /></a>
                      )}

                  </div>
                </div>
              </div>
            </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="swiper-slide container">
              <div className="row">
                <div className="col-lg-7">
                  <img className="bg" src="images/materials/slider01.jpg" alt="" />
                </div>
                <div className="col-lg-5">
                  <div className="text">
                    <span>Announcement</span>
                    <h2>Avax Listed!</h2>
                    <p>Shib Listed! Now you can buy or sell <br />
                      from out system!</p>
                      {themeNo === 1 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-blue.svg" alt="" /></a>
                      )}
                      {themeNo === 2 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-yellow.svg" alt="" /></a>
                      )}
                      {themeNo === 3 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-green.svg" alt="" /></a>
                      )}
                  </div>
                </div>
              </div>
            </div>
            </SwiperSlide>
            <SwiperSlide>
      <div className="swiper-slide container">
              <div className="row">
                <div className="col-lg-7">
                  <img className="bg" src="images/materials/slider01.jpg" alt="" />
                </div>
                <div className="col-lg-5">
                  <div className="text">
                    <span>Announcement</span>
                    <h2>Doge Listed!</h2>
                    <p>Shib Listed! Now you can buy or sell <br />
                      from out system!</p>
                      {themeNo === 1 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-blue.svg" alt="" /></a>
                      )}
                      {themeNo === 2 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-yellow.svg" alt="" /></a>
                      )}
                      {themeNo === 3 && (
                        <a href="#" className="button">Details <img src="images/icons/arrow-right-green.svg" alt="" /></a>
                      )}
                  </div>
                </div>
              </div>
            </div>
            </SwiperSlide>
 
    </Swiper>
  
        </section>
        {/* Slider main container End */}
        {/* Frame Container */}
        <section className="frame-container container">
          <div className="row row-reverse">
            <div className="col-lg-8">
              <ul className="tabList">
                <li className="fav"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                    <path d="M7.23928 2.34166C7.47928 1.60486 8.52168 1.60486 8.76088 2.34166L9.61688 4.97526C9.66917 5.13565 9.77084 5.27541 9.90733 5.37455C10.0438 5.47369 10.2082 5.52714 10.3769 5.52726H13.1465C13.9217 5.52726 14.2433 6.51926 13.6169 6.97526L11.3769 8.60246C11.2401 8.70169 11.1383 8.84167 11.0859 9.00234C11.0336 9.16302 11.0335 9.33613 11.0857 9.49686L11.9417 12.1305C12.1817 12.8673 11.3377 13.4809 10.7097 13.0249L8.46968 11.3977C8.33304 11.2985 8.16852 11.245 7.99968 11.245C7.83083 11.245 7.66631 11.2985 7.52968 11.3977L5.28968 13.0249C4.66248 13.4809 3.81928 12.8673 4.05848 12.1305L4.91448 9.49686C4.96662 9.33613 4.96652 9.16302 4.91421 9.00234C4.8619 8.84167 4.76005 8.70169 4.62328 8.60246L2.38408 6.97606C1.75768 6.52006 2.08008 5.52806 2.85448 5.52806H5.62328C5.79211 5.52811 5.95664 5.47473 6.0933 5.37558C6.22995 5.27643 6.33174 5.13658 6.38408 4.97606L7.24008 2.34246L7.23928 2.34166Z" fill="#FACA15" />
                  </svg>
                  Favorites
                </li>
                {/* <li onClick={() => handleItemClick('USDT')} className={selectedPair === 'USDT' ? 'selected' : ''}>USDT</li>
                <li onClick={() => handleItemClick('BTC')} className={selectedPair === 'BTC' ? 'selected' : ''}>BTC</li>
                <li onClick={() => handleItemClick('TRY')} className={selectedPair === 'TRY' ? 'selected' : ''}>TRY</li>
                <li onClick={() => handleItemClick('EUR')} className={selectedPair === 'EUR' ? 'selected' : ''}>EUR</li> */}

{pairs?.map((item) => ( 
       
              <li key={item} onClick={() => handleItemClick(item)} className={selectedPair === item ? 'selected' : ''}>{item}</li>
 ))}

              </ul>
              <div className="row">
                <div className="col-xl-4 col-lg-12">
                  <div className="min-table">
                    <table>
                      <tbody> 
                        <tr key="Increasing">
                          <th>Increasing</th>
                          <th />
                          <th />
                        </tr> 
 
                        <tr key="Increasing0">
                          <td>
                            <div className="pair">
                              <img src={`/images/coins/${increasingData[0]?.base?.toLowerCase()}.svg`} alt="" />
                              {increasingData[0]?.base}/{increasingData[0]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            { formatNumberWithComma(increasingData[0]?.price) }
                            </div>
                          </td>
                          <td>
                            <div className={increasingData[0]?.change_in_price > 0 ? 'up' : 'down'}>{increasingData[0]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="Increasing1">
                          <td>
                            <div className="pair">
                              <img src={`images/coins/${increasingData[1]?.base?.toLowerCase()}.svg`} alt="" />
                              {increasingData[1]?.base}/{increasingData[1]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            { formatNumberWithComma(increasingData[1]?.price) }
                            </div>
                          </td>
                          <td>
                            <div className={increasingData[1]?.change_in_price > 0 ? 'up' : 'down'}>{increasingData[1]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="Increasing2">
                          <td>
                            <div className="pair">
                             <img src={`images/coins/${increasingData[2]?.base?.toLowerCase()}.svg`} alt="" />
                              { increasingData[2]?.base}/{increasingData[2]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(increasingData[2]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={increasingData[2]?.change_in_price > 0 ? 'up' : 'down'}>{increasingData[2]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                      </tbody></table>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-12">
                  <div className="min-table">
                    <table>
                      <tbody>
                      <tr key="Decreasing">
                          <th>Decreasing</th>
                          <th />
                          <th />
                        </tr>
                        <tr key="Decreasing0">
                          <td>
                            <div className="pair">
                              <img src={`images/coins/${decreasingData[0]?.base?.toLowerCase()}.svg`} alt="" />
                              {decreasingData[0]?.base}/{decreasingData[0]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(decreasingData[0]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={decreasingData[0]?.change_in_price > 0 ? 'up' : 'down'}>{decreasingData[0]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="Decreasing1">
                          <td>
                            <div className="pair">
                            <img src={`images/coins/${decreasingData[1]?.base?.toLowerCase()}.svg`} alt="" />
                              {decreasingData[1]?.base}/{decreasingData[1]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(decreasingData[1]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={decreasingData[1]?.change_in_price > 0 ? 'up' : 'down'}>{decreasingData[1]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="Decreasing2">
                          <td>
                            <div className="pair">
                            <img src={`images/coins/${decreasingData[2]?.base?.toLowerCase()}.svg`} alt="" />
                              {decreasingData[2]?.base}/{decreasingData[2]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(decreasingData[2]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={decreasingData[2]?.change_in_price > 0 ? 'up' : 'down'}>{decreasingData[2]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>
                      </tbody></table>

                  </div>
                </div>

                <div className="col-xl-4 col-lg-12">
                  <div className="min-table">
                    <table>
                      <tbody>
                      <tr key="VolumeData">
                          <th>Highest Volume</th>
                          <th />
                          <th />
                        </tr>
                        <tr key="VolumeData0">
                          <td>
                            <div className="pair">
                              <img src={`images/coins/${volumeData[0]?.base?.toLowerCase()}.svg`} alt="" />
                              {volumeData[0]?.base}/{volumeData[0]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(volumeData[0]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={volumeData[0]?.change_in_price > 0 ? 'up' : 'down'}>{volumeData[0]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="VolumeData1">
                          <td>
                            <div className="pair">
                            <img src={`images/coins/${volumeData[1]?.base?.toLowerCase()}.svg`} alt="" />
                              {volumeData[1]?.base}/{volumeData[1]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(volumeData[1]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={volumeData[1]?.change_in_price > 0 ? 'up' : 'down'}>{volumeData[1]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>

                        <tr key="VolumeData2">
                          <td>
                            <div className="pair">
                            <img src={`images/coins/${volumeData[2]?.base?.toLowerCase()}.svg`} alt="" />
                              {volumeData[2]?.base}/{volumeData[2]?.quote}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                            {formatNumberWithComma(volumeData[2]?.price)}
                            </div>
                          </td>
                          <td>
                            <div className={volumeData[2]?.change_in_price > 0 ? 'up' : 'down'}>{volumeData[2]?.change_in_price.toFixed(2)}</div>
                          </td>
                        </tr>
                      </tbody></table>

                  </div>
                </div>

              </div>
              <form className="search">
                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                  <path d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="#9CA3AF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg> 

                <input 
                  type="text" 
                  placeholder="Search"
                  value={searchWord}
                  onChange={handleSearch}
                />

                <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} viewBox="0 0 13 14" fill="none">
                  <path d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583" fill="#111928" stroke="#9CA3AF" />
                </svg>
              </form>
            </div>
            <div className="col-lg-4">

  {coinBox()}

            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
              <tr key="CoinsDataAll">
                  <th />
                  <th>PAIR</th>
                  <th>PRICE</th>
                  <th>CHANHE (24HR)</th>
                  <th>HIGH (24HR)</th>
                  <th>LOW (24HR)</th>
                  <th>VOLUME (24HR)</th>
                </tr>
              </thead>
              <tbody>
    
              {
              tableShowData.length>0 ?  

              tableShowData?.map((item) => ( 
                
                <tr key={item.base}> 
                  <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                      <path d="M11.3587 3.51249C11.7187 2.40729 13.2823 2.40729 13.6411 3.51249L14.9251 7.46289C15.0035 7.70348 15.156 7.91311 15.3608 8.06182C15.5655 8.21053 15.812 8.29071 16.0651 8.29089H20.2195C21.3823 8.29089 21.8647 9.77889 20.9251 10.4629L17.5651 12.9037C17.3599 13.0525 17.2071 13.2625 17.1287 13.5035C17.0502 13.7445 17.0501 14.0042 17.1283 14.2453L18.4123 18.1957C18.7723 19.3009 17.5063 20.2213 16.5643 19.5373L13.2043 17.0965C12.9993 16.9477 12.7525 16.8676 12.4993 16.8676C12.246 16.8676 11.9992 16.9477 11.7943 17.0965L8.43427 19.5373C7.49347 20.2213 6.22867 19.3009 6.58747 18.1957L7.87147 14.2453C7.94968 14.0042 7.94954 13.7445 7.87107 13.5035C7.7926 13.2625 7.63983 13.0525 7.43467 12.9037L4.07587 10.4641C3.13627 9.78009 3.61987 8.29209 4.78147 8.29209H8.93467C9.18793 8.29216 9.43471 8.2121 9.6397 8.06337C9.84469 7.91464 9.99736 7.70487 10.0759 7.46409L11.3599 3.51369L11.3587 3.51249Z" fill="#374151" />
                    </svg>
                  </td>
                  <td>
                    <div className="pair"> 
                      <img src={`images/coins/${item?.base?.toLowerCase()}.svg`} alt="" />
                      {item?.base}/{item.quote}
                    </div>
                  </td>
                  <td>
                    <div className="price">
                       {formatNumberWithComma(item.price)}
                      <span>${item.price}</span>
                    </div>
                  </td>
                  <td>
                    <div className={item?.change_in_price > 0 ? 'up' : 'down'}>{ parseFloat(item.change_in_price).toFixed(2) }%</div>
                  </td>
                  <td>
                    <div className="price">
                    { formatNumberWithComma(item.high_24hr) }
                      <span>${ formatNumberWithComma(item.high_24hr) }</span>
                    </div>
                  </td>
                  <td>
                    <div className="price">
                    { formatNumberWithComma(item.low_24hr) }
                      <span>${ formatNumberWithComma(item.low_24hr) }</span>
                    </div>
                  </td>
                  <td>
                    <div className="price">
                    { formatNumberWithComma(item.base_volume) } <span>USDT</span>
                    </div>
                  </td>
                </tr> 
                
                ))
              :
<>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>Loading...</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>No Data...</span>   
                      </td>
                  </tr>
                  }
                  </>
              }
                
              </tbody>
              
            </table>
          </div>
          <ul className="pagination">
            {/* ... */}
          </ul>
        </section>
        {/* Frame Container End */}
      </div>
<Footer />
    </>
  );
}

export default Home;
