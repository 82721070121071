import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react"; 
import './Market.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';
 
function Market() {
 
  useEffect(() => {
    
  }, []);
 
  return (
    <>
    <Header />
    <PriceBar /> 

<div className="row">
  <div className="col-md-12 centered-items">
    <h1 style={{color:'#aaa', paddingTop:55}}>Market</h1>
    </div>
    <div className="col-md-12 centered-items">
    <h4 style={{color:'#aaa', paddingTop:55}}>Under Construction!</h4>
    </div>
</div>
<Footer />
    </>
  );

}

export default Market;