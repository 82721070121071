import React, { useState, useEffect } from "react";
import { TradeDropdown } from "./TradeDropdown";
import { formatNumberWithComma } from "../../../Common/Helpers";  

export const TradeHeader = ({ setActiveMarket, coinsData, setCoinsData, coin, newCoin, setNewCoin, SETMARKET, increasingData, handleSearch, searchWord, tableShowData, pairs, selectedPair, handleItemClick, primaryCoin, dropdownVisible, setDropdownVisible }) => {

  useEffect(() => {}, []);


  return (
    <>
      <div className="row d-lg-block d-none">
        <div className="col-md-12 py-2 border-bottom border-top custom-border-color border-secondary">
          <div className="d-flex items-center justify-start small">
            <TradeDropdown 
              setActiveMarket={setActiveMarket}
              coinsData={coinsData}
              setCoinsData={setCoinsData}

              coin={coin}
            //  setCoin={setCoin}
              newCoin={newCoin}
              setNewCoin={setNewCoin}
              SETMARKET={SETMARKET}
              primaryCoin={primaryCoin}
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}

              pairs={pairs}
              selectedPair={selectedPair}
              handleItemClick={handleItemClick}
              tableShowData={tableShowData}
              handleSearch={handleSearch}
              searchWord={searchWord}
              increasingData={increasingData}
            />
            <div className="px-4 d-flex align-items-center justify-content-center">


            {coin ? (
              <span className="text-danger fw-bold">{formatNumberWithComma(coin.price)}</span>
            ) : (
                <span className="text-danger fw-bold">{formatNumberWithComma(primaryCoin?.price)}</span>
            )}
            
              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">Coin Info</span>

              {coin ? (
                <span className="text-muted">{coin?.base}</span>
              ) : (
                <span className="text-muted">{primaryCoin?.base}</span>
              )}
              

              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">24h Change</span>

              {coin ? (
                <span className={coin?.change_in_price > 0 ? "text-success" : "text-danger"}>{ parseFloat(coin?.change_in_price).toFixed(2) } %</span>
              ) : (
                <span className={primaryCoin?.change_in_price > 0 ? "text-success" : "text-danger"}>{ parseFloat(primaryCoin?.change_in_price).toFixed(2) } %</span>
              )}

            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">24h High</span>
              {coin ? (
                <span className="text-muted">{formatNumberWithComma(coin?.high_24hr) }</span>
              ) : (
                <span className="text-muted">{formatNumberWithComma(primaryCoin?.high_24hr) }</span>
              )}
              
              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">24h Low</span>
              {coin ? (
                <span className="text-muted">{formatNumberWithComma(coin?.low_24hr) }</span>
              ) : (
                <span className="text-muted">{formatNumberWithComma(primaryCoin?.low_24hr) }</span>
              )}
              
              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">24h Volume</span>
              {coin ? (
                <span className="text-muted"> <span className="text-muted">{formatNumberWithComma(coin?.base_volume) }</span></span>
              ) : (
                <span className="text-muted"> <span className="text-muted">{formatNumberWithComma(primaryCoin?.base_volume) }</span></span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
