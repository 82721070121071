import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
 
const KeyModal = ({ showModal2, handleCloseModal2, privateKey, publicKey }) => {
 
  return (
    <Modal show={showModal2} onHide={handleCloseModal2}>
      <Modal.Header closeButton>
        <Modal.Title style={{color:'#ddd'}}>API DETAILS</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{color:'#ddd'}}>
 
      <div className='row' style={{paddingBottom:'15px', paddingTop:'15px'}}>
        <div className='col-md-10 mx-auto'>
            <div className="form-group">
                
                Save the private key somewhere safe, you will only see it once!
                <br /><br />

                <b>Private KEY : </b> {privateKey}
                <br /><br />
                <b>Public KEY : </b> {publicKey}

            </div>
        </div>
      </div>
 
      </Modal.Body>
      <Modal.Footer> 
        <Button variant="secondary" onClick={handleCloseModal2}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeyModal;