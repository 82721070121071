import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Security.css";
import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import axios from "axios";

function Security() {
  const [data, setData] = useState([]);
  const [authCode, setAuthCode] = useState();
   
  const [is2FaEnabled, setIs2FaEnabled] = useState(false);

  const token = localStorage.getItem("token");
 
  const twoFactorAuthChangeEnable = async () => {
  
    if (!authCode) {
      alert("Please input your 6 digit authenticator code");
      return false;
    }

    let reqBody = {
      GAuth_Code: authCode
    }

      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/GAuth_Set_Enable`, 
        reqBody,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
        }
      ).then((response) => {

       if (response?.data?.status=="Error") {
        alert(response?.data?.data);
       } else if (response?.data?.status=="Success") {
        check2Fa();
        alert(response?.data?.data);
       } else {
        // ...
       }
        
      }).catch(error => {
        console.error('Post Error:', error);
        alert("General Error");
      });

       

 }

 const twoFactorAuthChangeDisable = async () => {
 
      if (!authCode) {
        alert("Please input your 6 digit authenticator code");
        return false;
      }

      let reqBody = {
        GAuth_Code: authCode
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/GAuth_Disable_Request`, 
        reqBody,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
        }
      ).then((response) => {

        if (response?.data?.status=="Error") {
         alert(response?.data?.data);
        } else if (response?.data?.status=="Success") {
         check2Fa();
         alert(response?.data?.data);
        } else {
         // ...
        }
      }).catch(error => {
        console.error('Post Error:', error);
        alert("General Error");
      });
 
}
 
  const check2Fa = async () => {
 
    await axios
    .get(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setIs2FaEnabled(response?.data?.data?.is2FAEnabled); 
    })
    .catch((error) => {
      console.error("GET isteği hatası:", error);
    });
 
    // Axios GET Req.
    await axios
    .get(`${process.env.REACT_APP_API_REST_URL}/api/GAuth_Enable_Request`, {
      headers: {
        Authorization: `Bearer ${token}`, // Bearer token gönderimi
      },
    })
    .then((response2) => {
      console.log(response2);
      setData(response2?.data?.data);
    })
    .catch((error) => {
      console.error("GET isteği hatası:", error);
    });
  }


  useEffect(() => {
    check2Fa();
  }, [is2FaEnabled]);

  return (
    <>
      <Header />

      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Account" submenu="AccountSecurity" />
        <div className="main-content">
          <div className="main-content">
            <h4 className="title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.166 4.99885C5.06114 4.96285 7.84481 3.87731 10 1.94385C12.155 3.87767 14.9387 4.96357 17.834 4.99985C17.944 5.64985 18 6.31985 18 7.00085C18 12.2258 14.66 16.6708 10 18.3178C5.34 16.6698 2 12.2248 2 6.99985C2 6.31785 2.057 5.64985 2.166 4.99885ZM13.707 8.70685C13.8892 8.51825 13.99 8.26564 13.9877 8.00345C13.9854 7.74125 13.8802 7.49044 13.6948 7.30503C13.5094 7.11962 13.2586 7.01445 12.9964 7.01217C12.7342 7.0099 12.4816 7.11069 12.293 7.29285L9 10.5858L7.707 9.29285C7.5184 9.11069 7.2658 9.0099 7.0036 9.01217C6.7414 9.01445 6.49059 9.11962 6.30518 9.30503C6.11977 9.49044 6.0146 9.74125 6.01233 10.0034C6.01005 10.2656 6.11084 10.5182 6.293 10.7068L8.293 12.7068C8.48053 12.8943 8.73484 12.9996 9 12.9996C9.26516 12.9996 9.51947 12.8943 9.707 12.7068L13.707 8.70685Z"
                  fill="white"
                />
              </svg>
              Security
            </h4>
            <div className="box-item">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <div className="text">
                    <h4>
                      Two-Factor Authentication{" "}
                      {is2FaEnabled 
                      ?
                      <span className="btn-danger">Enabled</span>
                      :
                      <span className="btn-danger">Disabled</span>
                      }
                      
                    </h4>
                    <p className="tt">
                      LedgerBytes encourages the use of two-factor
                      authentication
                    </p>
                    <p>
                      Two-factor authentication (2fa) greatly increases security
                      by requiring both your password and another form of
                      authentication. LedgerBytes implements 2fa utilizing
                      Google Authenticator. To enable this feature simply
                      download <a href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&oco=0" target="_blank">Google Authenticator</a> on your
                      mobile device and scan the QRCode. Once you have linked
                      the Authenticator with LedgerBytes, enter the 6 digit code
                      provided.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <img className="w-100" src="/images/icons/mode.svg" alt="" />
                </div>
              </div>
            </div>
            {is2FaEnabled  
            ? 
            <div className="box-item">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="google-authenticator">
                      
                      <img src={data?.qR_Code} className="qr" alt="" />
                      
                      <h4>Disable Google Authenticator</h4>
                       
                      <p>Input your 6 digit authenticator code</p>
                  
                      <input 
                      type="text" 
                      style={{    
                        borderRadius: "8px",
                        border: "1px solid var(--gray-600, #4B5563)",
                        background: "var(--gray-700, #374151)",
                        padding: "14px 16px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                        color: "#fff",
                        fontSize:'20pt'
                      }}
                      onChange={e=>setAuthCode(e.target.value)} />
              
                      <button className="button" onClick={()=>twoFactorAuthChangeDisable()}>Disable 2FA</button>
                  </div>
                </div>
              </div>
            </div>
            :
              <div className="box-item">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="google-authenticator">
                      
                      <img src={data?.qR_Code} className="qr" alt="" />

                      <h4>Enable Google Authenticator</h4>
                      <p>Secret Key*</p>
                      <p>paccount.security.secretKeyHelp</p>
                      <div className="copy">
                        <input type="text" defaultValue={data?.pairingCode} />
                        <button>Copy</button>
                      </div>
                      <p>Input your 6 digit authenticator code</p>
                  
                      <input 
                      type="text" 
                      style={{    
                        borderRadius: "8px",
                        border: "1px solid var(--gray-600, #4B5563)",
                        background: "var(--gray-700, #374151)",
                        padding: "14px 16px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                        color: "#fff",
                        fontSize:'20pt'
                      }}
                      onChange={e=>setAuthCode(e.target.value)} />
              
                      <button className="button" onClick={()=>twoFactorAuthChangeEnable()}>Enable 2FA</button>
                  </div>
                </div>
              </div>
            </div>

            }
            
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Security;
