import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import './Register.css';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer/';
import CustomModal from '../../../Components/CustomModal/';
import { isValidEmail, validateString } from "../../../Common/Helpers";

import { COUNTRY_CODES_WITH_FLAG } from '../../../Common/CountryData';

function Register() {
 
  const navigate = useNavigate();
  // Check if a token exists in local storage
  const token = localStorage.getItem('token');

  // If token exists, redirect to the main page
  React.useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  const [firstname, setFirstname] = useState(); 
  const [lastname, setLastname] = useState();
  const [middlename, setMiddlename] = useState(); 
  const [email, setEmail] = useState(); 
  const [selectedCountry, setSelectedCountry] = useState('');
  const [password, setPassword] = useState(); 
  const [confirmPassword, setConfirmPassword] = useState(); 
  const [message, setMessage] = useState(); 
  const [countryCode, setCountryCode] = useState([]);

  const[isRegisterFormVisible, setIsRegisterFormVisible] = useState(true);  
   
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    console.log(isChecked)
  };
  
  const handleFirstnameChange = (e) => {
    const value = e.target.value; 
    let result = value.replace(/[^a-z]/gi, ''); 

    // Enforce max length of 20 characters
    if (result.length > 35) {
    result = result.slice(0, 35);
    }

    setFirstname(result); 
  };

  const handleLastnameChange = (e) => {
    const value = e.target.value; 
    let result = value.replace(/[^a-z]/gi, ''); 

    // Enforce max length of 20 characters
    if (result.length > 35) {
      result = result.slice(0, 35);
    }
 
    setLastname(result); 
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const checkRegister = async () => {
 
    if (!firstname) {
      setMessage('Firstname missing')
      handleShow();
 
    } else if (!lastname) {
      setMessage('Lastname missing')
      handleShow();

    } else if (!email) {
      setMessage('Email missing')
      handleShow();

    } else if (!isValidEmail(email)) {
      setMessage('Wrong email format')
      handleShow();

    } else if (selectedCountry.length===0) {
      setMessage('Select Country')
      handleShow();

    } else if (!password) {
      setMessage('Password missing')
      handleShow();

    // } else if (validateString(password, 4, 99, 'alphanumeric')) {
    //   setMessage('Wrong Password format')
    //   handleShow();

    } else if (!confirmPassword) {
      setMessage('Confirm Password missing')
      handleShow();
 
    } else if (password!=confirmPassword) {
      setMessage('Passwords didn\'t match')
      handleShow();

    } else if (!isChecked) {
      setMessage('Please check the checkbox')
      handleShow();
      
    } else {
 
    const response = await axios.post (
      process.env.REACT_APP_API_REST_URL+'/api/SignUp', {
        "country": selectedCountry,
        "email":email,
        "firstname":firstname,
        "lastname":lastname,
        "middlename":middlename,
        "password":password,
        "referralId": ""
      }
    );

      console.log("register answer:" + JSON.stringify(response) );

    
    if (response?.data?.status=="Success") {
       
      setIsRegisterFormVisible(false);

    } else {

      if (response?.data?.status && response?.data?.status=="Error") {
        setMessage(response?.data?.message)
      } else {
        setMessage('Error Occured')
      } 
      handleShow();
    }

    }
   
}
 
useEffect(() => {
 
  let myArray = [];
  COUNTRY_CODES_WITH_FLAG.map((item) => {
    const label = `(${item.dial_code}) ${item.name}`;
    myArray.push({ label: label, value: item.dial_code });
  });
  setCountryCode(myArray);
}, []);

  return (
<>
<Header />

<CustomModal 
        show={show} 
        handleClose={handleClose} 
        title="Register" 
        message={message}
      />

 {/* Register */}
 <section className="login">
        <div className="container">
          <div className="row row-reset justify-content-center">
            <div className="col-lg-4">
              <div className="picture">
                <img className="bg" src="images/materials/energy.jpg" alt="" />
                <img className="logo" src="images/logo.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
            {isRegisterFormVisible  && (!localStorage.getItem("token")) ? ( 
              <form className="sign-up">
                <h3>Create your Free Account</h3>
                <div className="input-container">
                  <label htmlFor="first-name">First Name</label>
                  {/* <input type="text" placeholder="First Name" onChange={e=>setFirstname(e.target.value)} /> */}
                  <input type="text" placeholder="First Name" value={firstname} onChange={handleFirstnameChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="first-name">Last Name</label>
                  {/* <input type="text" placeholder="Last Name" onChange={e=>setLastname(e.target.value)} /> */}
                  <input type="text" placeholder="Last Name" value={lastname} onChange={handleLastnameChange} /> 
                </div>
                <div className="input-container">
                  <label htmlFor="your-email">Your Email</label>
                  <input type="text" placeholder="name@example.com" onChange={e=>setEmail(e.target.value)} />
                </div>
                <div className="input-container">
                  <label htmlFor="your-email">Select Country</label>

                  {/* <span style={{marginTop:'15!important'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                    <path d="M4.89851 3.99845L4.89853 3.99844C5.9863 2.91066 7.46164 2.29956 8.99998 2.29956C10.5383 2.29956 12.0137 2.91066 13.1014 3.99844C14.1892 5.08621 14.8003 6.56154 14.8003 8.09988C14.8003 9.63822 14.1892 11.1136 13.1014 12.2013L8.99998 16.3028L4.89853 12.2013L4.89851 12.2013C4.35987 11.6627 3.93259 11.0233 3.64108 10.3196C3.34956 9.61585 3.19952 8.8616 3.19952 8.09988C3.19952 7.33816 3.34956 6.58391 3.64108 5.88018C3.93259 5.17645 4.35987 4.53704 4.89851 3.99845ZM8.99998 10.3999C9.60998 10.3999 10.195 10.1576 10.6263 9.72623C11.0577 9.29489 11.3 8.70988 11.3 8.09988C11.3 7.48988 11.0577 6.90487 10.6263 6.47354C10.195 6.0422 9.60998 5.79988 8.99998 5.79988C8.38998 5.79988 7.80497 6.0422 7.37363 6.47354C6.9423 6.90487 6.69998 7.48988 6.69998 8.09988C6.69998 8.70988 6.9423 9.29489 7.37363 9.72623C7.80497 10.1576 8.38998 10.3999 8.99998 10.3999Z" fill="#9CA3AF" stroke="#9CA3AF" />
                  </svg>
                  </span>  */}
  
                    <select
                      name="country"
                      id="country"
                      style={{ padding: "12px 18px 12px" }}
                      onChange={handleCountryChange}
                      value={selectedCountry}
                    >

                    <option value="">Your Country</option> 
                      {countryCode.map((country, index) => (
                      <option key={index} value={country.value}>
                       {country.label} 
                      </option>
                      ))} 
                  </select>
                </div>
                <div className="input-container">
                  <label htmlFor="password">Password</label>
                  <input type="password" placeholder="••••••••••" onChange={e=>setPassword(e.target.value)} />
                </div>
                <div className="input-container">
                  <label htmlFor="password-confirm">Confirm Password</label>
                  <input type="password" placeholder="••••••••••" onChange={e=>setConfirmPassword(e.target.value)} />
                </div>
                <div className="input-container">
                  <label htmlFor="code">Referral Code</label>
                  <input type="password" placeholder="••••••••••" />
                </div>
                <div className="checkbox">
            
                <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange}/>
                  <label htmlFor="check">
                    <p>I accept all  <a href="#">Policies and Terms and Conditions</a> set by LedgerBytes</p>
                  </label>
                </div>
                <input type="button" value="Create Account" className="button-style-01" onClick={()=>checkRegister()} /> 
                <p className="login-link">Already have an account? <a href="/login">Login here</a></p>
              </form>
            ) :   
            <>
            
                { localStorage.getItem('token') ? 
                  <>
                  <h5 style={{color:'#ddd', paddingTop:200}} align='center'>You are already logged in!</h5>
                  </> 
                  : 
                  <>

                    <div className="row">
                    <div className="col-md-12 mx-auto" style={{paddingTop:100, color:'#ccc'}}>
                    <h3 align='center'>Registration Successfull!</h3>
                    <br /><br />
                    <h6 align='center'>Please check your email for confirmation.</h6> 
                    </div>
                    </div>

                  </> 
                }


            </>
            }
            </div>
          </div>
        </div>
      </section>
      {/* Register End */}

<Footer />
</>
  );
}

export default Register;