import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../SettingsModal/SettingsModal.css";

export default function Dropdown({ title, options, selectedOption, onSelectOption, dropdownVisible, onToggleDropdown  }) {

  const handleSelectOption = (option) => {
    onSelectOption(option);
    onToggleDropdown();
  };



  return (
    <div className="mb-4">
    <h5 className="dropdown-title fs-6">{title}</h5>
              <div className={dropdownVisible ? "buyorder-dropdown position-relative active-dropdown" : "buyorder-dropdown position-relative"}>
                <button
                  type="button"
                  className="text-start text-capitalize d-flex justify-content-between align-items-center px-2"
                  onClick={onToggleDropdown}
                >
                  <div className="d-flex align-items-center">
                  {selectedOption.icon && (
            <img
              src={selectedOption.icon}
              width={20}
              height={15}
              className="me-2"
              alt=""
            />
          )}
                    <span className="text-capitalize">{selectedOption.name}</span>
                  </div>
                  <img
                    src="/images/icons/chevron-down.svg"
                    alt=""
                    className="ps-2"
                  />
                </button>
                <ul
                  className={
                    dropdownVisible
                      ? "buyorder-options position-absolute show"
                      : "buyorder-options position-absolute"
                  }
                >
                  {options.map((option, index) => (
                    <li key={index}>
                      <button
                        className="text-start text-capitalize d-flex justify-content-between align-items-center px-2"
                        type="button"
                        onClick={() => handleSelectOption(option)}
                        >
                        <div className="d-flex align-items-center">
                        {option.icon && (
                  <img
                    src={option.icon}
                    width={20}
                    height={15}
                    className="me-2"
                    alt=""
                  />
                )}
                          <span className="text-capitalize">{option.name}</span>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
    </div>
  );
}
