import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
import CustomModal from "../../../Components/CustomModal/";
import { isValidEmail, validateString } from "../../../Common/Helpers";
import { useDispatch } from "react-redux";
import { login, logout } from "../../../Features/Session/SessionSlice";
import "./Login.css";

const initialTime = 1 * 60;

function Login() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    console.log(isChecked);
  };

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [tempAuthToken, setTempAuthToken] = useState();
  const [smsCode, setSmsCode] = useState();
  const [refreshCondition, setRefreshCondition] = useState(0);
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
  const [isLoginVerificationFormVisible, setIsLoginVerificationFormVisible] =
    useState(false);
  const [verificationType, setVerificationType] = useState();
  const [timeLeft, setTimeLeft] = useState(initialTime);
 
  const handleInputSmsChange = (e) => {
    // Accept only numeric characters
    const numericInput = e.target.value.replace(/\D/g, "");
    setSmsCode(numericInput);
  };

  useEffect(() => {
    if (refreshCondition == 1) {
      // If the condition is met, reload the page
      window.location.reload();
    }
  }, [refreshCondition]); // Dependency array to watch for changes in 'someCondition'

  useEffect(() => {
    if (isLoginVerificationFormVisible) {
      // Exit early when we reach 0
      if (timeLeft === 0) {
        setRefreshCondition(1);
      } else {
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
      }
    }
  }, [timeLeft, isLoginVerificationFormVisible]);

  // Format time left into minutes:seconds
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    // Pad seconds with a zero if it's less than 10
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const checkLoginVerification = async () => {
    const requestBody = {
      grant_type: "password",
      username: tempAuthToken,
      password: smsCode,
      dvc_otp: smsCode,
      dvc_id: "dummy",
    };

    var formBody = [];
    for (var property in requestBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(requestBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/token`,
        formBody
      );

      if (response?.data?.access_token) {
        console.log("access token " + response?.data?.access_token);
        localStorage.setItem("token", response?.data?.access_token);

        const response2 = await axios.get(
          `${process.env.REACT_APP_API_REST_URL}/api/GetProfile`,
          {
            headers: {
              Authorization: `Bearer ${response?.data?.access_token}`,
            },
          }
        );

        localStorage.setItem("userInfo", JSON.stringify(response2.data.data));

        // dispatch(login(response?.data?.access_token));
        dispatch(
          login({
            userToken: response.data.access_token,
            userProfile: JSON.stringify(response2.data.data),
          })
        );

        navigate("/home", { replace: true });
      } else {
        alert("Wrong Verification Number");
        console.log("o token");
      }
    } catch (error) {
      alert("Wrong Verification Number");
      console.log("Error" + error);
    }
  };

  const checkLogin = async () => {
    if (!username) {
      setMessage("Email missing");
      handleShow();
    } else if (!password) {
      setMessage("Password missing");
      handleShow();
    } else if (
      !(
        isValidEmail(username) &&
        validateString(password, 4, 99, "alphanumeric")
      )
    ) {
      setMessage("Wrong email or password format");
      handleShow();
    } else {
      const response = await axios.post(
        process.env.REACT_APP_API_REST_URL + "/api/AuthenticateUser",
        {
          dvc_id: "dummy",
          email: username,
          password: password,
          mobile_session: true,
        }
      );

      if (response?.data?.status == "Success") {
        setTempAuthToken(
          response?.data?.data?.tempAuthToken
            ? response?.data?.data?.tempAuthToken
            : ""
        );
        setVerificationType(
          response?.data?.data?.twoFAMehtod
            ? response?.data?.data?.twoFAMehtod
            : "Email"
        );
        setIsLoginFormVisible(false);
        setIsLoginVerificationFormVisible(true);
      } else {
        setMessage("Wrong email or password!");
        handleShow();
      }
    }
  };

  return (
    <>
      <Header />

      <CustomModal
        show={show}
        handleClose={handleClose}
        title="Login"
        message={message}
      />

      {/* Login */}
      <section className="login">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="text">
                <ul>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Get started quickly
                    </h5>
                    <p>
                      Integrate with developer-friendly APIs or choose low-code
                      or pre-built solutions.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Support any business model
                    </h5>
                    <p>
                      Host code that you don't want to share with the world in
                      private.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Join millions of businesses
                    </h5>
                    <p>
                      Flowbite is trusted by ambitious startups and enterprises
                      of every size.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              {isLoginFormVisible && !localStorage.getItem("token") ? (
                <form>
                  <h4>Login</h4>
                  <div className="input-container">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      placeholder="name@example.com"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      placeholder="••••••••••"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="check"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />

                    {/* <label htmlFor="check">
                    <p>  By signing up, you are creating a Sendinblue account, and you agree to Flowbite’s <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                    </p>
                  </label> */}
                  </div>
                  <input
                    type="button"
                    value="Login"
                    className="button-style-01"
                    onClick={() => checkLogin()}
                  />

                  <a href="/register" className="link">
                    Sign Up
                  </a>
                  <a href="/forget-password" className="link">
                    Forgot my password
                  </a>
                </form>
              ) : (
                <>
                  {/* <h5 style={{color:'#ddd'}} align='center'>You are already logged in!</h5> */}
                </>
              )}

              {isLoginVerificationFormVisible ? (
                <form>
                  <h4>Login</h4>
                  <div className="input-container">
                    {verificationType !== "Email" ? (
                      <>
                        <label htmlFor="password">
                          Enter your one time code from authenticator !
                        </label>
                      </>
                    ) : (
                      <>
                        <label htmlFor="password">
                          Enter your one time code from your email !
                        </label>
                      </>
                    )}

                    {/* <input type="password" 
                  placeholder="••••••••••"  
                  onChange={e=>setSmsCode(e.target.value)} 
                  /> */}

                    <input
                      type="password"
                      placeholder="••••••••••"
                      value={smsCode}
                      onChange={handleInputSmsChange}
                    />

                    {/* <input type="password" placeholder="••••••••••" onChange={handleVerificationChange} /> */}
                  </div>
                  <div className="countdown">
                    Time Remaining: {formatTime()}
                    <div id="countdown" />
                  </div>
                  <input
                    type="button"
                    value="Verify"
                    className="button-style-01"
                    onClick={() => checkLoginVerification()}
                  />
                </form>
              ) : (
                ""
              )}

              {localStorage.getItem("token") ? (
                <h5 style={{ color: "#ddd" }} align="center">
                  You are already logged in!
                </h5>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Login End */}

      <Footer />
    </>
  );
}

export default Login;
