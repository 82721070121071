import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
 
const IpModal = ({ showModal1, handleCloseModal1, addIp }) => {
  
  const [ipAddress, setIpAddress] = useState('');
 
  
  const isValidIPAddress = (ipAddress) => {
    // Regular expression pattern to match IPv4 address format
    const ipv4Pattern = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
  
    // Regular expression pattern to match IPv6 address format
    const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  
    // Check if the string matches either IPv4 or IPv6 pattern
    return ipv4Pattern.test(ipAddress) || ipv6Pattern.test(ipAddress);
  }

  const addIpFunction = () => {
 
    let ipCheck = isValidIPAddress(ipAddress);

    if (!ipCheck) {
      alert ("Wrong IP Format");
      return false;
    }

    let reqBody = {
        cidr:ipAddress+"/32",
        type:"Login"
      }
      addIp(reqBody);  
  }
    
  return (
    <Modal show={showModal1} onHide={handleCloseModal1}>
      <Modal.Header closeButton>
        <Modal.Title>ADD API</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <br />
      {" You will be logged out after operation."}
      <br />
      <div className='row' style={{paddingBottom:'15px'}}>
        <div className='col-md-12'> 
        <div className="form-group">
            <label for="usr">Enter IP Address:</label>
            <input 
            type="text" 
            className="form-control address-input"
            value={ipAddress}
            onChange={(e) => setIpAddress(e.target.value)}
            />
        </div>
      </div>
      </div>
 
      <div className='row' style={{paddingBottom:'15px'}}>
        <div className='col-md-12'> 
        <div className="form-group"> 
          <button class="btn button-danger" type="button" style={{width:'100%'}} onClick={addIpFunction}>
          Add Ip
          </button> 
        </div>
      </div>
      </div>
 
      </Modal.Body>
      <Modal.Footer> 
        <Button variant="secondary" onClick={handleCloseModal1}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IpModal;