import React, { useState, useEffect, useRef } from "react";
import "./Trade.css";
import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";
import CoinInformation from "../../../Common/CoinInformation.json";
import { fetchMarketData } from "../../../Components/MarketData";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Portfolio = ({ tradeCompleted, setTradeCompleted, coin }) => {
  const tabs = [
    {
      id: 0,
      name: "Open Orders",
    },
    {
      id: 1,
      name: "Trade History",
    },
    {
      id: 2,
      name: "Portfolio",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const [tableShowData, setTableShowData] = useState([]);
  const [tradeHistoryData, setTradeHistoryData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchWord, setSearchWord] = useState();

  const [sortBy, setSortBy] = useState({ key: "date", ascending: false });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [hideBalances, setHideBalances] = useState(false);
  const [hideLowBalances, setHideLowBalances] = useState(false);

  const [btcValue, setBtcValue] = useState();
  const [USDTValue, setUSDTValue] = useState();
   
  const [balanceArr, setBalanceArr] = useState([]);

  const [totalInUsdt, setTotalInUsdt] = useState();
  const [totalInBtc, setTotalInBtc] = useState();

  const [getBalanceActivater, setGetBalanceActivater] = useState(new Date());
  
  const [initialPrices, setInitialPrices] = useState(() => {
    const storedData = localStorage.getItem("initialPrices");
    return storedData ? JSON.parse(storedData) : [];
  });

  

  const sortedtableShowData = tableShowData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  const sortedTradeHistoryData = tradeHistoryData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });


  const handleSort = (key) => {
    setSortBy((prevSortBy) => ({
      key,
      ascending: prevSortBy.key === key ? !prevSortBy.ascending : true,
    }));
  };

  const sortedPortfolioData = portfolioData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  const getSortClass = (key) => {
    if (sortBy.key !== key) return 'table-sort-icon hidden';
    return sortBy.ascending ? 'table-sort-icon' : 'table-sort-icon rotated';
  };

  const fetchMarketDataFunction = async () => {
    let latestFiatData = await fetchMarketData(); 

    const filteredArray = latestFiatData.filter(item => item.currency === 'BTC');
    setBtcValue(filteredArray[0].rate);

    const filteredArray2 = latestFiatData.filter(item => item.currency === 'USDT');
    setUSDTValue(filteredArray2[0].rate);

  }


  const getBalance = async () => {
    let tmpTotalInUsdt = 0;
    let tmpTotalInBtc = 0;
    await axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
        { currency: "ALL" },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        
        console.log(USDTValue)
        const { coinListAll } = getBalanceCoinData(response.data.data);
  

        let tmpCoinListAll = coinListAll;

        if (searchWord && tmpCoinListAll?.length>0) { 
            tmpCoinListAll = tmpCoinListAll.filter(item => 
            item.ShortName.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
          )
        } 
 
        if (hideBalances === true && tmpCoinListAll?.length > 0) {
  
            tmpCoinListAll = tmpCoinListAll.map(item => {
              // Create a new object with the same properties as the original item
              return {
                ...item,
                // Replace specific properties with "********"
                // balance: "-1",
                // balanceInBtc: "-1",
                // balanceInTrade: "-1",
                // balanceInTradeInUsdt: "-1",
                // balanceInUsdt: "-1",
                // total: "-1",
                // totalInBtc: "-1",
                // totalInUsdt: "-1",
              };
            }); 
        }
        
        if (hideLowBalances===true && tmpCoinListAll?.length>0) { 
          tmpCoinListAll = tmpCoinListAll.filter(item => item.balance!="0");
          console.log(tmpCoinListAll)
        } 


        const sortedCoinListAll = tmpCoinListAll.sort(
          (a, b) => b.balance - a.balance
        );

        if (initialPrices) {
          sortedCoinListAll.forEach((item2) => {
            
            // Find the corresponding item in Array1
            const correspondingItem1 = initialPrices.find(
              (item1) => item1.quote === "USDT" && item1.base === item2.currency
            )
            
            if (item2.currency=="USDT") {
              // item2.balance => Crypto Balance
              item2.balanceInUsdt = item2.balance * USDTValue;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt = item2.balanceInTrade * USDTValue;
              item2.balanceInTradeInBtc  = item2.balanceInTradeInUsdt / btcValue;

              item2.total       = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = item2.total * USDTValue;
              item2.totalInBtc  = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt>0) {
                tmpTotalInUsdt+=item2.balanceInUsdt; 
              } 
              if (item2.balanceInBtc>0) {
                tmpTotalInBtc+=item2.balanceInBtc; 
              }  
            }

            // If found, calculate and assign myprice to the current item in Array2
            if (correspondingItem1) {

              // item2.balance => Crypto Balance
              item2.balanceInUsdt = correspondingItem1.price * item2.balance;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt = correspondingItem1.price * item2.balanceInTrade
              item2.balanceInTradeInBtc  = item2.balanceInTradeInUsdt / btcValue;

              item2.total       = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = correspondingItem1.price * item2.total;
              item2.totalInBtc  = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt>0) {
                tmpTotalInUsdt+=item2.balanceInUsdt; 
              } 
              if (item2.balanceInBtc>0) {
                tmpTotalInBtc+=item2.balanceInBtc; 
              }  
            }
          })
          
        }
        
        setTotalInUsdt(tmpTotalInUsdt);
        setTotalInBtc(tmpTotalInBtc);

        setBalanceArr(sortedCoinListAll);
        setPortfolioData(sortedCoinListAll)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
        setIsLoading(false);
      });
  };

  const getBalanceCoinData = (data) => {

    const serviceData = data ? [...data] : [];
 
    const coinInformation = CoinInformation;
    let coinListAll = [];
    
    console.log(serviceData.length)
    for (let i = 0; i < serviceData.length; i++) {

      const coinName = serviceData[i].currency;
      
      if (coinInformation[coinName]?.ShortName) {
        serviceData[i].ShortName = coinInformation[coinName].ShortName;
        serviceData[i].LongName = coinInformation[coinName].LongName;
        coinListAll.push(serviceData[i]);
      }
      // } else {
      //   serviceData.splice(i, 1);
      // }
    }
    return { coinListAll };

  };


  const changeStatus = () => {
    setGetBalanceActivater(new Date());
    console.log(getBalanceActivater);
};

  useEffect(() => {
    getBalance();
  }, [hideBalances, hideLowBalances]);

  useEffect(() => {
    const myTimer = setInterval(changeStatus, 2500);
    return () => clearInterval(myTimer);
  }, [getBalanceActivater]);

  useEffect(() => {
    localStorage.setItem("initialPrices", JSON.stringify(initialPrices));
  }, [initialPrices]);

  useEffect(() => {
    setIsLoading(true); 
    getBalance();
    fetchMarketDataFunction();
    setIsLoading(false);
  }, [getBalanceActivater]);

  useEffect(() => {
    setIsLoading(true); 
    if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
        ws.current.close();
    }
    ws.current = new WebSocket("wss://node1.ledgerbytes.tech/ws");

    ws.current.onopen = () => {
        if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(
                JSON.stringify({
                    method: "subscribe",
                    events: ["MK"],
                })
            );
        }
    };

    ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message?.data) {
            const dataArray = message.data;
            const newArray = dataArray.map(({ base, quote, price }) => ({ base, quote, price }));

            setInitialPrices(prevState => {
                // Create a shallow copy of the previous state array
                const updatedState = [...prevState];
                // Iterate over each item in dataArray
                newArray.forEach(newItem => {
                    // Find the index of the item in the updated state array
                    const index = updatedState.findIndex(item => item.base === newItem.base && item.quote === newItem.quote);
                    // If the item exists in the updated state array, update it; otherwise, push it to the array
                    if (index !== -1) {
                        updatedState[index] = { ...updatedState[index], ...newItem };
                    } else {
                        updatedState.push(newItem);
                    }
                }); 
                return updatedState;
            });
        }
    };

    ws.current.onclose = () => {
        console.log('WebSocket connection closed.');
    };

    // Close WebSocket when Component Terminates
    return () => {
        if (ws.current) {
            ws.current.close();
        }
    }; 
}, []);




  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = activeTab.id === 0
    ? sortedtableShowData.slice(indexOfFirstItem, indexOfLastItem)
    : sortedTradeHistoryData.slice(indexOfFirstItem, indexOfLastItem);

  const token = localStorage.getItem("token");

  let isInstitutional = false;
  if (process.env.REACT_APP_IS_INSTITUTIONAL === "true") {
    isInstitutional = true;
  }

  const [deletedOrderId, setDeletedOrderId] = useState();

  const cancelOrder = async (id, side, pair) => {
    const requestBody = {
      side: side,
      pair: pair,
      orderId: id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/CancelOrder`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status != "Error") {
        const message = response.data.message;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTradeCompleted(!tradeCompleted);
        setTableShowData((prevData) =>
          prevData.filter((order) => order.order_id !== id)
        );
        setDeletedOrderId(id);
      } else {
        const message = response.data.message;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("error:", error);
    }
  };




  const ws = useRef(null);

  useEffect(() => {
    setIsLoading(true);

    if (activeTab.id === 0) {
      // Open Orders
      ws.current = new WebSocket("wss://node1.ledgerbytes.tech/ws");

      ws.current.onopen = () => {
        ws.current.send(
          JSON.stringify({
            method: "login",
            token: `Bearer ${token}`,
          })
        );

        ws.current.send(
          JSON.stringify({
            method: "subscribe",
            events: ["PO.ALL"],
          })
        );
      };

      let accumulatedData = [];

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message?.method == "stream") {
          accumulatedData = accumulatedData.concat(message.data);
          accumulatedData = accumulatedData.filter(
            (order) => order.base === coin?.base && order.quote === coin?.quote
          );

          setTimeout(() => {
            setTableShowData(accumulatedData);
            setIsLoading(false);
          }, 500);
        }
      };

      ws.current.onclose = () => {
      //  console.log("WebSocket connection closed.");
      };

      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    } else if (activeTab.id === 1) {
      // Trade History 
      axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/TradeHistory?side=ALL&pair=ALL&page=1&count=20`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response?.data?.data?.rows);

          let latestData = response?.data?.data?.rows;

          const filteredData = latestData.filter((item) => item.trade === coin.base && item.market === coin.quote);          

          if (searchWord) {
            let filteredShowArray = latestData.filter((item) =>
              item.trade
                .toLocaleLowerCase("en-US")
                .includes(searchWord.toLocaleLowerCase("en-US"))
            );
            setTradeHistoryData(filteredShowArray);
            setIsLoading(false);
          } else {
            // setTradeHistoryData(latestData);
            setTradeHistoryData(filteredData)
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    }
  }, [tradeCompleted, coin, activeTab]);




  return (
    <div className="px-3 px-lg-0 px-md-0 pt-3 border-top custom-border-color border-secondary border-end">
      <ul
        className="nav nav-pills ps-0 ps-lg-3 ps-md-3"
        id="pills-tab"
        role="tablist"
      >
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link rounded-3 py-1 ${
                activeTab.id === tab.id ? "active" : "text-muted"
              }`}
              id={`pills-${tab.name.toLowerCase()}-tab`}
              data-bs-toggle="pill"
              data-bs-target={`#pills-${tab.name.toLowerCase()}`}
              type="button"
              role="tab"
              aria-controls={`pills-${tab.name.toLowerCase()}`}
              aria-selected={activeTab.id === tab.id}
              onClick={() => handleTabClick(tab)}
            >
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane fade ${
              activeTab.id === tab.id ? "show active" : ""
            }`}
            id={`pills-${tab.name.toLowerCase()}`}
            role="tabpanel"
            aria-labelledby={`pills-${tab.name.toLowerCase()}-tab`}
            tabIndex={0}
          >
            {tab.name === "Open Orders" && (
              <div className="table-responsive table-trade-wrapper">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                    <tr>
                      <th>Date</th>
                      <th>Pair</th>
                      <th>Type</th>
                      <th>Side</th>
                      <th>Price</th>
                      <th>Stop</th>
                      <th>Pending</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {token && activeTab.id === 0 ? (
                      <>
                        {currentItems
                          .filter(
                            (order, index, self) =>
                              index ===
                              self.findIndex(
                                (o) => o.order_id === order.order_id
                              )
                          )
                          .filter((order) => order.order_id !== deletedOrderId)
                          .map((open, index) => {
                            const pairString = open.base + "_" + open.quote;
                            return (
                              <tr key={index} className="small">
                                <td>
                                  <div className="date ps-2">
                                    {splitDateTime(open.timestamp).date}
                                    <span className="d-block ms-0">
                                      {splitDateTime(open.timestamp).time}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="pair d-flex align-items-center gap-2">
                                    <img
                                      src={`/images/coins/${open?.base.toLowerCase()}.svg`}
                                      alt=""
                                    />
                                    {open?.base}/{open?.quote}
                                  </div>
                                </td>
                                <td>{open.type}</td>
                                <td>{open.side}</td>
                                <td>{open.rate}</td>
                                <td>{open.stop_price}</td>
                                <td>{open.pending}</td>
                                <td>{open.volume}</td>
                                <td>
                                  {!isInstitutional ? (
                                    <button
                                      className="btn trade-btn"
                                      onClick={() =>
                                        cancelOrder(
                                          open.order_id,
                                          open.side,
                                          pairString
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {tab.name === "Trade History" && (
              <div className="table-responsive table-trade-wrapper">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                    <tr className="small">
                      <th>Date</th>
                      <th>Pair</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th>Size</th>
                      <th>Volume</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {token && activeTab.id === 1 ? (
                      <>
                        {currentItems?.map((trade, index) => {
                          return (
                            <tr key={index} className="small">
                              <td>
                                <div className="date ps-2">
                                  {splitDateTime(trade?.date).date}
                                  <span className="d-block ms-0">
                                    {splitDateTime(trade?.date).time}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="pair d-flex align-items-center gap-2">
                                  <img
                                    src={`/images/coins/${trade?.trade.toLowerCase()}.svg`}
                                    width={32}
                                    height={32}
                                    alt=""
                                  />
                                  {trade?.trade}/{trade?.market}
                                </div>
                              </td>
                              <td>{trade?.side}</td>
                              <td>{formatNumberWithComma(trade?.rate)}</td>
                              <td>{trade?.serviceCharge}</td>
                              <td>{trade?.volume}</td>
                              <td>{trade?.amount}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {tab.name === "Portfolio" && (
              <div className="table-responsive table-trade-wrapper table-portfolio">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                  <tr className="small">
        <th onClick={() => handleSort('currency')} >
          <span className="pe-2">Currency</span>
          <span className={getSortClass('currency')}>
            <img src="./images/icons/top.png" width={16} height={16} /> 
          </span>
          </th>
        <th onClick={() => handleSort('balance')}>
        <span className="pe-2">Balance</span>
          <span className={getSortClass('balance')}>
            <img src="./images/icons/top.png" width={16} height={16} /> 
          </span>
        </th>
        <th onClick={() => handleSort('balanceInTrade')}>
          <span className="pe-2">In Orders</span>
          <span className={getSortClass('balanceInTrade')}>
            <img src="./images/icons/top.png" width={16} height={16} /> 
          </span>
          </th>
        <th onClick={() => handleSort('total')}>
        <span className="pe-2">Total</span>
          <span className={getSortClass('total')}>
            <img src="./images/icons/top.png" width={16} height={16} /> 
          </span>
        </th>
      </tr>
                  </thead>
                  <tbody>
                    {token && activeTab.id === 2 ? (
                      <>
                        {sortedPortfolioData?.map((item, index) => {
                          return (
                            <tr key={index}> 
                            <td>
                              <div className="pair d-flex align-items-center gap-2 ps-2">
                                <img
                                  src={`/images/coins/${item.ShortName.toLowerCase()}.svg`}
                                  width={32}
                                  height={32}
                                />
                                {item.LongName} / {item.ShortName}
                              </div>
                            </td>
                            <td>
                            
                              <div className="date">
                                
                                {item.balance && !hideBalances ? formatNumberWithComma(item.balance) : ""}{" "}
                                {hideBalances ? "******" : ""} 
                                {!hideBalances && !item.balance ? "0" : ""} 
                                &nbsp;{item.ShortName} <br />
  
                                <span className="ml0">
                                $&nbsp;{item?.balanceInUsdt  && !hideBalances ? formatNumberWithComma(item?.balanceInUsdt) : ""} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.balanceInUsdt ? "0" : ""}
                                </span>
                                <br />
                                <span className="ml0">
                                  {item?.balanceInBtc  && !hideBalances ? formatNumberWithComma(item?.balanceInBtc) : ""} 
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.balanceInBtc ? "0" : ""}
                                  &nbsp;BTC</span>
                              </div>
                            </td>
                            <td>
  
                              <div className="date">
                                {item.balanceInTrade  && !hideBalances ? formatNumberWithComma(item?.balanceInTrade) : ""}{" "} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.balanceInTrade ? "0" : ""}
                                &nbsp;{item.ShortName}<br />
  
                                <span className="ml0">
                                  $&nbsp;{item.balanceInTradeInUsdt && !hideBalances ? formatNumberWithComma(item.balanceInTradeInUsdt) : ""}
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.balanceInTradeInUsdt ? "0" : ""}
                                  </span>
                                <br />
                                <span className="ml0">
                                  {item?.balanceInTradeInBtc && !hideBalances ? formatNumberWithComma(item?.balanceInTradeInBtc) : ""} 
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.balanceInTradeInBtc ? "0" : ""}
                                  &nbsp;BTC
                                  </span>
                              </div>
                            </td>
                            <td>
                              <div className="date">
                                {item.total  && !hideBalances ? formatNumberWithComma(item?.total) : ""}{" "} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.total ? "0" : ""}
                                &nbsp;{item.ShortName}<br />
  
                                <span className="ml0">$&nbsp;
                                {item.totalInUsdt && !hideBalances  ? formatNumberWithComma(item.totalInUsdt) : ""} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.totalInUsdt ? "0" : ""}
                                </span>
                                <br />
                                <span className="ml0">
                                  {item?.totalInBtc && !hideBalances  ? formatNumberWithComma(item?.totalInBtc) : ""} 
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.totalInBtc ? "0" : ""}
                                  &nbsp;BTC</span>
                              </div>
                            </td>
                          </tr>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}

<ul className="pagination pagination-container">
  {((activeTab.id === 0 && tableShowData.length > 10) || (activeTab.id === 1 && tradeHistoryData.length > 10)) && (
    <>
      <li>
        {currentPage > 1 ? (
          <button className="btn btn-pagination" onClick={() => setCurrentPage(currentPage - 1)}>Prev</button>
        ) : (
          <button className="btn btn-pagination disabled" disabled>Prev</button>
        )}
      </li>
      <li>
        {activeTab.id === 0 && indexOfLastItem < tableShowData.length ? (
          <button
            className="btn btn-pagination"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        ) : activeTab.id === 1 && indexOfLastItem < tradeHistoryData.length ? (
          <button
            className="btn btn-pagination"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        ) : (
          <button
            className="btn btn-pagination disabled"
            disabled
          >
            Next
          </button>
        )}
      </li>
    </>
  )}
</ul>


          </div>
        ))}
      </div>
    </div>
  );
};
