import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './Overview.css';
import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';
 
import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";
import { useModalContext } from '../../../Context/ModalContext';

import axios from 'axios';
   
function OverView() {

  const { selectedTheme } = useModalContext();

  
    const [data, setData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    /*
     * Pagination and Orderby
    */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    // const [sortBy, setSortBy] = useState({ key: null, ascending: true });
    const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });
  
    // Function to sort data based on column key and sort order
    const sortedData = data.sort((a, b) => {
      if (sortBy.key) {
        const valueA = a[sortBy.key];
        const valueB = b[sortBy.key];
        if (valueA < valueB) return sortBy.ascending ? -1 : 1;
        if (valueA > valueB) return sortBy.ascending ? 1 : -1;
      }
      return 0;
    });
  
    // Function to handle column header click for sorting
    const handleSort = (key) => {
      if (sortBy.key === key) {
        setSortBy({ key, ascending: !sortBy.ascending });
      } else {
        setSortBy({ key, ascending: true });
      }
    };
  
    // Function to handle pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  
    /*
     * Pagination and Orderby Ended
    */

 
    useEffect(() => {
  
      const token = localStorage.getItem("token");
 
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo)
      setUserInfo(userInfo)
 
      // Axios ile GET isteği yapalım
      axios.get(`${process.env.REACT_APP_API_REST_URL}/api/LoginHistory`, {
        headers: {
          'Authorization': `Bearer ${token}` // Bearer token gönderimi
        }
      })
      .then(response => {
        setData(response?.data?.data); 
        // console.log(response?.data?.data);
      })
      .catch(error => {
        console.error('GET isteği hatası:', error);
      });
    }, []);  
    
  return (
    <>
  <Header />

{/* Main */}
<section className="main-page">
  <SideBar mainmenu="Account" submenu="AccountOverview" />
  <div className="main-content">
  <div className="main-content">
        <div className="row">
          <div className="col-lg-8">
            <div className="avatar-box">
              <div className="picture"><img src="/images/materials/tomas.jpg" alt="" /></div>
              <div className="text">
                <h3>{userInfo?.firstName} {userInfo?.lastName}</h3>
                <h5>{userInfo?.email}</h5>
                <ul>
                  <li>
                    <p>Account Number</p>
                    <span>{userInfo?.customerID}</span>
                  </li>
                  <li>
                    <p>Account Type</p>
                    <span>Regular</span>
                  </li>
                  <li>
                    <p>Join Time</p>
                    <span>{userInfo?.joinedOn}</span>
                  </li>
                </ul>
                <a href className="button hover01"><img src={selectedTheme.name === "dark" ? "/images/icons/pencil-alt.svg" : "/images/icons/pencil-alt-black.svg"} alt="" /> Edit Profile</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="verify-box">
              <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg>
                
                {
                  userInfo?.kycStatus && userInfo?.kycStatus=="Approved"
                  ?
                  <>
                    <h5>Account Verified</h5>
                  </>
                  :
                  <>
                    <h5>Verify your account </h5>
                    <span className="tt">Verify</span>
                  </>
                }

              </div>
              {/* <div className="item">
                <p><span>Withdrawal Limit Status </span>300 USDT/ 1.000 USDT</p>
                <div className="progress">
                  <div className="progress-bar w-75" role="progressbar" aria-label="Basic example" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                </div>
              </div> */}
              {/* <div className="item">
                <p>Trading Volume Discount</p>
                <p><span>0 BTC </span>(0% Discount)</p>
                <div className="progress">
                  <div className="progress-bar w-75" role="progressbar" aria-label="Basic example" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="content-box">
          <h4 className="tt">Recent Login History</h4> 
          <div> 
            </div> 
          <div className="table-responsive">
            <table className="table">
              <thead> 
                <tr>
                  <th style={{width:'5%'}}>DEVICE</th>
                  <th style={{width:'18%'}} onClick={() => handleSort('startedOn')}>
                    {sortBy.key==="startedOn" ? <u>STARTED ON</u> : "STARTED ON" } 
                    {sortBy.key==="startedOn" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="startedOn" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('ip')}>
                    {sortBy.key==="ip" ? <u>IP ADDRESS</u> : "IP ADDRESS" }
                    {sortBy.key==="ip" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="ip" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('os')}>
                    {sortBy.key==="os" ? <u>OPERATING SYSTEM</u> : "OPERATING SYSTEM" }
                    {sortBy.key==="os" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="os" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('browser')}>
                    {sortBy.key==="browser" ? <u>BROWSER</u> : "BROWSER" }
                    {sortBy.key==="browser" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="browser" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('location')}>
                    {sortBy.key==="location" ? <u>LOCATION</u> : "LOCATION" }
                    {sortBy.key==="location" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="location" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                </tr>
              </thead>
              <tbody>
  
              {
              currentItems.length>0 ?  

              currentItems.map((item, index) => (
                <tr key={item.id}> 
                  <td>
                    <div className="pair">
                      <img src={selectedTheme.name === "dark" ? "/images/icons/desktop-computer.svg" : "/images/icons/desktop-computer-black.svg"} alt="" />
                      PC
                    </div>
                  </td>
                  <td>
                    <div className="date">
                      { splitDateTime(item.startedOn).date }
                      <span>{ splitDateTime(item.startedOn).time }</span>
                    </div>
                  </td>
                  <td style={{color: '#0E9F6E'}}  >
                  {item.ip.slice(0, 20) }
                  </td>
                  <td>
                  {item.os}
                  </td>
                  <td>
                  {item.browser}
                  </td>
                  <td>
                  {item.location && item.location.trim().startsWith(',') ? item.location.trim().slice(1) : item.location}
                  </td>
                </tr>
                ))
                :
                <>
                <tr key="1" style={{height:200}}> 
                    <td colSpan={6} align="center">
                    <img src="/images/loading.gif" width={50}/>
                    <br /><br />
                    <span style={{color:'#ccc'}}>Loading...</span>   
                    </td>
                </tr>
                </>
                
              }
  
              </tbody>
            </table>
          </div>
          <ul className="pagination"> 
            <li><Link onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</Link></li>
            <li><Link onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= data.length}>Next</Link></li>
          </ul>
        </div>
      </div>
  </div> 
      </section>
 <Footer />
    </>
  );
}
 

export default OverView;