import { Link } from "react-router-dom";

export default function PriceBar () {
    return (

<>
   {/* Marquee */}
   {/* <section className="marquee">
        <div className="container">
          <div className="swiper-marquee">
            <ul>
              <li><a href="#">BTC/USD <span className="up">+0.69%</span></a></li>
              <li><a href="#">AVAX/USD <span className="down">-0.97%</span></a></li>
              <li><a href="#">DOGE/USD <span className="up">+0.69%</span></a></li>
              <li><a href="#">SHIB/USD <span className="up">+0.21%</span></a></li>
              <li><a href="#">LTC/USD <span className="up">+0.22%</span></a></li>
              <li><a href="#">XRP/USD <span className="up">+0.43%</span></a></li>
              <li><a href="#">ARPA/USD <span className="up">+0.30%</span></a></li>
            </ul>
          </div>
        </div>
      </section> */}
      {/* Marquee End */}
</>

    )
}

 