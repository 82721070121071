import React, { useEffect, useState } from 'react';
import ProtectedRoute from "./Common/ProtectedRoute"; // adjust the path accordingly

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./i18n";

import { BrowserRouter as Router, BrowserRouter, Routes, Route } from 'react-router-dom';

import AccountAffiliates from "./Pages/Account/Affiliates";
import AccountApiKeys from "./Pages/Account/ApiKeys";
import AccountChangePassword from "./Pages/Account/ChangePassword";
import AccountIpWhiteListing from "./Pages/Account/IpWhitelisting";
import AccountOverview from "./Pages/Account/Overview";
import AccountSecurity from "./Pages/Account/Security";
import AccountVerification from "./Pages/Account/Verification";
import AccountVolumeDiscount from "./Pages/Account/VolumeDiscount";
import AccountWhitelistedDevices from "./Pages/Account/WhitelistedDevices";
import AccountExchangeToken from "./Pages/Account/ExchangeToken";
import AccountFirstVerification from "./Pages/Account/FirstVerification";

import WalletAddressBook from "./Pages/Wallet/AddressBook";
import WalletBank from "./Pages/Wallet/Banks";
import WalletDeposits from "./Pages/Wallet/Deposits";
import WalletOverview from "./Pages/Wallet/Overview";
import WalletWithdrawal from "./Pages/Wallet/Withdrawals";

import OrdersInstaTrades from "./Pages/Order/InstaTrades";
import OrdersOpenOrders from "./Pages/Order/OpenOrders";
import OrdersOrderHistory from "./Pages/Order/OrderHistory";
import OrdersTradeHistory from "./Pages/Order/TradeHistory";

import ContentApiDocumentation from "./Pages/Content/ApiDocumentation";
import ContentCurrencies from "./Pages/Content/Currencies";
import ContentExchangeFees from "./Pages/Content/ExchangeFees";
import ContentAssetStatus from "./Pages/Content/AssetStatus";
import ContentTradingRules from "./Pages/Content/TradingRules";

import MainHome from "./Pages/Main/Home";
import MainMarket from "./Pages/Main/Market";
import MainTrade from "./Pages/Main/Trade";
import MainInstaTrade from "./Pages/Main/InstaTrade";
import MainSupport from "./Pages/Main/Support";

import AuthLogin from "./Pages/Auth/Login";
import AuthRegister from "./Pages/Auth/Register";
import AuthForgetPassword from "./Pages/Auth/ForgetPassword";
import AuthResetPassword from "./Pages/Auth/ResetPassword";
import AuthLoginVerification from "./Pages/Auth/LoginVerification";
import AuthLogout from "./Pages/Auth/Logout";

import Home from "./Pages/Main/Home";

import { useDispatch } from 'react-redux';
import { fetchLabels } from './Features/Labels/LabelsSlice';
import { fetchCurrencySettings } from './Features/CurrencySettings/CurrencySettingsSlice';

import SettingsModal from './Components/SettingsModal';

import { useModalContext } from "./Context/ModalContext";

function App() {

  const { selectedLanguage, selectedTheme, themeNo, setThemeNo } = useModalContext();


  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
   
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("selected language changed:", selectedLanguage);
    if (selectedLanguage) {
      dispatch(fetchLabels(selectedLanguage.code));
    }
    dispatch(fetchCurrencySettings());
  }, [dispatch, selectedLanguage]);



  useEffect(() => {
    const themeNumber = process.env.REACT_APP_THEME_NO;
    setThemeNo(themeNumber ? parseInt(themeNumber) : 1);
  }, []);

  const [selectedMode, setSelectedMode] = useState("dark")
  useEffect(() => {
    const mode = selectedTheme.name;
    setSelectedMode(mode)
  }, [selectedTheme])

  useEffect(() => {
    const body = document.body;
    if (selectedTheme.name === 'light') {
      body.classList.remove('dark');
      body.classList.add(`theme_no_${themeNo}`, 'light');
    } else if (selectedTheme.name === 'dark') {
      body.classList.remove('light');
      body.classList.add(`theme_no_${themeNo}`, 'dark');
    }
  }, [selectedTheme]);
 
          return (
            <div>
        <BrowserRouter>
<ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

        <SettingsModal
          modalVisible={settingsModalVisible}
        />

          <Routes>
 
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/register" element={<AuthRegister />} />
            <Route path="/forget-password" element={<AuthForgetPassword />} />
            <Route path="/reset-password/:otp" element={<AuthResetPassword />} />
            <Route path="/login-verification" element={<AuthLoginVerification />} />
            <Route
              path="/account-verification/:id"
              element={<AccountFirstVerification />}
            />
            <Route path="/logout" element={<AuthLogout />} />

            <Route path="/home" element={<MainHome />} />
            <Route path="/market" element={<MainMarket />} />
         
            <Route path="/trade/:pair?" element={<MainTrade />} />

            <Route path="/insta-trade" element={<MainInstaTrade />} />
            <Route path="/support" element={<MainSupport />} />

            <Route
              path="/account/overview"
              element={
                <ProtectedRoute>
                  <AccountOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/verification"
              element={
                <ProtectedRoute>
                  <AccountVerification />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/security"
              element={
                <ProtectedRoute>
                  <AccountSecurity />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/ip-white-listing"
              element={
                <ProtectedRoute>
                  <AccountIpWhiteListing />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/affiliates"
              element={
                <ProtectedRoute>
                  <AccountAffiliates />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/api-keys"
              element={
                <ProtectedRoute>
                  <AccountApiKeys />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/exchange-token"
              element={
                <ProtectedRoute>
                  <AccountExchangeToken />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/volume-discount"
              element={
                <ProtectedRoute>
                  <AccountVolumeDiscount />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/change-password"
              element={
                <ProtectedRoute>
                  <AccountChangePassword />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/whitelisted-devices"
              element={
                <ProtectedRoute>
                  <AccountWhitelistedDevices />
                </ProtectedRoute>
              }
            />

            <Route
              path="/wallet/overview"
              element={
                <ProtectedRoute>
                  <WalletOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="/wallet/deposits"
              element={
                <ProtectedRoute>
                  <WalletDeposits />
                </ProtectedRoute>
              }
            />

            <Route
              path="/wallet/withdrawals"
              element={
                <ProtectedRoute>
                  <WalletWithdrawal />
                </ProtectedRoute>
              }
            />

            <Route
              path="/wallet/addressbook"
              element={
                <ProtectedRoute>
                  <WalletAddressBook />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet/banks"
              element={
                <ProtectedRoute>
                  <WalletBank />
                </ProtectedRoute>
              }
            />

            <Route
              path="/order/open-orders"
              element={
                <ProtectedRoute>
                  <OrdersOpenOrders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order/order-history"
              element={
                <ProtectedRoute>
                  <OrdersOrderHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order/trade-history"
              element={
                <ProtectedRoute>
                  <OrdersTradeHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order/insta-trades"
              element={
                <ProtectedRoute>
                  <OrdersInstaTrades />
                </ProtectedRoute>
              }
            />

            <Route
              path="/api-documentation"
              element={<ContentApiDocumentation />}
            />
            <Route path="/currencies" element={<ContentCurrencies />} />
            <Route path="/exchange-fees" element={<ContentExchangeFees />} />
            <Route path="/trading-rules" element={<ContentTradingRules />} />
            <Route path="/asset-status" element={<ContentAssetStatus />} />
          </Routes>
        </BrowserRouter>
        </div>
      );
  
}

export default App;
