import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Withdrawals.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";
import CoinInformation from "../../../Common/CoinInformation.json";
import {
  fetchMarketData,
  getCurrencySettings,
  fetchMarketMergedInstaData,
} from "../../../Components/MarketData";

import ExportExcelButton from "../../../Common/ExportExcelButton";
import DownloadPDFButton from "../../../Common/DownloadDynPDFButton";
import CsvExporter from "../../../Common/CsvExporter"; // Adjust the path as per your file structure

import {
  splitDateTime,
  formatNumberWithComma,
  splitCoinCurrency,
} from "../../../Common/Helpers";

import axios from "axios";
import Select from "react-select";
import SelectWithdrawalType from "./SelectWithdrawalType";

import Dropdown from "../../../Components/Dropdown";

const tableHeaders = [
  "withdrawalAmount",
  "withdrawalConfirmDate",
  "withdrawalReqDate",
  "withdrawalStatus",
  "withdrawalType",
  "withdrawalAddress",
];

function Withdrawals() {
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [withdrawalAddress, setWithdrawalAddress] = useState();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [amount, setAmount] = useState();
  const [rateAmount, setRateAmount] = useState();

  const [emailToken, setEmailToken] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [withdrawalEmailOtp, setWithdrawalEmailOtp] = useState("");
 
  const [marketData, setMarketData] = useState([]);
  const [currency, setCurrency] = useState("ALL");
  const [isVisible, setIsVisible] = useState(false);

  const [is2FaEnabled, setIs2FaEnabled] = useState(false);
  const [gAuth, setGAuth] = useState("");
  const [currentCryptoPrices, setCurrentCryptoPrices] = useState([]);

  const [searchWord, setSearchWord] = useState();
  const [tableShowData, setTableShowData] = useState([]);

  const [selectedOption, setSelectedOption] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankAddress, setBankAddress] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [withdrawalBankAmount, setWithdrawalBankAmount] = useState("");
  const [emailWithdrawalToken, setEmailWithdrawalToken] = useState("");
   
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  const sendWithdrawalOtpFunction = async () => {
 
    let requestBody = {
      address: selectedBank.bankName,
      amount: withdrawalBankAmount,
      currency: currency,
      otp_type: "email"
    }

   try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/RequestWithdraw_EmailOTP`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response?.data?.status == "Success") {
      setEmailWithdrawalToken(response?.data?.data?.token);
      toast.success("Please check your email", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("An error occured.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    toast.error("An error occured. Please try again later.", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
 
  }

  const [expandedRow, setExpandedRow] = useState(null);
  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const requestWithdrawalBankAmountFunction = (tmpRequestAmount) => {
    // Accept only numeric characters
    const numericInput = tmpRequestAmount.replace(/\D/g, "");
    setWithdrawalBankAmount(numericInput);
  }
 
  const setWithdrawalEmailOtpFunction = (tmpVal) => {
    // Accept only numeric characters
    const numericInput = tmpVal.replace(/\D/g, "");
    setWithdrawalEmailOtp(numericInput);
  }

  let sendOtpFunctionStatus = false;
  let handleFunctionStatus = false;

  if (currency != "ALL" && amount && withdrawalAddress && emailOtp) {
    handleFunctionStatus = true;
    sendOtpFunctionStatus = true;
  } else if (currency != "ALL" && amount && withdrawalAddress) {
    sendOtpFunctionStatus = true;
  }

  let sendWithdrawalOtpFunctionStatus = false;
  if (withdrawalBankAmount) {
    sendWithdrawalOtpFunctionStatus = true; 
  }

  let handleOkAddressFunctionStatus = false;
  if (withdrawalBankAmount && withdrawalEmailOtp) {
    handleOkAddressFunctionStatus = true; 
  }

  const handleOkAddressFunction = async () => {
 
    const requestBody = {

      AccountNumber:selectedBank.accountNumber,
      BankName:selectedBank.bankName,
      BankRoutingCode:selectedBank.bankRoutingCode,
      BeneficiaryName: "Self",
      CurrencyName:currency,
      RequestAmount:withdrawalBankAmount,
      SwiftCode:selectedBank.swiftCode,
      email_otp:withdrawalEmailOtp,
      email_token:emailWithdrawalToken,
      gauth_code:gAuth
    }

    console.log(requestBody);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/withdraw-fiat?fee=v2`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(response);

      if (response) {
        if (response?.data?.status == "Success") {
          toast.success("Request has been sent.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(JSON.stringify(response?.data?.data), {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      toast.error("An error occured. Please try again later.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

  }
 
  const handleBankChange = (accountNumber) => {
    const foundBank = banks.find((bank) => bank.accountNumber === accountNumber);
    setSelectedBank(foundBank);
  }

  const handleWithdrawalMethodChange = (selectedOption) => {
    setWithdrawalMethod(selectedOption);
  }

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

    if (newKeyword) {
      let tmpSearchWord = newKeyword;
      if (tableShowData) {
        let filteredShowArray = tableShowData.filter((item) =>
          item.withdrawalType
            .toLocaleLowerCase("en-US")
            .includes(tmpSearchWord.toLocaleLowerCase("en-US"))
        );
        setTableShowData(filteredShowArray);
      }
    }
  };

  const getWalletType = (shortName) => {
    const upperCaseShortName = shortName.toUpperCase();
    let walletType = "Token";
    if (marketData) {
      const foundObject = marketData.find(
        (item) => item.shortName.toUpperCase() === upperCaseShortName
      );
      let orgWalletType = foundObject.walletType;
      if (orgWalletType) {
        if (orgWalletType.startsWith("Fiat")) {
          walletType = "Fiat";
        }
      }
    }
    return walletType;
  };

  const getBankAccounts = () => {
    // Axios GET
    axios
      .get(
        `${process.env.REACT_APP_API_REST_URL}/api/Get_Fiat_CustomerAccounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        setBanks(response?.data?.data);
      })
      .catch((error) => {
        console.error("GET error:", error);
      });
  };

  const setAmountFunction = async (tmpAmount) => {
    let tmpRate = getRateByCurrency(currency);
    let tmpResult = parseFloat(tmpRate) * parseFloat(tmpAmount);

    if (isNaN(tmpRate)) {
      tmpRate = 0;
    }
    if (isNaN(tmpResult)) {
      tmpResult = 0;
    }

    setAmount(tmpAmount);
    setRateAmount(tmpResult);
  };

  const setRateAmountFunction = async (tmpRateAmount) => {
    let tmpRate = getRateByCurrency(currency);
    let tmpResult = parseFloat(tmpRateAmount) / parseFloat(tmpRate);

    if (isNaN(tmpRate)) {
      tmpRate = 0;
    }
    if (isNaN(tmpResult)) {
      tmpResult = 0;
    }

    setAmount(tmpResult);
    setRateAmount(tmpRateAmount);
  };

  const doWithdrawal = async () => {
    if (!/^\d{6}$/.test(emailOtp)) {
      alert("Invalid Email Token Code.");
      return;
    }

    if (!/^\d{6}$/.test(gAuth)) {
      alert("Invalid GAuth Code.");
      return;
    }

    const requestBody = {
      address: withdrawalAddress,
      amount: amount,
      currency: currency,
      email_otp: emailOtp,
      email_token: emailToken,
      gauth_code: gAuth,
      sms_otp: "",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/withdraw-crypto?fee=v2`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
 
      if (response?.data?.status == "Success") {
        toast.success(JSON.stringify(response?.data?.data), {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Clear the form
        setCurrency("ALL");
        setWithdrawalAddress("ALL");
        setAmount();
        setRateAmountFunction();
        setEmailOtp();
        setSelectedBank("");
        setGAuth();
        getWithdrawals();
        setIsVisible(false);
      } else {
        toast.error("An error occured.", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error("An error occured. Please try again later.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getWithdrawalOtp = async () => {
    const requestBody = {
      address: withdrawalAddress,
      amount: amount,
      currency: currency,
      otp_type: "email",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/RequestWithdraw_EmailOTP`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        setEmailToken(response?.data?.data?.token);

        if (response?.data?.status == "Success") {
          toast.success(JSON.stringify(response?.data?.message), {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      toast.error("An error occured. Please try again later.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const getData = async () => {
    const requestBody = {
      Currency: "ALL",
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/Get_AddressBook`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAddresses(response?.data?.data);
    } catch (error) {
      console.error("GET isteği hatası:", error);
    }
  };

  const fetchData = async () => {
    try {
      // let marketDataResponse = await fetchMarketData();
      let marketDataResponse = await getCurrencySettings();

      const coinInformation = CoinInformation;
      for (let i = 0; i < marketDataResponse.length; i++) {
        marketDataResponse[i].ShortName = marketDataResponse[i].shortName;
        marketDataResponse[i].LongName = marketDataResponse[i].fullName;
        marketDataResponse[i].currency = marketDataResponse[i].ShortName;
      }

      marketDataResponse.sort((a, b) => {
        const nameA = a.shortName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.shortName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      setMarketData(marketDataResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getWithdrawals = async (myCurrency = "ALL") => {
    setIsLoading(true);
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/GetWithdrawals`,
          { currency: myCurrency },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Bearer token gönderimi
            },
          }
        )
        .then((response) => {
          setData(response?.data?.data?.withdrawals);
          setTableShowData(response?.data?.data?.withdrawals);

          let latestData = response?.data?.data?.withdrawals;

          if (searchWord) {
            let filteredShowArray = latestData.filter((item) =>
              item.withdrawalType
                .toLocaleLowerCase("en-US")
                .includes(searchWord.toLocaleLowerCase("en-US"))
            );
            setTableShowData(filteredShowArray);
          } else {
            setTableShowData(latestData);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("GET isteği hatası:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleCurrencyChange = async (selectedOption) => {
    setSelectedOption(selectedOption);

    setAmount();
    setEmailOtp();
    setWithdrawalAddress("");
    setSelectedBank("");
    setCurrency(selectedOption.currency);
    getWithdrawals(selectedOption.currency);

    // getBalance();

    // Assuming addresses is your original array and givenCurrency is the variable holding the currency value to filter
    let tmpArr = addresses.filter(
      (item) => item.Currency === selectedOption.currency
    );
    setFilteredAddresses(tmpArr);
    if (selectedOption.currency == "ALL") {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#121928" : state.data.bgColor, // Set background color based on bgColor property
      color: state.isSelected ? "#fff" : "#ccc", // Text color
      padding: 8,
    }),
    singleValue: (provided) => ({
      ...provided,
    //  color: "#ccc", // Change text color of the selected value
    }),
    input: (provided) => ({
      ...provided,
    //  color: "#ccc", // Change text color while typing
    }),
  };

  const check2Fa = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIs2FaEnabled(response?.data?.data?.is2FAEnabled);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
      });
  };

  const fetchMarketDataFunction = async () => {
    //let latestFiatData = await fetchMarketMergedInstaData();
    let latestFiatData = await fetchMarketData();
    setCurrentCryptoPrices(latestFiatData);
  };

  const getRateByCurrency = (tmpCurrency) => {
    if (tmpCurrency) {
      // Convert input currency to lowercase for case-insensitive comparison
      const lowerCaseCurrency = tmpCurrency.toLowerCase();

      // Find the object with the matching currency (ignoring case)
      const currencyObject = currentCryptoPrices.find(
        (crypto) => crypto.currency.toLowerCase() === lowerCaseCurrency
      );

      // If the currency is found, return its rate; otherwise, return null or any default value
      return currencyObject ? currencyObject.rate : 0;
    } else {
      return 0;
    }
  };

  const getBalance = async (tmpCurrency) => {
 
    const token = localStorage.getItem("token");
    const reqBody = { currency: tmpCurrency }

    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
    ).catch(error => {
      console.error('Post Error:', error);
    });
    console.log(response);
    return true;
 
}

  useEffect(() => {
    getData();
    fetchData();
    getWithdrawals();
    check2Fa();
    fetchMarketDataFunction();
    getBankAccounts();
  }, [searchWord]);


  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page withdrawals">
        <SideBar mainmenu="Wallet" submenu="WalletWithdrawals" />
        <div className="main-content">
          <h4 className="title">Withdrawals</h4>

          <div className="row">
            <div className="col-lg-12 mb20">
              <form className="form-flex">
                <div className="withdrawals-dropdown w-100">
                  <Select
                    className="custom-select"
                    value={selectedOption}
                    onChange={handleCurrencyChange}
                    options={marketData}
                    getOptionValue={(option) => option.currency}
                    getOptionLabel={(option) =>
                      `${option.LongName} (${option.currency})`
                    }
                    isSearchable={true}
                    placeholder="ALL Coins - Please Select"
                    styles={customStyles}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb20">
              {/* {withdrawalDiv} */}

              {isVisible && getWalletType(currency) == "Token" ? (
                <>
                  <div className="row">
                    <div className="col-lg-6 mb20">
                      <form className="form-flex">
                        <div className="search2">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setWithdrawalAddress(e.target.value)
                            }
                          >
                            <option value="ALL">Please Select</option>
                            {filteredAddresses.length > 0 &&
                              filteredAddresses.map((item, index) => (
                                <option key={index} value={item.Address}>
                                  {item.Label}
                                </option>
                              ))}
                          </select>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>&nbsp;</label>
                        <Link
                          to="/wallet/addressbook"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          // onClick={handleOkBankFunction}
                        >
                          Address Book
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "15px" }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Address:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={withdrawalAddress}
                          onChange={(e) => setWithdrawalAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "15px" }}>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Amount:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmountFunction(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>USD Amount:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={rateAmount}
                          onChange={(e) =>
                            setRateAmountFunction(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: "15px" }}>
                    <div className="form-group">
                      <label>
                        Email Verification Code:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setEmailOtp(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            disabled={!sendOtpFunctionStatus}
                            style={{
                              borderRadius: "0 0.25rem 0.25rem 0",
                              backgroundColor: sendOtpFunctionStatus
                                ? ""
                                : "#dadada",
                              color: sendOtpFunctionStatus ? "" : "#222",
                            }}
                            type="button"
                            onClick={getWithdrawalOtp}
                          >
                            REQUEST OTP
                          </button>
                        </div>
                      </div>
                    </div>

                    {is2FaEnabled ? (
                      <div className="form-group">
                        <label>
                          Google Auth. 2FA Code:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={gAuth}
                          onChange={(e) => setGAuth(e.target.value)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row" style={{ paddingBottom: "15px" }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <button
                          className="btn btn-primary"
                          disabled={!handleFunctionStatus}
                          style={{
                            borderRadius: "0 0.25rem 0.25rem 0",
                            backgroundColor: handleFunctionStatus
                              ? ""
                              : "#dadada",
                            color: handleFunctionStatus ? "" : "#222",
                            width: "100%",
                          }}
                          type="button"
                          onClick={doWithdrawal}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {isVisible && getWalletType(currency) != "Token" ? (
                <>
                  <span style={{ paddingBottom: "5px" }}>
                    Select a Deposit Method
                  </span>
                  <SelectWithdrawalType
                    value={withdrawalMethod}
                    currency={currency}
                    onChange={handleWithdrawalMethodChange}
                  />

                  {withdrawalMethod == "bankTransfer" ? (
                    <>
                      <p style={{ paddingTop: "15px" }}>
                        Total Balance: 0 {currency}
                        <br />
                        Balance In Orders: 0 {currency}
                        <br />
                        Available to withdrawal: 0 {currency}
                        <br />
                      </p>
                      <div className="row">
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            onChange={(e) => handleBankChange(e.target.value)}
                          >
                            <option value="ALL">Please Select</option>
                            {banks.length > 0 &&
                              banks.map((item, index) => {
                                // Check if the accountCurrency is equal to "EUR"
                                if (item.accountCurrency === currency) {
                                  return (
                                    <option key={index} value={item.accountNumber}>
                                      {item.bankName}
                                    </option>
                                  );
                                } else {
                                  // If not EUR, do not display the option
                                  return null;
                                }
                              })}
                          </select>
                        </div>
                        <div className="col-md-2">
                          <Link
                            to="/wallet/banks"
                            target="_blank"
                            class="button-danger"
                          >
                            ADD BANK
                          </Link>
                        </div> 
                      </div>
<div className="row">
  <div className="col-md-10">

  {selectedBank 
                        ? 
                        <p style={{ paddingTop:'15px'}}> 
                          Bank Name: {selectedBank.bankName}
                          <br />
                          Account Number: {selectedBank.accountNumber}
                          <br />
                          Account Type: Savings
                          <br />
                          Account Currency: {selectedBank.accountCurrency}
                          <br />
                          Bank Routing Code: {selectedBank.bankRoutingCode}
                          <br />
                          Swift Code: {selectedBank.swiftCode} 
                          <br /><br />

                          Request Amount
                          <br />
                          <input className="textbox form-control" value={withdrawalBankAmount} onChange={(e) => requestWithdrawalBankAmountFunction(e.target.value)} />
                          <br />

        <div className="row">
          <div className="form-group">
            <label for="usr">Withdrawal Email Verification Code:</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                value={withdrawalEmailOtp}
                onChange={(e) => setWithdrawalEmailOtpFunction(e.target.value)}
              />
              <div class="input-group-append">
                <button
                  class="btn button-danger"
                  type="button"
                  disabled={!sendWithdrawalOtpFunctionStatus}
                  style={{
                    borderRadius: "0 0.25rem 0.25rem 0",
                    backgroundColor: sendWithdrawalOtpFunctionStatus ? "" : "#dadada",
                    color: sendWithdrawalOtpFunctionStatus ? "" : "#222",
                  }}
                  onClick={sendWithdrawalOtpFunction}
                >
                  REQUEST OTP
                </button>
              </div>
            </div>
          </div>
        </div>


        {is2FaEnabled ? (
          <div className="row" style={{ paddingBottom: "15px" }}>
            <div className="col-md-12">
              <div className="form-group">
                <label for="usr">Google Auth. 2FA Code:</label>
                <input
                  type="text"
                  className="form-control"
                  value={gAuth}
                  onChange={(e) => setGAuth(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
 
        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <button
                class="btn button-danger" 
                type="button"
                style={{
                    opacity: 1,
                    width: "100%",
                    backgroundColor: handleOkAddressFunctionStatus
                      ? "#F05252"
                      : "#dadada",
                    color: handleOkAddressFunctionStatus 
                      ? "#ffffff" 
                      : "#222222",
                  }} 
                disabled={!handleOkAddressFunctionStatus}
                onClick={handleOkAddressFunction}
              > 
                WITHDRAWAL REQUEST
              </button>
            </div>
          </div>
        </div>
 
                        </p> 
                        : 
                        ""
                        }

  </div>
</div>


                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-10 mb20">
              <form className="form-flex">
                <div className="search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                      stroke="#9CA3AF"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchWord}
                    onChange={handleSearch}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={14}
                    viewBox="0 0 13 14"
                    fill="none"
                  >
                    <path
                      d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                      fill="#111928"
                      stroke="#9CA3AF"
                    />
                  </svg>
                </div>
              </form>
            </div>
            <div className="col-lg-2 dropdown">
              <button
                onClick={toggleDropdown}
                className="btn btn-primary"
                style={{
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                  height: 47
                }}
              >
                Export Data
              </button>

              {isOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "20",
                    zIndex: "999",
                  }}
                >
                  <DownloadPDFButton
                    data={tableShowData}
                    headers={tableHeaders}
                  />
                  <div style={{ paddingBottom: "5px" }} />
                  <CsvExporter data={tableShowData} />
                  <div style={{ paddingBottom: "5px" }} />
                  <ExportExcelButton
                    data={tableShowData}
                    filename="exported_data"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>REQUESTED</th>
                    <th>WITHDRAWED</th>
                    <th>COIN</th>
                    <th>AMOUNT</th>
                    <th>TRANSACTION HASH</th>
                  </tr>
                </thead>
                <tbody>
                  {tableShowData.length > 0 ? (
                    tableShowData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr
                          key={item.id}
                          onClick={() => handleRowClick(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            <div className="date">
                              {item?.withdrawalReqDate
                                ? splitDateTime(item?.withdrawalReqDate).date
                                : ""}
                              <span>
                                {" "}
                                {item?.withdrawalReqDate
                                  ? splitDateTime(item?.withdrawalReqDate).time
                                  : ""}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="date">
                              {item?.withdrawalConfirmDate
                                ? splitDateTime(item?.withdrawalConfirmDate)
                                    .date
                                : "Pending"}
                              <span>
                                {" "}
                                {item?.withdrawalConfirmDate
                                  ? splitDateTime(item?.withdrawalConfirmDate)
                                      .time
                                  : ""}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="pair">
                              <img
                                src={`/images/coins/${item.withdrawalType.toLowerCase()}.svg`}
                                alt=""
                              />
                              {item.withdrawalType}
                            </div>
                          </td>
                          <td>{item.withdrawalAmount}</td>
                          <td>{item?.txnHash}</td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan="5">
                              <span style={{ color: "#aaa", fontSize: "10pt" }}>
                                Block Explorer URL: {item.explorerURL}
                                <br />
                                Withdrawal Address: {item.withdrawalAddress}
                                <br />
                                Withdrawal Amount: {item.withdrawalAmount}
                                <br />
                                Network Name: {item.networkName}
                              </span>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      {isLoading ? (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={5} align="center">
                            <img src="/images/loading.gif" width={50} />
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>Loading...</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={5} align="center">
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>No Data...</span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {/* <ul className="pagination">
              <li>
                <Link to="#">Previous</Link>
              </li>
              <li>
                <Link to="#">1</Link>
              </li>
              <li>
                <Link to="#">2</Link>
              </li>
              <li>
                <Link to="#">3</Link>
              </li>
              <li>
                <Link to="#">Next</Link>
              </li>
            </ul> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Withdrawals;
