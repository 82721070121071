import React, { useState, useEffect, useRef, useMemo } from "react";
import { segregateAndSortTrades } from "../../../Common/Helpers.js";
import "./Trade.css";
import { useFetcher } from "react-router-dom";
import { formatNumberWithComma } from "../../../Common/Helpers";  



export const OrderBook = ({ coin, primaryCoin, activeMarket }) => {


  const getSocketVariables = (coin, primaryCoin, activeMarket) => {
    const base = coin ? coin.base : primaryCoin?.base;
    return {
      SocketVariableOb: `OB.${base}_${activeMarket}`,
      SocketVariableRT: `RT.${base}_${activeMarket}`,
      SocketVariablePO: `PO.${base}_${activeMarket}`,
    };
  };

  const [socketVars, setSocketVars] = useState(getSocketVariables(coin, primaryCoin, activeMarket));

  const [transactions, setTransactions] = useState();
  const [asks, setAsks] = useState([]);
  const [bids, setBids] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [trades, setTrades] = useState([]);
  const [latestTradePrice, setLatestTradePrice] = useState(0);
  const [latestTradeType, setLatestTradeType] = useState("BUY");
  const [averageOfLatestPrices, setAverageOfLatestPrices] = useState(0);


  const sortedAsks = useMemo(() => {
    return asks?.sort((a, b) => b[0] - a[0]).slice(-10);
  }, [asks]);
  
  const sortedBids = useMemo(() => {
    return bids?.sort((a, b) => b[0] - a[0]).slice(0, 10);
  }, [bids]);
  
  const calculatedAsks = useMemo(() => {
    return sortedAsks?.map((ask, index) => {
      const total = sortedAsks?.slice(0, index + 1).reduce((sum, ask) => sum + ask[1], 0);
      return { price: ask[0], size: ask[1], total: total.toFixed(6) };
    });
  }, [sortedAsks]);
  
  const calculatedBids = useMemo(() => {
    return sortedBids?.map((bid, index) => {
      const total = sortedBids?.slice(0, index + 1).reduce((sum, bid) => sum + bid[1], 0);
      return { price: bid[0], size: bid[1], total: total.toFixed(6) };
    });
  }, [sortedBids]);

  const ws = useRef(null);

  const subscribeToEvents = () => {
    ws.current.send(JSON.stringify({
        method: "subscribe",
        events: [socketVars.SocketVariableOb, socketVars.SocketVariableRT, socketVars.SocketVariablePO],
    }));
   

    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data?.event === socketVars.SocketVariableOb) {
        if (data?.data?.asks?.length > 10) {
          const sortedAsks = data.data.asks.sort((a, b) => b[0] - a[0]);
          setAsks(sortedAsks.slice(-10));
        } else {
          setAsks(data.data.asks.sort((a, b) => b[0] - a[0]));
        }
        if (data?.data?.bids?.length > 10) {
          const sortedBids = data.data.bids.sort((a, b) => b[0] - a[0]);
          setBids(sortedBids.slice(0, 10));
        } else {
          setBids(data.data.bids.sort((a, b) => b[0] - a[0]));
        }
      } else if (data?.event === socketVars.SocketVariableRT) {
        if (data?.data && data?.data[0]) {
          setTrades([...data.data]);
          setLatestTradePrice(data.data[0].rate);
          setLatestTradeType(data.data[0].execution_side);
          const latestPrices = data.data.slice(0, 10).map(trade => trade.rate);
          const average = latestPrices.reduce((acc, price) => acc + price, 0) / latestPrices.length;
          setAverageOfLatestPrices(average);
        }
      } else if (data?.event === socketVars.SocketVariablePO) {
        if (data?.data) {
          let openOrderList = [...openOrders];
          data.data.forEach((item) => {
            if (item?.status === false) {
              const index = openOrderList.findIndex(
                (oldOrder) => oldOrder.order_id === item.order_id
              );
              if (index === -1) {
                openOrderList.push(item);
              } else {
                openOrderList[index] = item;
              }
            } else {
              const index = openOrderList.findIndex(
                (oldOrder) => oldOrder.order_id === item.order_id
              );
              if (index > -1) {
                openOrderList.splice(index, 1);
              }
            }
          });
          setOpenOrders(openOrderList.sort((a, b) => b.timestamp.localeCompare(a.timestamp)).filter(item => item?.base === primaryCoin?.base));
        } else {
          setOpenOrders([]);
        }
      }
    };
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
  
    ws.current = new WebSocket('wss://node1.ledgerbytes.tech/ws');
  
    ws.current.onopen = () => {
      if (token) {
        ws.current.send(JSON.stringify({
          method: "login",
        }));
  
        ws.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.method === "login" && data.status === "success") {
            subscribeToEvents();
          }
        };
      } else {
        subscribeToEvents();
      }
    };
  
    ws.current.onerror = (error) => {
      console.log("WebSocket error: " + error);
    };
  
    ws.current.onclose = () => {
      console.log("order-book WebSocket connection closed");
    };
  
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);
  



  useEffect(() => {
    const newSocketVars = getSocketVariables(coin, primaryCoin, activeMarket);
    setSocketVars(newSocketVars);
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      subscribeToEvents();
    }
  }, [coin, primaryCoin, activeMarket]);




  return (
    <div className="order-book">
      <div className="d-flex justify-content-between flex-col flex-lg-row flex-md-row px-2">
        <div>
          <div className="d-flex justify-content-start align-items-center mb-1">
          <h2 className="order-book-title fs-5 mb-0 me-2">Order Book</h2>
          <div className="d-flex gap-1">
            <div className="d-flex flex-column gap-1">
              <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
            <div className="d-flex flex-column gap-1">
            <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
            <div className="d-flex flex-column gap-1">
              <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
          </div>
          </div>
        </div>
        <div>
          <button className="bg-transparent border-0 small text-white d-flex align-items-center">
            <span>0.1</span>
            <img src="/images/icons/caret-down.svg" alt="" className="ps-2" />
          </button>
        </div>
      </div>
      <div className="table-responsive scrollbar-custom px-2">
      <table className="table table-sm table-order-book table-no-border mb-1">
        <thead>
          <tr className="small">
            <th className="text-muted px-0">Price</th>
            {coin ? (
              <th className="text-muted">Size[{coin.base}]</th>
            ) : (
              <th className="text-muted">Size[{primaryCoin?.base}]</th>
            )}

            {coin ? (
             <th className="text-muted">Total[{coin.base}]</th>
            ) : (
              <th className="text-muted">Total[{primaryCoin?.base}]</th>
            )}
            
            
          </tr>
        </thead>
        <tbody>
        {calculatedAsks.map((ask, index) => (
          <tr key={index} className="border-0 small">
            <td className="text-danger border-0">{formatNumberWithComma(ask.price)}</td>
            <td className="border-0 text-end">{ask.size}</td>
            <td className="border-0 text-end">{ask.total}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>

    <div className="table-responsive">
      <table className="table table-sm table-order-book table-no-border mb-1">
      <tbody className="border-top border-bottom custom-border-color border-secondary table-border">
            <tr>
              <td className={latestTradeType === "BUY" ? "text-success text-end pe-2" : "text-danger text-end pe-2"}>
              {formatNumberWithComma(latestTradePrice)}
            </td>
            <td>
              ${formatNumberWithComma(averageOfLatestPrices)}
              </td>
            </tr>
        </tbody>
      </table>
    </div>

    <div className="table-responsive px-2">
      <table className="table table-sm table-order-book table-no-border mb-1">
        <tbody>
        {calculatedBids.map((bid, index) => (
          <tr key={index} className="border-0 small">
            <td className="text-success border-0">{formatNumberWithComma(bid.price)}</td>
            <td className="border-0 text-end">{bid.size}</td>
            <td className="border-0 text-end">{bid.total}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>



    </div>
  )
};
