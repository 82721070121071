import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react"; 
import './Logout.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';

import { useDispatch } from 'react-redux';
import { login, logout } from '../../../Features/Session/SessionSlice';

function Logout() {
 
  const dispatch = useDispatch();

  useEffect(() => {
  
    // const navigate = useNavigate();

    localStorage.setItem("token", "");
    localStorage.removeItem('token');
    localStorage.setItem("userInfo", "");
    localStorage.removeItem('userInfo');
    localStorage.removeItem('initialPrices');
     
    dispatch(logout());

    // navigate('/login', { replace: true });

  }, []);
 
  return (
    <>
    <Header />
    <PriceBar /> 

<div className="row">
  <div className="col-md-12 centered-items">
    <h1 style={{color:'#aaa', paddingTop:55}}>You are Logged Out!</h1>
    </div>
    <div className="col-md-12 centered-items">
    <h4 style={{color:'#aaa', paddingTop:55}}>
      <Link to="/login">Please Login</Link> 
    </h4> 
    </div>
    <p><br /><br /></p>
</div>
<Footer />
    </>
  );

}

export default Logout;