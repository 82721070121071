import { Link } from "react-router-dom";
import './ChangePassword.css';

import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';

function ChangePassword() {
   
  return (
    <>
     <Header />
     {/* Main */}
      <section className="main-page">
      <SideBar mainmenu="Account" submenu="AccountChangePassword" />
  
      <div className="main-content">
        <h4 className="title">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path d="M15 7.00008C15.5304 7.00008 16.0391 7.21079 16.4142 7.58586C16.7893 7.96093 17 8.46964 17 9.00008M21 9.00008C21.0003 9.93725 20.781 10.8615 20.3598 11.6986C19.9386 12.5358 19.3271 13.2627 18.5744 13.821C17.8216 14.3792 16.9486 14.7535 16.0252 14.9136C15.1018 15.0737 14.1538 15.0153 13.257 14.7431L11 17.0001H9V19.0001H7V21.0001H4C3.73478 21.0001 3.48043 20.8947 3.29289 20.7072C3.10536 20.5196 3 20.2653 3 20.0001V17.4141C3.00006 17.1489 3.10545 16.8946 3.293 16.7071L9.257 10.7431C9.00745 9.91809 8.93857 9.04902 9.05504 8.19502C9.17152 7.34102 9.47062 6.52215 9.93199 5.79412C10.3934 5.0661 11.0062 4.44602 11.7287 3.97609C12.4512 3.50617 13.2665 3.19743 14.1191 3.07088C14.9716 2.94434 15.8415 3.00296 16.6693 3.24276C17.4972 3.48256 18.2637 3.89791 18.9166 4.46054C19.5696 5.02317 20.0936 5.71987 20.4531 6.50322C20.8127 7.28656 20.9992 8.13817 21 9.00008Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Chage Password
        </h4>
        <div className="box-item">
          <div className="api-key">
            <div className="null-box">
              <img src="images/materials/isolation_Mode.svg" alt="" />
              <div className="text">
                <h5>Change Password</h5>
                <p>
                  Click the button to receive an email with a temporary code. This code is required to change your password.
                </p>
              </div>
              <a href="account-api-key-2.html" className="button">
                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.49998 1.55518C6.77594 3.10223 4.54899 3.97096 2.23278 3.99998C2.14478 4.51918 2.09998 5.05518 2.09998 5.59998C2.09998 9.77998 4.77198 13.336 8.49998 14.6536C12.228 13.336 14.9 9.77998 14.9 5.59998C14.9 5.05438 14.8544 4.51998 14.7672 3.99918C12.4511 3.97038 10.2241 3.10194 8.49998 1.55518ZM9.29998 11.2C9.29998 11.4121 9.21569 11.6156 9.06566 11.7657C8.91563 11.9157 8.71215 12 8.49998 12C8.2878 12 8.08432 11.9157 7.93429 11.7657C7.78426 11.6156 7.69998 11.4121 7.69998 11.2C7.69998 10.9878 7.78426 10.7843 7.93429 10.6343C8.08432 10.4843 8.2878 10.4 8.49998 10.4C8.71215 10.4 8.91563 10.4843 9.06566 10.6343C9.21569 10.7843 9.29998 10.9878 9.29998 11.2ZM9.29998 5.59998C9.29998 5.3878 9.21569 5.18432 9.06566 5.03429C8.91563 4.88426 8.71215 4.79998 8.49998 4.79998C8.2878 4.79998 8.08432 4.88426 7.93429 5.03429C7.78426 5.18432 7.69998 5.3878 7.69998 5.59998V7.99998C7.69998 8.21215 7.78426 8.41563 7.93429 8.56566C8.08432 8.71569 8.2878 8.79998 8.49998 8.79998C8.71215 8.79998 8.91563 8.71569 9.06566 8.56566C9.21569 8.41563 9.29998 8.21215 9.29998 7.99998V5.59998Z" fill="white" />
                </svg>
                Enable 2FA
              </a>
            </div>
          </div>
        </div>
      </div>
      
      </section>
     <Footer />
    </>
  );
}

export default ChangePassword;