import { createSlice } from '@reduxjs/toolkit';
export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    selectDepositMethod: '', // Initial value ...
    selectDepositCurrency: '', // Initial value ...
  },
  reducers: {
    setSelectDepositMethodValue: (state, action) => {
      state.selectDepositMethod = action.payload;
    },
    setSelectDepositCurrency: (state, action) => {
      state.selectDepositCurrency = action.payload;
    },
  },
});
export const { setSelectDepositMethodValue, setSelectDepositCurrency } = walletSlice.actions;
export default walletSlice.reducer;
