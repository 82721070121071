import { Navigate } from 'react-router-dom';

// import { useSelector } from 'react-redux';
// import { selectIsAuthenticated } from '../Features/Session/SessionSlice';
 
const ProtectedRoute = ({ children }) => {
// const isAuthenticated = useSelector(selectIsAuthenticated);

const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/login" replace />;
  }  
  return children;
};

export default ProtectedRoute;