import React from 'react';
// import ReactDOM from 'react-dom';

// import 'bootstrap/dist/js/bootstrap.bundle.min';
// 'bootstrap/dist/css/bootstrap.min.css';

import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
  
import { Provider } from 'react-redux';
import store from './store';

import { ModalProvider } from './Context/ModalContext';


 
const root = document.getElementById('root');
const reactRoot = createRoot(root);
 
reactRoot.render(
<React.StrictMode>
  <Provider store={store}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </Provider>
</React.StrictMode>
);
  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
