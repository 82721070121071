import React, { useState } from "react";
import "./Trade.css";
 
export const AnnounceBar = () => {

  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className={isVisible ? "d-lg-flex d-none announce-bar align-items-center justify-content-start visible" : "announce-bar d-flex align-items-center justify-content-start hidden"}>
      <img src="/images/icons/speaker-wave.svg" alt="" />
      <p className="text-white mb-0 ps-2">Halloween Trading Party: Trade to Sgare 1 BTC & 5 ETH</p>
      <button className="ms-auto bg-transparent border-0" onClick={() => setIsVisible(false)} >
        <img src="/images/icons/xmark.svg" alt="" />
      </button>
    </div>
  )
};
