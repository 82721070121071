import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Overview.css";
import { formatNumberWithComma } from "../../../Common/Helpers";
import CoinInformation from "../../../Common/CoinInformation.json";
import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";
import axios from "axios";
 
import ExportExcelButton from "../../../Common/ExportExcelButton";
import DownloadPDFButton from "../../../Common/DownloadPDFButton";
import CsvExporter from '../../../Common/CsvExporter'; // Adjust the path as per your file structure
 
import {fetchMarketData, getCurrencySettings} from '../../../Components/MarketData';

function Overview() {
 
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);

  const [hideBalances, setHideBalances] = useState(false);
  const [hideLowBalances, setHideLowBalances] = useState(false);

  const [btcValue, setBtcValue] = useState();
  const [USDTValue, setUSDTValue] = useState();
   
  const ws = useRef(null);
  const [balanceArr, setBalanceArr] = useState([]);

  const [totalInUsdt, setTotalInUsdt] = useState();
  const [totalInBtc, setTotalInBtc] = useState();

  const [getBalanceActivater, setGetBalanceActivater] = useState(new Date());
  
  const [initialPrices, setInitialPrices] = useState(() => {
    const storedData = localStorage.getItem("initialPrices");
    return storedData ? JSON.parse(storedData) : [];
  });

 
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
   
  let isInstitutional = false;
  if (process.env.REACT_APP_IS_INSTITUTIONAL === "true") {
    isInstitutional = true;
  }

/*
     * Pagination and Orderby
    */

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(9999);
const [searchWord, setSearchWord] = useState();
const [tableShowData, setTableShowData] = useState([]);
// const [sortBy, setSortBy] = useState({ key: null, ascending: true });
const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });

// Function to sort data based on column key and sort order
const sortedData = tableShowData.sort((a, b) => {
  if (sortBy.key) {
    const valueA = a[sortBy.key];
    const valueB = b[sortBy.key];
    if (valueA < valueB) return sortBy.ascending ? -1 : 1;
    if (valueA > valueB) return sortBy.ascending ? 1 : -1;
  }
  return 0;
});

// Function to handle column header click for sorting
const handleSort = (key) => {
  if (sortBy.key === key) {
    setSortBy({ key, ascending: !sortBy.ascending });
  } else {
    setSortBy({ key, ascending: true });
  }
}
  
const handleSearch = (e) => {
  let newKeyword = e.target.value;
  setSearchWord(newKeyword);
 
    if (newKeyword) {
      let tmpSearchWord = newKeyword;

      let filteredShowArray = tableShowData.filter(item => 
        item.ShortName.toLocaleLowerCase('en-US').includes(tmpSearchWord.toLocaleLowerCase('en-US'))
      );

      setTableShowData(filteredShowArray);    
      setIsLoading(false);  
    } 

}

/*
 * Pagination and Orderby Ended
*/

const handleHideBalancesChange = () => {
  // Toggle hideBalances state between true and false
  setHideBalances(!hideBalances);
};

const handleHideLowBalancesChange = () => {
  // Toggle hideBalances state between true and false
  setHideLowBalances(!hideLowBalances);
}
 
  const fetchMarketDataFunction = async () => {
    let latestFiatData = await fetchMarketData(); 

    const filteredArray = latestFiatData.filter(item => item.currency === 'BTC');
    setBtcValue(filteredArray[0].rate);

    const filteredArray2 = latestFiatData.filter(item => item.currency === 'USDT');
    setUSDTValue(filteredArray2[0].rate);

  }

  const getBalance = async () => {
    let tmpTotalInUsdt = 0;
    let tmpTotalInBtc = 0;
    await axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
        { currency: "ALL" },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        
        console.log(USDTValue)
        const { coinListAll } = getBalanceCoinData(response.data.data);
  

        let tmpCoinListAll = coinListAll;

        if (searchWord && tmpCoinListAll?.length>0) { 
            tmpCoinListAll = tmpCoinListAll.filter(item => 
            item.ShortName.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
          )
        } 
 
        if (hideBalances === true && tmpCoinListAll?.length > 0) {
  
            tmpCoinListAll = tmpCoinListAll.map(item => {
              // Create a new object with the same properties as the original item
              return {
                ...item,
                // Replace specific properties with "********"
                // balance: "-1",
                // balanceInBtc: "-1",
                // balanceInTrade: "-1",
                // balanceInTradeInUsdt: "-1",
                // balanceInUsdt: "-1",
                // total: "-1",
                // totalInBtc: "-1",
                // totalInUsdt: "-1",
              };
            }); 
        }
        
        if (hideLowBalances===true && tmpCoinListAll?.length>0) { 
          tmpCoinListAll = tmpCoinListAll.filter(item => item.balance!="0");
          console.log(tmpCoinListAll)
        } 


        const sortedCoinListAll = tmpCoinListAll.sort(
          (a, b) => b.balance - a.balance
        );

        if (initialPrices) {
          sortedCoinListAll.forEach((item2) => {
            
            // Find the corresponding item in Array1
            const correspondingItem1 = initialPrices.find(
              (item1) => item1.quote === "USDT" && item1.base === item2.currency
            )
            
            if (item2.currency=="USDT") {
              // item2.balance => Crypto Balance
              item2.balanceInUsdt = item2.balance * USDTValue;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt = item2.balanceInTrade * USDTValue;
              item2.balanceInTradeInBtc  = item2.balanceInTradeInUsdt / btcValue;

              item2.total       = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = item2.total * USDTValue;
              item2.totalInBtc  = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt>0) {
                tmpTotalInUsdt+=item2.balanceInUsdt; 
              } 
              if (item2.balanceInBtc>0) {
                tmpTotalInBtc+=item2.balanceInBtc; 
              }  
            }

            // If found, calculate and assign myprice to the current item in Array2
            if (correspondingItem1) {

              // item2.balance => Crypto Balance
              item2.balanceInUsdt = correspondingItem1.price * item2.balance;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt = correspondingItem1.price * item2.balanceInTrade
              item2.balanceInTradeInBtc  = item2.balanceInTradeInUsdt / btcValue;

              item2.total       = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = correspondingItem1.price * item2.total;
              item2.totalInBtc  = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt>0) {
                tmpTotalInUsdt+=item2.balanceInUsdt; 
              } 
              if (item2.balanceInBtc>0) {
                tmpTotalInBtc+=item2.balanceInBtc; 
              }  
            }
          })
          
        }
        
        setTotalInUsdt(tmpTotalInUsdt);
        setTotalInBtc(tmpTotalInBtc);

        setBalanceArr(sortedCoinListAll);
        setTableShowData(sortedCoinListAll)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
        setIsLoading(false);
      });
  };

  const getBalanceCoinData = (data) => {

    // let marketDataResponse = await getCurrencySettings();

    const serviceData = data ? [...data] : [];
 
    const coinInformation = CoinInformation;
    let coinListAll = [];
    
    console.log(serviceData.length)
    for (let i = 0; i < serviceData.length; i++) {

      const coinName = serviceData[i].currency;
      
      if (coinInformation[coinName]?.ShortName) {
        serviceData[i].ShortName = coinInformation[coinName].ShortName;
        serviceData[i].LongName = coinInformation[coinName].LongName;
        coinListAll.push(serviceData[i]);
      }
      // } else {
      //   serviceData.splice(i, 1);
      // }
    }
    return { coinListAll };

  };

 
  useEffect(() => {
    getBalance();
  }, [hideBalances, hideLowBalances, searchWord]);

  const changeStatus = () => {
      setGetBalanceActivater(new Date());
      console.log(getBalanceActivater);
  };
  
  useEffect(() => {
    const myTimer = setInterval(changeStatus, 2500);
    return () => clearInterval(myTimer);
  }, [getBalanceActivater]);

  useEffect(() => {
    localStorage.setItem("initialPrices", JSON.stringify(initialPrices));
  }, [initialPrices]);

  useEffect(() => {
    setIsLoading(true); 
    getBalance();
    fetchMarketDataFunction();
    setIsLoading(false);
  }, [getBalanceActivater]);

  useEffect(() => { 
    setIsLoading(true); 
    if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
      ws.current.close();
    }
    ws.current = new WebSocket("wss://node1.ledgerbytes.tech/ws");
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify({
          method: "subscribe",
          events: ["MK"],
        })
      );
    };
    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message?.data) {
        const dataArray = message?.data;
        const newArray = dataArray.map(({ base, quote, price }) => ({ base, quote, price }));

        setInitialPrices(prevState => {
          // Create a shallow copy of the previous state array
          const updatedState = [...prevState];
          // Iterate over each item in dataArray
          newArray.forEach(newItem => {
            // Find the index of the item in the updated state array
            const index = updatedState.findIndex(item => item.base === newItem.base && item.quote === newItem.quote);
            // If the item exists in the updated state array, update it; otherwise, push it to the array
            if (index !== -1) {
              updatedState[index] = { ...updatedState[index], ...newItem };
            } else {
              updatedState.push(newItem);
            }
          }); 
          return updatedState;
        });
      }
    };
    ws.current.onclose = () => {};


    // Close WebSocket when Component Terminates
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    }; 
    // Close WebSocket when Component Terminates
    
  }, []);

  return (
    <>
      <Header />

      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Wallet" submenu="WalletOverview" />
        <div className="main-content">
          <div className="row">
            {/* <div className="col-lg-7"> */}
            <div className="col-lg-12">
              <h4 className="title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_690_2213)">
                    <path
                      d="M15.8333 2.5H4.16667C3.062 2.50132 2.00296 2.94073 1.22185 3.72185C0.440735 4.50296 0.00132321 5.562 0 6.66667H20C19.9987 5.562 19.5593 4.50296 18.7782 3.72185C17.997 2.94073 16.938 2.50132 15.8333 2.5Z"
                      fill="white"
                    />
                    <path
                      d="M0 13.3332C0.00132321 14.4379 0.440735 15.4969 1.22185 16.2781C2.00296 17.0592 3.062 17.4986 4.16667 17.4999H15.8333C16.938 17.4986 17.997 17.0592 18.7782 16.2781C19.5593 15.4969 19.9987 14.4379 20 13.3332V8.33325H0V13.3332ZM5.83333 12.9166C5.83333 13.1638 5.76002 13.4055 5.62267 13.611C5.48532 13.8166 5.2901 13.9768 5.06169 14.0714C4.83328 14.166 4.58195 14.1908 4.33947 14.1426C4.09699 14.0943 3.87427 13.9753 3.69945 13.8005C3.52463 13.6256 3.40558 13.4029 3.35735 13.1604C3.30912 12.918 3.33387 12.6666 3.42848 12.4382C3.52309 12.2098 3.68331 12.0146 3.88887 11.8772C4.09443 11.7399 4.33611 11.6666 4.58333 11.6666C4.91485 11.6666 5.2328 11.7983 5.46722 12.0327C5.70164 12.2671 5.83333 12.5851 5.83333 12.9166Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_690_2213">
                      <rect width={20} height={20} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Wallet Overview
              </h4>
              <div className="balance">
                <h4>
                  Estimated Balance{" "}
                  <button className="eye">
                    <img src="/images/icons/eye.svg" alt="" />
                  </button>
                </h4>
                <div className="price">
                  <span className="v01">
                   {totalInBtc && !hideBalances ? formatNumberWithComma(totalInBtc) : ""} 
                   {hideBalances ? "******" : ""}
                   &nbsp;BTC 
                    <button className="down">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.0837 5.25L7.00033 9.33333L2.91699 5.25"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </span>
                  <span className="v02">≈ $ 
                  {totalInUsdt && !hideBalances ? formatNumberWithComma(totalInUsdt) : ""}
                  {hideBalances ? "******" : ""}
                  </span>
                </div>
                <p>
                  There are no assets in your account yet, complete <br /> the
                  authentication to fund your account.
                </p>
              </div>
              <div className="tab-flex">
                
                <div className="radios">

                  <div className="item">
                    <input 
                      id="r01"
                      name="check1"
                      type="checkbox" 
                      checked={hideBalances}
                      onChange={handleHideBalancesChange}
                    /> 
                    <label htmlFor="r01">Hide Balances</label>
                  </div>

                  <div className="item">
                    <input 
                      id="r02"
                      name="check2"
                      type="checkbox" 
                      checked={hideLowBalances}
                      onChange={handleHideLowBalancesChange}
                    /> 
                    <label htmlFor="r02">Hide Low Balances</label>
                  </div>
                </div>

                <div className="dropdown">
 
        <div>
            <button onClick={toggleDropdown} style={{ cursor: 'pointer', position: 'relative', width:'200px' }}>
            Export Data
            </button>
          
            {isOpen && (
              <div style={{ position: 'absolute', top: '100%', left: '0', zIndex: '999' }}>
                <DownloadPDFButton data={sortedData} />
                <div style={{paddingBottom:'5px'}} />
                <CsvExporter data={sortedData}/>
                <div style={{paddingBottom:'5px'}} />
                <ExportExcelButton data={sortedData} filename="exported_data" />
              </div>
            )}
        </div>
  
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Export Data
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="radios">
                <div className="item">
                  <input type="radio" id="r01" name="radio" />
                  <label htmlFor="r01">Hide Balances</label>
                </div>
                <div className="item">
                  <input type="radio" id="r02" name="radio" defaultChecked />
                  <label htmlFor="r02">Hide Low Balances</label>
                </div>
              </div> */}
              <form className="search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                    stroke="#9CA3AF"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input 
                  type="text" 
                  placeholder="Search" 
                  value={searchWord}
                  onChange={handleSearch}
                  />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={13}
                  height={14}
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <path
                    d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                    fill="#111928"
                    stroke="#9CA3AF"
                  />
                </svg>
              </form>
            </div>
             
            <div className="col-lg-12">
              <div className="table-responsive mt20">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('LongName')}>
                      {sortBy.key==="LongName" ? <u>CURRENCY</u> : "CURRENCY" }
                      {sortBy.key==="LongName" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                      {sortBy.key==="LongName" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                      </th>
                      <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('balance')}>
                      {sortBy.key==="balance" ? <u>BALANCE</u> : "BALANCE" }
                      {sortBy.key==="balance" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                      {sortBy.key==="balance" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                      </th>
                      <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('balanceInTrade')}>
                      {sortBy.key==="balanceInTrade" ? <u>IN ORDERS</u> : "IN ORDERS" }
                      {sortBy.key==="balanceInTrade" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                      {sortBy.key==="balanceInTrade" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                      </th>
                      <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('total')}>
                      {sortBy.key==="total" ? <u>TOTAL</u> : "TOTAL" }
                      {sortBy.key==="total" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                      {sortBy.key==="total" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                      </th>

                      {!isInstitutional ? <th>ACTIONS</th> : <></>}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData && sortedData?.length > 0 ? (
                      sortedData?.map((item, index) => ( 
                        <tr key={item.id}> 
                          <td>
                            <div className="pair">
                              <img
                                src={`/images/coins/${item.ShortName.toLowerCase()}.svg`}
                              />
                              {item.LongName} / {item.ShortName}
                            </div>
                          </td>
                          <td>
                          
                            <div className="date">
                              
                              {item.balance && !hideBalances ? formatNumberWithComma(item.balance) : ""}{" "}
                              {hideBalances ? "******" : ""} 
                              {!hideBalances && !item.balance ? "0" : ""} 
                              &nbsp;{item.ShortName} <br />

                              <span className="ml0">
                              $&nbsp;{item?.balanceInUsdt  && !hideBalances ? formatNumberWithComma(item?.balanceInUsdt) : ""} 
                              {hideBalances ? "******" : ""}
                              {!hideBalances && !item.balanceInUsdt ? "0" : ""}
                              </span>
                              <br />
                              <span className="ml0">
                                {item?.balanceInBtc  && !hideBalances ? formatNumberWithComma(item?.balanceInBtc) : ""} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.balanceInBtc ? "0" : ""}
                                &nbsp;BTC</span>
                            </div>
                          </td>
                          <td>

                            <div className="date">
                              {item.balanceInTrade  && !hideBalances ? formatNumberWithComma(item?.balanceInTrade) : ""}{" "} 
                              {hideBalances ? "******" : ""}
                              {!hideBalances && !item.balanceInTrade ? "0" : ""}
                              &nbsp;{item.ShortName}<br />

                              <span className="ml0">
                                $&nbsp;{item.balanceInTradeInUsdt && !hideBalances ? formatNumberWithComma(item.balanceInTradeInUsdt) : ""}
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.balanceInTradeInUsdt ? "0" : ""}
                                </span>
                              <br />
                              <span className="ml0">
                                {item?.balanceInTradeInBtc && !hideBalances ? formatNumberWithComma(item?.balanceInTradeInBtc) : ""} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.balanceInTradeInBtc ? "0" : ""}
                                &nbsp;BTC
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className="date">
                              {item.total  && !hideBalances ? formatNumberWithComma(item?.total) : ""}{" "} 
                              {hideBalances ? "******" : ""}
                              {!hideBalances && !item.total ? "0" : ""}
                              &nbsp;{item.ShortName}<br />

                              <span className="ml0">$&nbsp;
                              {item.totalInUsdt && !hideBalances  ? formatNumberWithComma(item.totalInUsdt) : ""} 
                              {hideBalances ? "******" : ""}
                              {!hideBalances && !item.totalInUsdt ? "0" : ""}
                              </span>
                              <br />
                              <span className="ml0">
                                {item?.totalInBtc && !hideBalances  ? formatNumberWithComma(item?.totalInBtc) : ""} 
                                {hideBalances ? "******" : ""}
                                {!hideBalances && !item.totalInBtc ? "0" : ""}
                                &nbsp;BTC</span>
                            </div>
                          </td>

                          {!isInstitutional ? (
                            <td>
                              <div className="actions">
                                <ul>
                                  <li>
                                    <a href="#" className="stn-btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={17}
                                        height={17}
                                        viewBox="0 0 17 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.39023 11.112L10.7902 8.71202L10.7963 8.70588L10.7964 8.70593C10.825 8.67826 10.8479 8.64515 10.8636 8.60856C10.8793 8.57195 10.8876 8.53258 10.8879 8.49275C10.8883 8.45292 10.8807 8.41342 10.8656 8.37654C10.8505 8.33968 10.8283 8.30618 10.8001 8.27801C10.7719 8.24984 10.7384 8.22757 10.7016 8.21249C10.6647 8.1974 10.6252 8.18981 10.5854 8.19016C10.5455 8.1905 10.5061 8.19878 10.4695 8.2145C10.4329 8.23022 10.3998 8.25308 10.3722 8.28173L10.3661 8.28798L10.3661 8.28793L9.33168 9.32233L8.47813 10.1759V8.96878V2.89998C8.47813 2.82041 8.44652 2.7441 8.39026 2.68784C8.334 2.63158 8.25769 2.59998 8.17813 2.59998C8.09856 2.59998 8.02225 2.63158 7.96599 2.68784C7.90973 2.7441 7.87813 2.82041 7.87813 2.89998V8.96878V10.1759L7.02457 9.32233L5.99023 8.28798C5.99021 8.28796 5.99019 8.28795 5.99017 8.28793C5.93392 8.23172 5.85765 8.20015 5.77813 8.20015C5.69863 8.20015 5.62238 8.2317 5.56613 8.28787C5.5661 8.28791 5.56606 8.28795 5.56603 8.28798M8.39023 11.112L5.21253 9.06558C5.06255 8.91555 4.9783 8.71211 4.9783 8.49998C4.9783 8.28784 5.06255 8.0844 5.21253 7.93438L5.56603 8.28798M8.39023 11.112C8.39021 11.112 8.39019 11.112 8.39017 11.112C8.33392 11.1682 8.25765 11.1998 8.17813 11.1998C8.0986 11.1998 8.02233 11.1682 7.96608 11.112C7.96606 11.112 7.96604 11.112 7.96602 11.112L5.56613 8.71208M8.39023 11.112L5.56613 8.71208M5.56603 8.28798C5.50985 8.34423 5.4783 8.42048 5.4783 8.49998C5.4783 8.57952 5.50989 8.65582 5.56613 8.71208M5.56603 8.28798L5.56613 8.71208M3.07812 14.1C3.07812 14.0204 3.10973 13.9441 3.16599 13.8878C3.22225 13.8316 3.29856 13.8 3.37813 13.8H12.9781C13.0577 13.8 13.134 13.8316 13.1903 13.8878C13.2465 13.9441 13.2781 14.0204 13.2781 14.1C13.2781 14.1795 13.2465 14.2558 13.1903 14.3121C13.134 14.3684 13.0577 14.4 12.9781 14.4H3.37813C3.29856 14.4 3.22225 14.3684 3.16599 14.3121C3.10973 14.2558 3.07812 14.1795 3.07812 14.1Z"
                                          fill="white"
                                          stroke="white"
                                        />
                                      </svg>
                                      Deposit
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="stn-btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={17}
                                        height={17}
                                        viewBox="0 0 17 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.99023 5.51204L7.02457 4.4777L7.87813 3.62414V4.83125V10.9C7.87813 10.9796 7.90973 11.0559 7.96599 11.1122C8.02225 11.1684 8.09856 11.2 8.17813 11.2C8.25769 11.2 8.334 11.1684 8.39026 11.1122C8.44652 11.0559 8.47813 10.9796 8.47813 10.9V4.83125V3.62414L9.33168 4.4777L10.3623 5.50829C10.4186 5.56149 10.4933 5.59088 10.5709 5.59021C10.6496 5.58952 10.7248 5.55797 10.7804 5.50235C10.836 5.44673 10.8676 5.37148 10.8683 5.29283C10.869 5.21527 10.8396 5.1405 10.7864 5.08419L8.39022 2.68806C8.33397 2.63181 8.25768 2.60022 8.17813 2.60022C8.0986 2.60022 8.02233 2.63179 7.96608 2.688L5.99023 5.51204ZM5.99023 5.51204C5.99021 5.51206 5.99019 5.51208 5.99017 5.51209M5.99023 5.51204L5.99017 5.51209M5.56613 5.51215C5.5661 5.51211 5.56606 5.51208 5.56603 5.51204C5.50985 5.45579 5.4783 5.37955 5.4783 5.30005C5.4783 5.22053 5.50987 5.14426 5.56608 5.088C5.5661 5.08798 5.56611 5.08797 5.56613 5.08795L7.96602 2.68806L5.77813 6.09988C5.56599 6.09988 5.36255 6.01563 5.21253 5.86565L5.56613 5.51215ZM5.56613 5.51215C5.62238 5.56832 5.69863 5.59988 5.77813 5.59988C5.85765 5.59988 5.93392 5.5683 5.99017 5.51209M5.56613 5.51215L5.99017 5.51209M3.07812 14.1C3.07812 14.0205 3.10973 13.9442 3.16599 13.8879C3.22225 13.8317 3.29856 13.8 3.37813 13.8H12.9781C13.0577 13.8 13.134 13.8317 13.1903 13.8879C13.2465 13.9442 13.2781 14.0205 13.2781 14.1C13.2781 14.1796 13.2465 14.2559 13.1903 14.3122C13.134 14.3684 13.0577 14.4 12.9781 14.4H3.37813C3.29856 14.4 3.22225 14.3684 3.16599 14.3122C3.10973 14.2559 3.07812 14.1796 3.07812 14.1Z"
                                          fill="white"
                                          stroke="white"
                                        />
                                      </svg>
                                      Withdrawal
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" className="trade-btn">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={17}
                                        height={17}
                                        viewBox="0 0 17 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M6.57795 4.50004C6.36578 4.50004 6.1623 4.58433 6.01227 4.73435C5.86224 4.88438 5.77795 5.08787 5.77795 5.30004C5.77795 5.51221 5.86224 5.7157 6.01227 5.86573C6.1623 6.01575 6.36578 6.10004 6.57795 6.10004H11.0468L10.0124 7.13444C9.86663 7.28532 9.78599 7.4874 9.78781 7.69716C9.78964 7.90692 9.87377 8.10757 10.0221 8.25589C10.1704 8.40422 10.3711 8.48836 10.5808 8.49018C10.7906 8.492 10.9927 8.41137 11.1436 8.26564L13.5436 5.86564C13.6935 5.71562 13.7778 5.51217 13.7778 5.30004C13.7778 5.08791 13.6935 4.88446 13.5436 4.73444L11.1436 2.33444C11.0698 2.25803 10.9815 2.19709 10.8839 2.15516C10.7863 2.11323 10.6813 2.09116 10.5751 2.09024C10.4689 2.08932 10.3635 2.10956 10.2652 2.14978C10.1669 2.19001 10.0776 2.24941 10.0024 2.32452C9.92732 2.39964 9.86792 2.48896 9.8277 2.58728C9.78747 2.68559 9.76723 2.79094 9.76815 2.89716C9.76908 3.00338 9.79114 3.10836 9.83307 3.20596C9.875 3.30357 9.93595 3.39184 10.0124 3.46564L11.0468 4.50004H6.57795ZM9.77795 12.5C9.99013 12.5 10.1936 12.4158 10.3436 12.2657C10.4937 12.1157 10.578 11.9122 10.578 11.7C10.578 11.4879 10.4937 11.2844 10.3436 11.1344C10.1936 10.9843 9.99013 10.9 9.77795 10.9H5.30915L6.34355 9.86564C6.41996 9.79184 6.48091 9.70357 6.52284 9.60596C6.56476 9.50836 6.58683 9.40338 6.58775 9.29716C6.58868 9.19094 6.56844 9.08559 6.52821 8.98728C6.48799 8.88896 6.42858 8.79964 6.35347 8.72452C6.27836 8.64941 6.18903 8.59001 6.09072 8.54978C5.9924 8.50956 5.88706 8.48932 5.78083 8.49024C5.67461 8.49116 5.56963 8.51323 5.47203 8.55516C5.37443 8.59709 5.28615 8.65803 5.21235 8.73444L2.81235 11.1344C2.66238 11.2845 2.57812 11.4879 2.57812 11.7C2.57812 11.9122 2.66238 12.1156 2.81235 12.2656L5.21235 14.6656C5.36324 14.8114 5.56532 14.892 5.77507 14.8902C5.98483 14.8884 6.18548 14.8042 6.33381 14.6559C6.48214 14.5076 6.56627 14.3069 6.56809 14.0972C6.56992 13.8874 6.48928 13.6853 6.34355 13.5344L5.30915 12.5H9.77795Z"
                                          fill="white"
                                        />
                                      </svg>
                                      Trade
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      ))
                    ) : (
                      <>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>Loading...</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>No Data...</span>   
                      </td>
                  </tr>
                  } 
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <ul className="pagination">
                <li>
                  <Link to="#">Previous</Link>
                </li>
                <li>
                  <Link to="#">1</Link>
                </li>
                <li>
                  <Link to="#">2</Link>
                </li>
                <li>
                  <Link to="#">3</Link>
                </li>
                <li>
                  <Link to="#">Next</Link>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Overview;
